import { createSlice } from "@reduxjs/toolkit";

import { ListState } from "@/types/list";

import { RootState } from "@/redux";

import { Order } from "@/types/types";

import { listApi } from "./list.api";

const initialState: ListState = {
	lists: [],
	listMeta: {
		page: 1,
		limit: 15,
		itemCount: 15,
		pageCount: 1,
		hasPreviousPage: false,
		hasNextPage: false,
	},
	inbox: [],
	campaign: [],
	meta: {
		page: 1,
		limit: 15,
		itemCount: 15,
		pageCount: 1,
		hasPreviousPage: false,
		hasNextPage: false,
	},
	sortingList: {
		sortBy: "updatedAt",
		sortOrder: Order.DESC,
	},
	sortingInbox: {
		sortBy: "updatedAt",
		sortOrder: Order.DESC,
	},
};

export const listSlice = createSlice({
	name: "list",
	initialState,
	reducers: {
		setListSorting: (state, { payload }) => {
			state.sortingList = payload;
		},
		setInboxSorting: (state, { payload }) => {
			state.sortingInbox = payload;
		},
		removeEveryRecordAbove15: (state) => {
			state.inbox = state.inbox.slice(0, 15);
		},
		clearInbox: (state) => {
			state.inbox = [];
		},
		clearCampaign: (state) => {
			state.campaign = [];
		},
		clearLists: (state) => {
			state.lists = [];
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(listApi.endpoints.getLists.matchFulfilled, (state, { payload }) => {
			state.lists = payload.data;
			state.listMeta = payload.meta;
		});
		builder.addMatcher(listApi.endpoints.getLeanLists.matchFulfilled, (state, { payload }) => {
			if (payload.meta.page === 1) {
				state.lists = payload.data;
			} else {
				const newColumns = payload.data.filter((c) => !state.lists.find((p) => p.id === c.id));
				const knownColumns = payload.data.filter((c) => state.lists.find((p) => p.id === c.id));
				const savedColumns = state.lists.filter((c) => !payload.data.find((p) => p.id === c.id));

				state.lists = [...savedColumns, ...knownColumns, ...newColumns];
			}

			state.listMeta = payload.meta;
		});
		builder.addMatcher(listApi.endpoints.getUnassigned.matchFulfilled, (state, { payload }) => {
			state.inbox = payload.data;
			state.meta = payload.meta;
		});
		builder.addMatcher(listApi.endpoints.getCampaign.matchFulfilled, (state, { payload }) => {
			state.campaign = payload.data;
			state.meta = payload.meta;
		});
		builder.addMatcher(listApi.endpoints.updateCostumColumn.matchFulfilled, (state, action) => {
			const index = state.inbox.findIndex((person) => person.id === action.payload.id);

			const person = state.inbox[index];

			if (index === -1) {
				return;
			}

			const columnIndex = person.columns.findIndex(
				(column) => column.columnConfigId === action.payload.columnValue.columnConfigId
			);

			if (columnIndex > -1) {
				const column = person.columns[columnIndex];

				column.value = action.payload.columnValue.value;

				state.inbox[index].columns[columnIndex] = column;
			} else {
				state.inbox[index].columns.push(action.payload.columnValue);
			}
		});
		builder.addMatcher(listApi.endpoints.updateProspect.matchFulfilled, (state, action) => {
			const index = state.inbox.findIndex((person) => person.id === +action.payload.id);

			if (index === -1) {
				return;
			}

			const person = state.inbox[index];

			if (action.payload.updateProspectDto.name) {
				person.prospect.name = action.payload.updateProspectDto.name;
			}

			state.inbox[index] = person;
		});

		builder.addMatcher(listApi.endpoints.updateMapBusiness.matchFulfilled, (state, action) => {
			const index = state.inbox.findIndex((person) => person.id === +action.payload.id);

			if (index === -1) {
				return;
			}

			const person = state.inbox[index];

			if (action.payload.updateMapBusinessDto.name) {
				person.mapBusiness.name = action.payload.updateMapBusinessDto.name;
			}

			state.inbox[index] = person;
		});

		builder.addMatcher(listApi.endpoints.updateOrganization.matchFulfilled, (state, action) => {
			const index = state.inbox.findIndex((person) => person.id === +action.payload.id);

			if (index === -1) {
				return;
			}

			const person = state.inbox[index];

			if (action.payload.updateProspectDto.name && person.prospect.organization?.name) {
				person.prospect.organization.name = action.payload.updateProspectDto.name;
			}

			state.inbox[index] = person;
		});
		builder.addMatcher(listApi.endpoints.updateList.matchFulfilled, (state, action) => {
			if (!action.payload.id) {
				return;
			}

			const index = state.lists.findIndex((list) => list.id === action.payload.id);

			if (index === -1) {
				return;
			}

			if (action.payload.id) {
				state.lists[index] = {
					...state.lists[index],
					...action.payload,
				};
			}
		});
	},
});

export const { setListSorting, removeEveryRecordAbove15, setInboxSorting, clearInbox, clearLists } =
	listSlice.actions;
export const listReducer = listSlice.reducer;
export const listSelector = (state: RootState) => state[listSlice.name];
