import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";

import { faAdd, faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";

import { TextField } from "@mui/material";

import { Breadcrumb } from "@/components/Breadcrump/Breadcrump";

import settingsWheel from "@assets/icons/settingsWheel.svg";
import trashIcon from "@assets/icons/trashIcon.svg";

import { Button } from "@/components/Button/Button";
import {
	useDeleteListColumnMutation,
	useGetColumnTemplatesQuery,
	useGetListQuery,
} from "@/pages/Private/redux/list/list.api";

import { Icon, LoadingOverlay, Modal } from "@/components";

import { ButtonColor, ButtonSize } from "@/components/Button/types";

import { MapScrapeType } from "@/enum/map.enum";

import { CustomColumnTypes, ListSourceType } from "@/enum/list.enum";

import { useSearch } from "@/utils";

import {
	useSaveSearchApolloPeopleMutation,
	useUpdateApolloMutation,
} from "@/pages/Private/redux/apollo/apollo.api";

import { ApolloType } from "@/enum/apollo.enum";

import { JobScrapeType } from "@/enum/job.enum";

import { getAvailableSteps, isDetailsNeeded, getDoneSteps } from "../../utils/create-list-steps";
import { useFilters } from "../../utils/useFilters";
import { IFilterWithValues } from "../../components/FilterModal";
import {
	IFilter,
	FrequentlyUsedFiltersConfig,
	AdditionalColumnsFiltersConfig,
	IFilterType,
} from "../../constans/general-filter";
import {
	AdditionalColumnsJobFiltersConfig,
	AdditionalColumnsNonLinkedinJobFiltersConfigForCreation,
	FrequentlyUsedJobFiltersConfig,
} from "../../constans/jobs-filter";
import { LiCompanyActivityFilter } from "../../constans/li-company-activity-filter";
import { LiPostFilter } from "../../constans/li-post-filter";
import { LiProfileFilter } from "../../constans/li-profile-filter";
import { MapsAdditionalFilter, MapsFilter } from "../../constans/maps-filter";
import { IConfig, IConfigGeneral } from "./interfaces/config-interface";
import { Stepper } from "../ListEditDetails/components/Stepper";
import { EditCustomColumnModalWrapper } from "../../components/EditCustomColumnModalWrapper";
import { CreateCustomColumnModal } from "../../components/CreateCustomColumnModal";
import { ColumnConfig } from "../../schema/columnConfig";

export const ListEditFilter: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();

	const navigate = useNavigate();
	const ts = (key: string) => t(`list.${key}`);

	const { data, isFetching } = useGetListQuery(+id);

	const [expandFrequentlyUsed, setExpandFrequentlyUsed] = useState<boolean>(true);

	const [showNewColumn, setShowNewColumn] = useState<boolean>(false);
	const [editableColumn, setEditableColumn] = useState<ColumnConfig>();
	const [showEditColumn, setShowEditColumn] = useState<boolean>(false);
	const [showDeleteColumn, setShowDeleteColumn] = useState<boolean>(false);

	const [columns, setColumns] = useState<boolean>(false);

	const { page, limit } = useSearch();

	const [saveSearch, { isLoading }] = useSaveSearchApolloPeopleMutation();
	const [updateSearch, { isLoading: isUpdating }] = useUpdateApolloMutation();

	const { data: enrichmentTemplates } = useGetColumnTemplatesQuery({
		page,
		limit,
	});

	const [filter, setFilter] = useState<IFilterWithValues>();

	const [deleteColumn, { isLoading: deleteLoading }] = useDeleteListColumnMutation();

	const { renderFilters, filterValue } = useFilters({ filter });

	const [additionalColumns, setAdditionalColumns] = useState<boolean>(true);
	const [enrichments, setEnrichments] = useState<boolean>(false);

	useEffect(() => {
		if (data?.apollos?.length) {
			const apollo = data.apollos.find((apollo) => apollo.type === ApolloType.SOURCE);
			const config = apollo?.config as unknown as IConfig;

			if (!config) {
				return;
			}

			const {
				person_locations,
				organization_locations,
				organization_num_employees_ranges,
				person_titles,
				industries,
				seniority,
				...rest
			} = config;

			const restParams = rest as IConfigGeneral;

			let newFilter = filter;

			if (person_locations) {
				newFilter = {
					...newFilter,
					country: {
						type: "dropdown",
						filterParam: "prospect.country",
						value: person_locations.map((location) => ({
							id: location,
							title: location,
						})),
					},
				};
			}

			if (industries) {
				newFilter = {
					...newFilter,
					industry: {
						type: "dropdown",
						filterParam: "organization.industry",
						value: industries.map((industry) => ({
							id: industry,
							title: industry,
						})),
					},
				};
			}

			if (organization_locations) {
				newFilter = {
					...newFilter,
					companyCountry: {
						type: "dropdown",
						filterParam: "organization.country",
						value: organization_locations.map((country) => ({
							id: country,
							title: country,
						})),
					},
				};
			}

			if (person_titles) {
				newFilter = {
					...newFilter,
					title: {
						type: "dropdown",
						filterParam: "prospect.title",
						value: person_titles.map((title) => ({
							id: title,
							title: title,
						})),
					},
				};
			}

			if (seniority) {
				newFilter = {
					...newFilter,
					seniority: {
						type: "dropdown",
						filterParam: "prospect.seniority",
						value: seniority.map((seniority) => ({
							id: seniority,
							title: seniority,
						})),
					},
				};
			}

			if (organization_num_employees_ranges) {
				// eslint-disable-next-line no-debugger
				debugger;

				const [from, till] =
					organization_num_employees_ranges?.at(0)?.split(",") ||
					organization_num_employees_ranges?.split(",") ||
					"";

				newFilter = {
					...newFilter,
					estimatedNumEmployees: {
						type: "between",
						filterParam: "organization.estimatedNumEmployees",
						value: [],
					},
					from_estimatedNumEmployees: {
						type: "between",
						filterParam: "organization.estimatedNumEmployees",
						value: from,
					},
					till_estimatedNumEmployees: {
						type: "between",
						filterParam: "organization.estimatedNumEmployees",
						value: till,
					},
				};
			}

			const restKeys = Object.keys(restParams);

			for (const key of restKeys) {
				newFilter = {
					...newFilter,
					[key]: {
						type: restParams[key]?.type as unknown as IFilterType,
						filterParam: restParams[key]?.filterParam,
						value: restParams[key].value,
					},
				};
			}

			setFilter(newFilter);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const CustomColumnFilters = useMemo(() => {
		let FilterDataType: IFilter[] = [];

		if (enrichmentTemplates?.data) {
			FilterDataType = enrichmentTemplates.data.map((template) => {
				if (template.type === CustomColumnTypes.AI_PROMPT) {
					return {
						name: template.name,
						filterParam: template.name,
						filterByType: [
							"dropdown",
							"isEmpty",
							"isNotEmpty",
							"textContains",
							"textDoesNotContain",
							"textStartsWith",
							"textEndsWith",
							"textIsExactly",
						],
						filterByValue: [],
						customColumnId: template.id,
					};
				}

				return {
					name: template.name,
					filterParam: template.name,
					filterByType: [
						"dropdown",
						"isEmpty",
						"isNotEmpty",
						"textContains",
						"textDoesNotContain",
						"textStartsWith",
						"textEndsWith",
						"textIsExactly",
					],
					filterByValue: [],
					customColumnId: template.id,
				};
			});
		}

		return FilterDataType;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enrichmentTemplates]);

	const FrequentlyUsedFilters = useMemo(() => {
		let FilterDataType: IFilter[] = [];

		if (data?.sourceType === ListSourceType.LI_EVENTS) {
			FilterDataType = FrequentlyUsedFiltersConfig;
		}

		if (data?.sourceType === ListSourceType.CSV_ENRICHMENTS) {
			FilterDataType = FrequentlyUsedFiltersConfig;
		}

		if (data?.sourceType === ListSourceType.PROSPECT_SEARCH) {
			FilterDataType = FrequentlyUsedFiltersConfig;
		}

		if (data?.sourceType === ListSourceType.MAPS) {
			FilterDataType = MapsFilter;

			if (data?.scrapeLevel === MapScrapeType.GET_ENRICHMENT) {
				FilterDataType = [...FilterDataType, ...FrequentlyUsedFiltersConfig];
			}
		}

		if (data?.sourceType === ListSourceType.JOBS) {
			FilterDataType = FrequentlyUsedJobFiltersConfig;

			if (data?.scrapeLevel === JobScrapeType.GET_ENRICHMENT) {
				FilterDataType = [...FilterDataType, ...FrequentlyUsedFiltersConfig];
			}
		}

		if (data?.sourceType === ListSourceType.LI_POST) {
			FilterDataType = [...FrequentlyUsedFiltersConfig, ...LiPostFilter];
		}

		if (data?.sourceType && [ListSourceType.LI_PROFILE].includes(data.sourceType)) {
			FilterDataType = [...FrequentlyUsedFiltersConfig, ...LiProfileFilter];
		}

		if (data?.sourceType === ListSourceType.LI_COMPANY_ACTIVITY) {
			FilterDataType = [...FrequentlyUsedFiltersConfig, ...LiCompanyActivityFilter];
		}

		if (FilterDataType.length === 0) {
			FilterDataType = FrequentlyUsedFiltersConfig;
		}

		return FilterDataType;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const additionalColumnsFilters = useMemo(() => {
		let FilterDataType: IFilter[] = [];

		if (data && FrequentlyUsedFilters?.length) {
			if (!FrequentlyUsedFilters) {
				return FilterDataType;
			}

			if (data?.sourceType === ListSourceType.CSV_ENRICHMENTS) {
				FilterDataType = AdditionalColumnsFiltersConfig;
			}

			if (data?.sourceType === ListSourceType.LI_EVENTS) {
				FilterDataType = AdditionalColumnsFiltersConfig;
			}

			if (data?.sourceType === ListSourceType.MAPS) {
				FilterDataType = MapsAdditionalFilter;

				if (data?.scrapeLevel === MapScrapeType.GET_ENRICHMENT) {
					FilterDataType = [
						...FilterDataType,
						...AdditionalColumnsFiltersConfig.filter(
							(filter) =>
								!FrequentlyUsedFilters.find((f) => f.name === filter.name) &&
								!FilterDataType.find((f) => f.name === filter.name)
						),
					];
				}
			}

			if (data?.sourceType === ListSourceType.JOBS) {
				FilterDataType = AdditionalColumnsJobFiltersConfig;

				if (data?.scrapeLevel === JobScrapeType.GET_ENRICHMENT) {
					FilterDataType = [
						...FilterDataType,
						...AdditionalColumnsFiltersConfig.filter(
							(filter) =>
								!FrequentlyUsedFilters.find((f) => f.name === filter.name) &&
								!FilterDataType.find((f) => f.name === filter.name)
						),
					];
				} else {
					FilterDataType = AdditionalColumnsNonLinkedinJobFiltersConfigForCreation;
				}
			}

			if (data?.sourceType === ListSourceType.LI_POST) {
				FilterDataType = AdditionalColumnsFiltersConfig;
			}

			if (data?.sourceType && [ListSourceType.LI_PROFILE].includes(data.sourceType)) {
				FilterDataType = LiProfileFilter;
			}

			if (data?.sourceType === ListSourceType.LI_COMPANY_ACTIVITY) {
				FilterDataType = AdditionalColumnsFiltersConfig;
			}

			if (FilterDataType.length === 0) {
				FilterDataType = AdditionalColumnsFiltersConfig.filter(
					(filter) => !FrequentlyUsedFilters.find((f) => f.name === filter.name)
				);
			}

			FilterDataType = FilterDataType.filter(
				(filter) => !FrequentlyUsedFilters.find((f) => f.name === filter.name)
			);
		}

		return FilterDataType;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, FrequentlyUsedFilters]);

	const handleDeleteColumn = async () => {
		await deleteColumn({ id: editableColumn?.id }).unwrap();
		setEditableColumn(undefined);
	};

	const submit = async () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let config: any = {};

		if (filterValue) {
			for (const filter of Object.keys(filterValue)) {
				if (filter === "country" && filterValue[filter].type === "dropdown") {
					config = {
						...config,
						person_locations: (filterValue[filter].value as { id: string; title: string }[])?.map(
							(a) => a.id
						),
					};
				} else if (filter === "companyCountry" && filterValue[filter].type === "dropdown") {
					config = {
						...config,
						organization_locations: (
							filterValue[filter].value as { id: string; title: string }[]
						)?.map((a) => a.id),
					};
				} else if (filter === "title" && filterValue[filter].type === "dropdown") {
					config = {
						...config,
						person_titles: (filterValue[filter].value as { id: string; title: string }[])?.map(
							(a) => a.id
						),
					};
				} else if (filter === "industry" && filterValue[filter].type === "dropdown") {
					config = {
						...config,
						industries: (filterValue[filter].value as { id: string; title: string }[])?.map(
							(a) => a.id
						),
					};
				} else if (filter === "seniority" && filterValue[filter].type === "dropdown") {
					config = {
						...config,
						seniority: (filterValue[filter].value as { id: string; title: string }[])?.map(
							(a) => a.id
						),
					};
				} else if (
					filter === "from_estimatedNumEmployees" &&
					filterValue[filter].type === "between"
				) {
					config = {
						...config,
						organization_num_employees_ranges: filterValue[filter].value,
					};
				} else if (
					filter === "till_estimatedNumEmployees" &&
					filterValue[filter].type === "between"
				) {
					config = {
						...config,
						organization_num_employees_ranges: `${config.organization_num_employees_ranges || ""},${
							filterValue[filter].value
						}`,
					};
				} else {
					config = {
						...config,
						[filter.replaceAll(" ", "_")]: {
							type: filterValue[filter].type,
							value: filterValue[filter].value,
							filterParam: filterValue[filter].filterParam,
							customColumnId: filterValue[filter].customColumnId,
						},
					};
				}
			}
		}

		if (data?.apollos && data?.apollos.length > 0) {
			updateSearch({
				id: data.apollos.find((apollo) => apollo.type === ApolloType.SOURCE)?.id || 0,
				name: data?.name || "",
				type: ApolloType.SOURCE,
				config: {
					...config,
				},
				listId: +id,
			});
		} else {
			saveSearch({
				name: data?.name || "",
				type: ApolloType.SOURCE,
				config: {
					...config,
				},
				leadsPerDay: 0,
				amount: 0,
				listId: +id,
			});
		}

		navigate(`/app/lists/create/score/${id}`);
	};

	const handleEdit = (column: ColumnConfig) => {
		setEditableColumn(column);
		setShowEditColumn(true);
	};
	const handleDelete = (column: ColumnConfig) => {
		setEditableColumn(column);
		setShowDeleteColumn(true);
	};

	return isFetching || !data ? (
		<LoadingOverlay />
	) : (
		<div className="sm:block flex flex-col items-start justify-between mb-32 pb-32">
			<Breadcrumb
				path={[
					{ name: ts("title"), link: "/" },
					{ name: ts("create.title"), link: "/create" },
					{ name: data?.name || "", link: `/create/${id}/details` },
				]}
			/>
			<div className="text-lg leading-9 mb-5">Create {ts(data.sourceType)} List</div>
			<Stepper
				availableSteps={getAvailableSteps(isDetailsNeeded(data), data)}
				currentStep="filter"
				doneSteps={getDoneSteps(data)}
				listId={data.id}
			/>
			<div className="w-full">
				<div className="text-md mb-5">Filter</div>
				<button
					className="text-ssm mb-4"
					onClick={() => {
						setExpandFrequentlyUsed(!expandFrequentlyUsed);
					}}
				>
					Frequently used <Icon icon={expandFrequentlyUsed ? faChevronUp : faChevronDown} />
				</button>
				{expandFrequentlyUsed && (
					<div className="flex flex-row gap-12">{renderFilters(FrequentlyUsedFilters)}</div>
				)}
				<hr className="border-t border-t-border mb-4" />
				<button
					className="text-ssm mb-4"
					onClick={() => {
						setAdditionalColumns(!additionalColumns);
					}}
				>
					Additional Columns <Icon icon={additionalColumns ? faChevronUp : faChevronDown} />
				</button>
				{additionalColumns && (
					<div className="flex flex-row gap-12">{renderFilters(additionalColumnsFilters)}</div>
				)}
				<hr className="border-t border-t-border mb-4" />
				<button
					className="text-ssm mb-4"
					onClick={() => {
						setEnrichments(!enrichments);
					}}
				>
					Enrichments <Icon icon={enrichments ? faChevronUp : faChevronDown} />
				</button>
				{enrichments && (
					<div className="flex flex-row gap-12">
						{renderFilters(CustomColumnFilters, true, true)}
					</div>
				)}
				<hr className="border-t border-t-border mb-4" />
				<button
					className="text-ssm mb-4"
					onClick={() => {
						setColumns(!columns);
					}}
				>
					Columns <Icon icon={columns ? faChevronUp : faChevronDown} />
				</button>
				{columns && (
					<>
						<div className="flex flex-col">
							{data?.columns
								?.filter((c) => c.type !== CustomColumnTypes.AI_PROMPT)
								?.map((column) => (
									<div
										key={column.id}
										className="flex flex-row items-center p-2 space-x-2 bg-white border border-gray-200 rounded-xl mb-4 px-4 py-3 w-[370px]"
									>
										<div className="flex grow">{column.name}</div>
										<div className="flex flex-row items-center ml-auto">
											<button className="mr-2" onClick={() => handleEdit(column)}>
												<img alt="settingsWheel" className="cursor-pointer" src={settingsWheel} />
											</button>
											<button onClick={() => handleDelete(column)}>
												<img alt="delete" className="cursor-pointer" src={trashIcon} />
											</button>
										</div>
									</div>
								))}
						</div>
						<div className="flex flex-row gap-4 w-full mb-4">
							<div className="w-auto">
								<Button
									color={ButtonColor.BRAND}
									image={<Icon className="mr-2" icon={faAdd} />}
									size={ButtonSize.ML}
									title={t("inbox.addColumn")}
									onClick={() => setShowNewColumn(true)}
								/>
							</div>
						</div>
					</>
				)}
			</div>

			<div className="absolute mt-14 h-[72px] flex flex-row justify-between items-center w-auto border-t border-t-border px-8 py-[14px] rounded-t-16 bottom-0 right-0 z-20 bg-white w-full">
				<div></div>
				<div className="flex flex-row self-left items-center gap-x-4">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						title={t("basics.previous")}
						onClick={() => navigate(`/app/lists/create/source/${id}`)}
					/>
					<Button
						disabled={
							data.sourceType === ListSourceType.PROSPECT_SEARCH &&
							filterValue &&
							Object.keys(filterValue).length === 0
						}
						isLoading={isLoading || isUpdating}
						title={t("basics.next")}
						onClick={submit}
					/>
				</div>
			</div>
			{showNewColumn && id && (
				<CreateCustomColumnModal
					customColumnTypes={[
						CustomColumnTypes.TEXT,
						CustomColumnTypes.NUMBER,
						CustomColumnTypes.BOOLEAN,
						CustomColumnTypes.LINK,
						CustomColumnTypes.RANDOM_VALUE,
					]}
					listId={+id}
					open={showNewColumn}
					setOpen={setShowNewColumn}
				/>
			)}
			{showEditColumn && id && editableColumn?.id && (
				<EditCustomColumnModalWrapper
					columnId={editableColumn?.id}
					listId={+id}
					open={showEditColumn}
					setOpen={setShowEditColumn}
				/>
			)}
			{showDeleteColumn && id && editableColumn?.id && (
				<Modal
					handleClose={() => setShowDeleteColumn(false)}
					handleSave={handleDeleteColumn}
					isLoading={deleteLoading}
					isOpened={showDeleteColumn}
					submitButtonColor={ButtonColor.DANGER}
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<div className="text-sm text-gray-700 mb-4">{ts("delete.confirmation")}</div>
					<TextField
						fullWidth
						defaultValue={editableColumn.name}
						inputProps={{ readOnly: true }}
						label={ts("name")}
					/>
				</Modal>
			)}
		</div>
	);
};
