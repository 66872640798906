import { t } from "i18next";

import { ChangeEvent, useEffect, useRef, useState } from "react";

import { TextField, Autocomplete } from "@mui/material";

import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";

import { AutoCompleteItem } from "@/components/Dropdown/Dropdown";
import { FilterDropdown } from "@/components/FilterDropdown.tsx/FilterDropdown";
import { InputField } from "@/components/InputField/InputField";
import { InclusionFilterStatus } from "@/enum/list.enum";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import { useLazyGetFilterByPropertyQuery } from "@/pages/Private/redux/list/list.api";

import { Icon, LoadingOverlay } from "@/components";

import {
	IBooleanFilterType,
	IFilter,
	IFilterType,
	INumericFilterType,
	ITextFilterType,
} from "../constans/general-filter";
import { IFilterWithValues } from "../components/FilterModal";
import {
	ICurrentFilter,
	ICurrentFilterValue,
} from "../pages/ListEditFilter/interfaces/config-interface";

export const useFilters = ({ filter }: { filter?: IFilterWithValues }) => {
	const [filterType, setFilterType] = useState<{ [key: string]: IFilterType }>({});

	const [searchValue, setSearchValue] = useState<{ [key: string]: string }>();
	const [query, setQuery] = useState<{ [key: string]: string }>({});

	const searchTimer = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (searchTimer.current) {
			clearTimeout(searchTimer.current);
		}

		searchTimer.current = setTimeout(() => {
			searchValue &&
				Object.keys(searchValue).forEach((key) => {
					setQuery({ ...query, [key]: searchValue[key] });
				});
		}, 800);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	const [filterValue, setFilterValue] = useState<IFilterWithValues>({ ...filter });

	useEffect(() => {
		if (filter) {
			const prepFilterType =
				filter &&
				Object.keys(filter).reduce((acc, curr) => {
					return { ...acc, [curr]: filter[curr].type };
				}, {});

			if (prepFilterType) {
				setFilterType(prepFilterType);
			}

			setFilterValue(filter);
		}
	}, [filter]);

	const [getFilterByValue] = useLazyGetFilterByPropertyQuery();

	const [inputValues, setInputValues] = useState<string>();

	const [isLoadingByPropertyName, setIsLoadingByPropertyName] = useState<string | null>(null);

	const [currentFilter, setCurrentFilter] = useState<ICurrentFilter>({});

	useEffect(() => {
		const getFilterValues = async () => {
			if (filterType) {
				const filtersToIterate = Object.keys(filterType);

				let currentFilterVaules: ICurrentFilter = { ...currentFilter };

				for await (const key of filtersToIterate) {
					if (
						(filterType[key] === "dropdown" ||
							filterType[key] === "dropdownExclude" ||
							filterType[key] === "dropdownCustom" ||
							filterType[key] === "dropdownCustomExclude") &&
						(!currentFilter?.[key] || query[key]?.length > 0)
					) {
						setIsLoadingByPropertyName(key);
						const filterValues = await getFilterByValue({
							propertyName: key,
							searchTerm: query?.[key] || "",
						}).unwrap();

						if (filterValues?.data) {
							currentFilterVaules = {
								...currentFilterVaules,
								[key]: filterValues.data.map((rec) => {
									return {
										id: rec?.id?.toString() || rec.value,
										title: rec.value,
									} as ICurrentFilterValue;
								}) as ICurrentFilterValue[],
							} as ICurrentFilter;
						}

						setIsLoadingByPropertyName(null);
					}
				}

				setCurrentFilter({
					...currentFilterVaules,
				});
			}
		};

		getFilterValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterType, query]);

	const [show, setShow] = useState<number[]>([]);
	const [prefill, setPrefill] = useState<boolean>(true);

	const renderFilters = (
		FilterDataType: IFilter[],
		hasCheckbox?: boolean,
		noTranslations?: boolean
	) => {
		if (hasCheckbox && prefill && FilterDataType?.length > 0) {
			FilterDataType.map((item, index) => {
				if (!show.includes(index) && Object.keys(filterType).includes(item.name)) {
					setShow([...show, index]);
				}
			});
		}

		return (
			<div className="flex flex-col">
				{FilterDataType.map((filter, index) => {
					return (
						<div key={filter.name} className="flex flex-row items-start">
							<div className="flex flex-row w-[200px] mr-8 items-center h-[48px]">
								{hasCheckbox ? (
									<Checkbox
										isChecked={show.includes(index)}
										onChange={() => {
											setPrefill(false);
											if (show.includes(index)) {
												setShow(show.filter((s) => s !== index));

												// remove from filterValue
												const filteredFilterValue =
													filterValue && Object.keys(filterValue)?.filter((f) => f !== filter.name);
												const newFilteredValues = filteredFilterValue?.reduce((acc, curr) => {
													return { ...acc, [curr]: filterValue?.[curr] };
												}, {});

												setFilterValue(newFilteredValues);

												// remove from filterType
												const filteredFilterType =
													filterType && Object.keys(filterType)?.filter((f) => f !== filter.name);
												const newFilteredType = filteredFilterType?.reduce((acc, curr) => {
													return { ...acc, [curr]: filterType?.[curr] };
												}, {});

												setFilterType(newFilteredType);

												return;
											}

											setShow([...show, index]);
										}}
									>
										<div className="text-[18px] text-text flex items-center">
											{noTranslations ? <>{filter.name}</> : <>{t(`inbox.${filter.name}`)}</>}
										</div>
									</Checkbox>
								) : (
									<>{t(`inbox.${filter.name}`)}</>
								)}
							</div>
							{((hasCheckbox && show.includes(index)) || typeof hasCheckbox !== "boolean") && (
								<>
									<div className="flex-col w-[370px] mr-8">
										<FilterDropdown
											className="w-full mb-3"
											data={[
												{
													id: "noFilter",
													title: t(`filter.noFilter`),
												},
												...filter.filterByType.map((type) => {
													return {
														id: type,
														title: t(`filter.${type}`),
													};
												}),
											]}
											defaultValue={{
												id: "noFilter",
												title: t(`filter.noFilter`),
											}}
											floating={true}
											handleSelect={(value: AutoCompleteItem) => {
												setFilterType({ ...filterType, [filter.name]: value.id as IFilterType });

												const filteredFilterValue =
													filterValue && Object.keys(filterValue)?.filter((f) => f !== filter.name);
												const newFilteredValues = filteredFilterValue?.reduce((acc, curr) => {
													return { ...acc, [curr]: filterValue?.[curr] };
												}, {});

												if (IBooleanFilterType.includes(value.id as IFilterType)) {
													setFilterValue({
														...newFilteredValues,
														[filter.name]: {
															filterParam: filter.filterParam,
															type: value.id as IFilterType,
														},
													});

													return;
												}

												setFilterValue(newFilteredValues);
											}}
											value={
												filterType?.[filter.name]
													? {
															id: filterType[filter.name] as string,
															title: t(`filter.${filterType?.[filter.name]}`),
													  }
													: {
															id: "noFilter",
															title: t(`filter.noFilter`),
													  }
											}
										/>
									</div>
									<div className="flex flex-col w-[370px] ">
										{filterType &&
											Object.keys(filterType).map((fT, index) => {
												if (fT !== filter.name) {
													return <></>;
												}

												if (ITextFilterType.some((type) => type === filterType[fT])) {
													return (
														<div key={`ft-${index}`}>
															<InputField
																className="mb-0"
																handleChange={function (
																	event: ChangeEvent<HTMLInputElement>
																): void {
																	setFilterValue({
																		...filterValue,
																		[filter.name]: {
																			value: event.target.value,
																			type: filterType[fT],
																			filterParam: filter.filterParam,
																			customColumnId: filter.customColumnId,
																		},
																	});
																}}
																name={filter.name}
																placeholder={t(filter.name)}
																value={(filterValue?.[filter.name]?.value as string) || ""}
															/>
														</div>
													);
												}

												if (
													filterType[fT] !== "between" &&
													INumericFilterType.some((type) => type === filterType[fT])
												) {
													return (
														<div key={`ft-${index}`}>
															<InputField
																handleChange={function (
																	event: ChangeEvent<HTMLInputElement>
																): void {
																	setFilterValue({
																		...filterValue,
																		[filter.name]: {
																			value: event.target.value,
																			type: filterType[fT],
																			filterParam: filter.filterParam,
																			customColumnId: filter.customColumnId,
																		},
																	});
																}}
																name={filter.name}
																placeholder={t(filter.name)}
																type="number"
																value={(filterValue?.[filter.name]?.value as number) || ""}
															/>
														</div>
													);
												}

												if (
													filterType[fT] === "between" &&
													INumericFilterType.some((type) => type === filterType[fT])
												) {
													return (
														<div key={`ft-${index}`}>
															<InputField
																handleChange={function (
																	event: ChangeEvent<HTMLInputElement>
																): void {
																	setFilterValue({
																		...filterValue,
																		[`from_${filter.name}`]: {
																			value: event.target.value,
																			type: filterType[fT],
																			filterParam: filter.filterParam,
																		},
																	});
																}}
																name={`from_${filter.name}`}
																placeholder={t(`from_${filter.name}`)}
																type="number"
																value={
																	(filterValue?.[`from_${filter.name}`]?.value as number) || ""
																}
															/>
															<InputField
																handleChange={function (
																	event: ChangeEvent<HTMLInputElement>
																): void {
																	setFilterValue({
																		...filterValue,
																		[`till_${filter.name}`]: {
																			value: event.target.value,
																			type: filterType[fT],
																			filterParam: filter.filterParam,
																		},
																	});
																}}
																name={`till_${filter.name}`}
																placeholder={t(`till_${filter.name}`)}
																type="number"
																value={
																	(filterValue?.[`till_${filter.name}`]?.value as number) || ""
																}
															/>
														</div>
													);
												}

												if (filterType[fT] === "dropdown" && fT === "inclusionStatus") {
													return (
														<div key={`ft-${index}`}>
															<FilterDropdown
																className="w-full mb-3"
																data={[
																	{
																		title: InclusionFilterStatus.ADDED,
																		id: InclusionFilterStatus.ADDED,
																	},
																	{
																		title: InclusionFilterStatus.EXCLUDED,
																		id: InclusionFilterStatus.EXCLUDED,
																	},
																	{
																		title: InclusionFilterStatus.MAYBE,
																		id: InclusionFilterStatus.MAYBE,
																	},
																	{
																		title: InclusionFilterStatus.BOTH,
																		id: InclusionFilterStatus.BOTH,
																	},
																]}
																floating={true}
																handleSelect={(value: AutoCompleteItem) => {
																	if (value.id) {
																		setFilterValue({
																			...filterValue,
																			[filter.name]: {
																				value: value.id,
																				type: filterType[fT],
																				filterParam: filter.filterParam,
																			},
																		});
																	}
																}}
															/>
														</div>
													);
												} else if (filterType[fT] === "dropdown") {
													if (!currentFilter[filter.name]) {
														return (
															<>
																<LoadingOverlay />
															</>
														);
													}

													return (
														<Autocomplete
															key={`ft-${index}`}
															multiple
															defaultValue={[]}
															getOptionLabel={(option) => option.title}
															inputValue={searchValue?.[filter.name] || ""}
															isOptionEqualToValue={(option, currentValue) => {
																if (currentValue.title === "" && currentValue.id === "") {
																	return true;
																}

																return option.id === currentValue.id;
															}}
															loading={isLoadingByPropertyName === filter.name}
															loadingText="Loading..."
															options={currentFilter[filter.name] || []}
															renderInput={(params) => (
																<TextField
																	sx={{
																		width: "100%",
																		minHeight: 48,
																		marginBottom: 1,
																		"& .MuiFilledInput-root": {
																			minHeight: 48,
																			marginBottom: 0,
																			borderRadius: 1.5,
																			paddingTop: "10px",
																			paddingBottom: "10px !important",
																			paddingLeft: 1.5,
																		},
																	}}
																	{...params}
																/>
															)}
															size="small"
															sx={{
																width: "100%",
															}}
															value={
																(filterValue?.[filter.name]?.value as {
																	title: string;
																	id: string;
																}[]) || []
															}
															onChange={(event, value) => {
																setFilterValue({
																	...filterValue,
																	[filter.name]: {
																		value: value,
																		type: filterType[fT],
																		filterParam: filter.filterParam,
																	},
																});

																setSearchValue({});
															}}
															onInputChange={(event, newValue, reason) => {
																if (reason === "input") {
																	setSearchValue({ [filter.name]: newValue });
																}
															}}
														/>
													);
												} else if (
													filterType[fT] === "dropdownCustom" ||
													filterType[fT] === "dropdownCustomExclude"
												) {
													if (!currentFilter[filter.name]) {
														return (
															<>
																<LoadingOverlay />
															</>
														);
													}

													return (
														<Autocomplete
															key={`ft-${index}`}
															multiple
															clearOnBlur={true}
															getOptionLabel={(option) => option.title}
															inputValue={inputValues || ""}
															isOptionEqualToValue={(option, currentValue) => {
																if (currentValue.title === "" && currentValue.id === "") {
																	return true;
																}

																return option.id === currentValue.id;
															}}
															loading={isLoadingByPropertyName === filter.name}
															loadingText="Loading..."
															options={
																[
																	{ id: inputValues || "", title: inputValues || "" },
																	...currentFilter[filter.name],
																]?.filter((r) => r.id !== "") || []
															}
															popupIcon={
																<Icon
																	className="text-gray-500 h-[24px] w-[15px]"
																	icon={faChevronDown}
																/>
															}
															renderInput={(params) => (
																<TextField
																	sx={{
																		width: "100%",
																		minHeight: 48,
																		marginBottom: 1,
																		"& .MuiFilledInput-root": {
																			minHeight: 48,
																			marginBottom: 0,
																			borderRadius: 1.5,
																			paddingTop: "10px",
																			paddingBottom: "10px !important",
																			paddingLeft: 1.5,
																		},
																	}}
																	{...params}
																/>
															)}
															size="small"
															sx={{
																width: "100%",
															}}
															value={
																(filterValue?.[filter.name]?.value as {
																	title: string;
																	id: string;
																}[]) || []
															}
															onBlur={() => {
																setSearchValue({});
															}}
															onChange={(event, value) => {
																setFilterValue({
																	...filterValue,
																	[filter.name]: {
																		value: value,
																		type: filterType[fT],
																		filterParam: filter.filterParam,
																	},
																});
																setInputValues("");
																setSearchValue({});
															}}
															onInputChange={(event, newValue, reason) => {
																if (reason === "input") {
																	setInputValues(newValue);
																	setSearchValue({ [filter.name]: newValue });
																}
															}}
														/>
													);
												}

												return <></>;
											})}
									</div>
								</>
							)}
						</div>
					);
				})}
			</div>
		);
	};

	return { renderFilters, filterType, filterValue, setFilterType, setFilterValue };
};
