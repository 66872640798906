/* eslint-disable @typescript-eslint/no-explicit-any */
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

import { TFunction } from "i18next";

import { Icon } from "@/components";
import { SvgIcon } from "@/components/Icon/SvgIcon";
import { TableColumn } from "@/components/Table/Table";
import { ListSourceType } from "@/enum/list.enum";

import { ReactComponent as eye } from "@assets/icons/create-list/eye.svg";
import { ReactComponent as pencil } from "@assets/icons/create-list/pencil.svg";
import { ReactComponent as trash } from "@assets/icons/create-list/trash.svg";

import { LiProfile } from "../../LiProfile/schema/liProfile";

export const getCustomTableColumns = ({
	type,
	handleSelect,
	handleDeleteModal,
	handleRemove,
	handlePreviewModal,
	t,
}: {
	type: ListSourceType;
	handleSelect?: (row: any) => void;
	handleDeleteModal?: (row: any) => void;
	handleRemove?: (row: any) => Promise<void>;
	handlePreviewModal?: (row: any) => Promise<void>;
	t: TFunction<"translation", undefined, "translation">;
}) => {
	const liCompanyActivityColumns: TableColumn[] = [
		{
			title: "name",
			uid: "name",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => <div className="max-w-[250px] truncate">{row.name}</div>,
		},
		{
			title: "",
			uid: "action",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => (
				<div className="flex items-center grow">
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={pencil}
						onClick={() => {
							handleSelect?.(row);
						}}
					/>
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={trash}
						onClick={() => {
							handleDeleteModal?.(row);
						}}
					/>
				</div>
			),
		},
	];

	const liPostColumns: TableColumn[] = [
		{
			title: "name",
			uid: "name",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => <div className="grow truncate">{row.name}</div>,
		},
		{
			title: "",
			uid: "action",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => (
				<div className="flex items-center justify-end grow">
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={pencil}
						onClick={() => {
							handleSelect?.(row);
						}}
					/>
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={trash}
						onClick={() => {
							handleDeleteModal?.(row);
						}}
					/>
				</div>
			),
		},
	];

	const liProfileVisitsColumns: TableColumn[] = [
		{
			title: "name",
			uid: "name",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => <div className="grow truncate">{row.name}</div>,
		},
		{
			title: "",
			uid: "action",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => (
				<div className="flex items-center justify-end grow">
					<Icon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						icon={faArrowLeft}
						onClick={() => {
							handleRemove?.(row);
						}}
					/>
				</div>
			),
		},
	];

	const mapColumns: TableColumn[] = [
		{
			title: "query",
			uid: "query",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => <div className="flex items-center">{row.query}</div>,
		},
		{
			title: "quantity",
			uid: "quantity",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => <div className="flex items-center">{row.limit}</div>,
		},
		{
			title: "",
			uid: "action",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => (
				<div className="flex items-center grow">
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={eye}
						onClick={() => {
							handlePreviewModal?.(row);
						}}
					/>
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={pencil}
						onClick={() => {
							handleSelect?.(row);
						}}
					/>
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={trash}
						onClick={() => {
							handleDeleteModal?.(row);
						}}
					/>
				</div>
			),
		},
	];

	const liEventColumns: TableColumn[] = [
		{
			title: "name",
			uid: "name",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => <div className="flex items-center">{row.name}</div>,
		},
		{
			title: "type",
			uid: "type",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => <div className="flex items-center">{row.type}</div>,
		},
		{
			title: "",
			uid: "action",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => (
				<div className="flex items-center grow">
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={pencil}
						onClick={() => {
							handleSelect?.(row);
						}}
					/>
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={trash}
						onClick={() => {
							handleDeleteModal?.(row);
						}}
					/>
				</div>
			),
		},
	];

	const jobColumns: TableColumn[] = [
		{
			title: "query",
			uid: "query",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,
			render: (row: any) => <div className="flex items-center">{row.query}</div>,
		},
		{
			title: "",
			uid: "action",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => (
				<div className="flex items-center grow">
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={pencil}
						onClick={() => {
							handleSelect?.(row);
						}}
					/>
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={trash}
						onClick={() => {
							handleDeleteModal?.(row);
						}}
					/>
				</div>
			),
		},
	];

	const liProfileColumns: TableColumn[] = [
		{
			title: "name",
			uid: "name",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,
			render: (row: LiProfile) => <div className="flex items-center">{row.name}</div>,
		},
		{
			title: "type",
			uid: "type",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,
			render: (row: LiProfile) => (
				<div className="flex items-center">{t?.(`list.${row.type}`)}</div>
			),
		},
		{
			title: "",
			uid: "action",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,
			render: (row: LiProfile) => (
				<div className="flex items-center grow">
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={pencil}
						onClick={() => {
							handleSelect?.(row);
						}}
					/>
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={trash}
						onClick={() => {
							handleDeleteModal?.(row);
						}}
					/>
				</div>
			),
		},
	];

	const csvEnrichmentColumns: TableColumn[] = [
		{
			title: "name",
			uid: "name",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => <div className="flex items-center">{row.name}</div>,
		},
		{
			title: "",
			uid: "action",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,

			render: (row: any) => (
				<div className="flex items-center grow">
					<SvgIcon
						className="w-[24px] h-[24px] cursor-pointer text-gray-700 ml-1"
						svgIcon={trash}
						onClick={() => {
							handleDeleteModal?.(row);
						}}
					/>
				</div>
			),
		},
	];

	if (type === ListSourceType.LI_POST) {
		return liPostColumns;
	}

	if (type === ListSourceType.LI_PROFILE_VISITS) {
		return liProfileVisitsColumns;
	}

	if (type === ListSourceType.LI_COMPANY_ACTIVITY) {
		return liCompanyActivityColumns;
	}

	if (type === ListSourceType.MAPS) {
		return mapColumns;
	}

	if (type === ListSourceType.LI_EVENTS) {
		return liEventColumns;
	}

	if (type === ListSourceType.JOBS) {
		return jobColumns;
	}

	if (type === ListSourceType.CSV_ENRICHMENTS) {
		return csvEnrichmentColumns;
	}

	if (type === ListSourceType.LI_PROFILE) {
		return liProfileColumns;
	}

	if (type === ListSourceType.LI_PEOPLE) {
		return liEventColumns;
	}
};
