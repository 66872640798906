import { FunctionComponent, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";

import { Link } from "react-router-dom";

import GoogleMapsIcon from "@assets/icons/google-maps.svg";

import { ExternalLink } from "@/components/ExternalLink/ExternalLink";

import { Loader, Modal } from "@/components";

import { Map } from "@/pages/Private/pages/Map/schema/map";

import { Table, TableColumn } from "@/components/Table/Table";

import { useDeleteMapMutation, useLazyGetMapPreviewQuery } from "@/pages/Private/redux/map/map.api";
import {
	useLazyGetListQuery,
	useRemoveSourceFromListMutation,
} from "@/pages/Private/redux/list/list.api";

import { useDeleteJobMutation } from "@/pages/Private/redux/job/job.api";

import { useDeleteCsvEnrichmentMutation } from "@/pages/Private/redux/csvEnrichment/csvEnrichment.api";

import { ListSourceType } from "@/enum/list.enum";

import { useDeleteLiEventMutation } from "@/pages/Private/redux/liEvent/liEvent.api";

import { useDeleteLiPostMutation } from "@/pages/Private/redux/liPost/liPost.api";

import { useDeleteLiCompanyActivityMutation } from "@/pages/Private/redux/liCompanyActivity/liCompanyActivity.api";

import { useDeleteLiProfileMutation } from "@/pages/Private/redux/liProfile/liProfile.api";

import { useDeleteLiPeopleMutation } from "@/pages/Private/redux/liPeople/liPeople.api";

import { List } from "../schema/list";
import { Job } from "../../Job/schema/job";
import { IMapResponseItem } from "../../Map/interface";
import { LiEvent } from "../../LiEvent/schema/liEvent";
import { LiCompanyActivity } from "../../LiCompanyActivity/schema/liCompanyActivity";
import { LiPost } from "../../LiPost/schema/liPost";
import { LiProfileVisits } from "../../LiProfileVisits/schema/liProfileVisits";
import { CsvEnrichment } from "../../CsvEnrichment/schema/csvEnrichment";
import { LiProfile } from "../../LiProfile/schema/liProfile";
import { getCustomTableColumns } from "../utils/addedSearchTableConfigs";

export interface AddedSourceListProps {
	list: List;
	handleSelect: (map: Map | Job | LiCompanyActivity | LiPost | LiProfileVisits) => void;
}

export const AddedSourceList: FunctionComponent<AddedSourceListProps> = ({
	list,
	handleSelect,
}) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`list.sources.${key}`), [t]);

	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [showPreview, setShowPreview] = useState<boolean>(false);
	const [selectedMap, setSelectedMap] = useState<
		Map | Job | LiEvent | LiPost | LiCompanyActivity | CsvEnrichment | LiProfile | null
	>(null);
	const [mapData, setMapData] = useState<IMapResponseItem[] | null>(null);
	const [deleteMap, { isLoading: isDeleteLoading }] = useDeleteMapMutation();
	const [deleteJob, { isLoading: jobIsDeleteLoading }] = useDeleteJobMutation();
	const [deleteLiEvent, { isLoading: liEventIsDeleteLoading }] = useDeleteLiEventMutation();
	const [deleteLiPost, { isLoading: liPostIsDeleteLoading }] = useDeleteLiPostMutation();
	const [deleteLiProfile, { isLoading: liProfileIsDeleteLoading }] = useDeleteLiProfileMutation();
	const [deleteLiPeople, { isLoading: LiPeopleIsDeleteLoading }] = useDeleteLiPeopleMutation();
	const [removeSourceFromList] = useRemoveSourceFromListMutation();
	const [deleteLiCompanyActivity, { isLoading: liCompanyActivityIsDeleteLoading }] =
		useDeleteLiCompanyActivityMutation();
	const [deleteCsvEnrichment, { isLoading: csvEnrichmentIsDeleteLoading }] =
		useDeleteCsvEnrichmentMutation();

	const [refreshList] = useLazyGetListQuery();
	const [fetchPreview, { isLoading: previewIsLoading }] = useLazyGetMapPreviewQuery();

	const previewColumns: TableColumn[] = [
		{
			title: "name",
			uid: "name",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			render: (row: IMapResponseItem) => (
				<div className="flex items-center">
					{row.name}
					<Link target="_blank" to={row.website ?? ""}>
						<ExternalLink />
					</Link>{" "}
					<Link target="_blank" to={row.place_link ?? ""}>
						<img className="w-[20px] h-[20px]" src={GoogleMapsIcon} />
					</Link>
				</div>
			),
		},
		{
			title: "type",
			uid: "type",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			render: (row: IMapResponseItem) => <div className="flex items-center">{row.type}</div>,
		},
		{
			title: "website",
			uid: "website",
			allowExclude: false,
			hideable: false,
			moveable: false,
			show: true,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			render: (row: IMapResponseItem) => <div className="flex items-center">{row.website}</div>,
		},
	];

	const handleRemove = async (row: LiProfileVisits) => {
		await removeSourceFromList({ sourceId: row.id, id: list.id }).unwrap();
	};

	const handleDeleteModal = (row: Map | Job) => {
		setShowDeleteModal(true);
		setSelectedMap(row);
	};

	const handlePreviewModal = async (row: Map | Job) => {
		const results = await fetchPreview(row.id).unwrap();

		if (results.data) {
			setMapData(results.data);
		}

		setShowPreview(true);
	};

	const handleDeleteMap = async () => {
		try {
			if (list.sourceType === ListSourceType.MAPS) {
				await deleteMap({ id: selectedMap?.id ?? 0, listId: list.id }).unwrap();
			}

			if (list.sourceType === ListSourceType.JOBS) {
				await deleteJob({ id: selectedMap?.id ?? 0, listId: list.id }).unwrap();
			}

			if (list.sourceType === ListSourceType.LI_EVENTS) {
				await deleteLiEvent({ id: selectedMap?.id ?? 0, listId: list.id }).unwrap();
			}

			if (list.sourceType === ListSourceType.CSV_ENRICHMENTS) {
				await deleteCsvEnrichment({ id: selectedMap?.id ?? 0, listId: list.id }).unwrap();
			}

			if (list.sourceType === ListSourceType.LI_POST) {
				await deleteLiPost({ id: selectedMap?.id ?? 0, listId: list.id }).unwrap();
			}

			if (list.sourceType === ListSourceType.LI_PROFILE) {
				await deleteLiProfile({ id: selectedMap?.id ?? 0, listId: list.id }).unwrap();
			}

			if (list.sourceType === ListSourceType.LI_COMPANY_ACTIVITY) {
				await deleteLiCompanyActivity({ id: selectedMap?.id ?? 0, listId: list.id }).unwrap();
			}

			if (list.sourceType === ListSourceType.LI_PEOPLE) {
				await deleteLiPeople({ id: selectedMap?.id ?? 0, listId: list.id }).unwrap();
			}

			setShowDeleteModal(false);
			refreshList(list.id);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<div className="w-full mt-8 bg-brand-white p-4 rounded-xl border border-gray-200">
			{previewIsLoading && <Loader />}
			<div className="text-md mb-8">Added searches</div>
			<div className="w-full bg-white border border-divider rounded-xl">
				<table className="w-full">
					<thead className="border-b border-b-divider">
						{getCustomTableColumns({
							type: list.sourceType,
							handleSelect,
							handleDeleteModal,
							handlePreviewModal,
							t,
						})?.map((column) => {
							return (
								<th
									key={column.uid}
									className="text-left text-table-header text-xs px-4 items-center h-[44px]"
								>
									{column.title}
								</th>
							);
						})}
					</thead>
					<tbody>
						{[
							...list.maps,
							...list.jobs,
							...list.csvEnrichments,
							...list.liEvents,
							...list.liCompanyActivity,
							...list.liPosts,
							...list.liProfileVisits,
							...list.liProfile,
							...list.liPeople,
						].map((item, index) => {
							return (
								<tr key={index} className="border-b border-b-divider">
									{getCustomTableColumns({
										type: list.sourceType,
										handleSelect,
										handleDeleteModal,
										handleRemove,
										handlePreviewModal,
										t,
									})?.map((column) => {
										return (
											<td
												key={column.uid}
												className="px-4 items-center h-[44px] text-sm font-medium"
											>
												{column.render(item)}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteMap}
					isLoading={
						isDeleteLoading ||
						jobIsDeleteLoading ||
						csvEnrichmentIsDeleteLoading ||
						liEventIsDeleteLoading ||
						liPostIsDeleteLoading ||
						liCompanyActivityIsDeleteLoading ||
						liProfileIsDeleteLoading ||
						LiPeopleIsDeleteLoading
					}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedMap?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}

			{showPreview && (
				<Modal
					handleClose={() => setShowPreview(false)}
					isLoading={isDeleteLoading}
					isOpened={showPreview}
					size="lg"
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={"Preview"}
				>
					<Table
						columns={previewColumns}
						data={mapData || []}
						hideFooter={true}
						hidePagination={true}
					/>
				</Modal>
			)}
		</div>
	);
};
