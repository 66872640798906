import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";

import { useSearch } from "@/utils";
import { useLazyGeSuggestionQuery } from "@/pages/Private/redux/liPeople/liPeople.api";

export interface LinkedinParams {
	name: string;
	id: string;
}

interface LinkedinTagAutocompleteProps {
	value?: LinkedinParams[];
	type: string;
	handleValueChange: (value: LinkedinParams[]) => void;
}

export const LinkedinTagAutocomplete: FunctionComponent<LinkedinTagAutocompleteProps> = ({
	value,
	type,
	handleValueChange,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liPeople.${key}`);
	const { query, setSearchValue } = useSearch();
	const [inputValue, setInputValue] = useState<string>();
	const [options, setOptions] = useState<LinkedinParams[]>([]);
	const [defaults, setDefaults] = useState<LinkedinParams[]>([]);
	const [fetchTags] = useLazyGeSuggestionQuery();

	useEffect(() => {
		setInputValue(query);
	}, [query, type]);

	useEffect(() => {
		if (value) {
			setDefaults(
				value?.map((value) => {
					return { name: value.name, id: value.id };
				}) || []
			);
		}
	}, [value]);

	useEffect(() => {
		if (inputValue) {
			fetchTags({ type, keyword: inputValue }).then((results) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const optionValues = results?.data?.suggestions?.map((res) => {
					return {
						name: res.title,
						id: res.urn,
					};
				});

				if (optionValues) {
					setOptions([...optionValues]);
				}
			});
		}
	}, [inputValue, fetchTags, type]);

	return (value && defaults) || !value ? (
		<div className="flex flex-col w-full">
			<div className="text-sm font-medium text-gray-900 mb-2">{ts(`${type}.label`)}</div>
			<Autocomplete
				multiple
				defaultValue={[]}
				filterOptions={(options) => {
					return options;
				}}
				getOptionLabel={(option) => option.name}
				id={type}
				options={options}
				renderInput={(params) => (
					<TextField
						sx={{
							width: "100%",
							minHeight: 48,
							marginBottom: 1,
							"& .MuiFilledInput-root": {
								minHeight: 48,
								marginBottom: 0,
								borderRadius: 1.5,
								paddingTop: "10px",
								paddingBottom: "10px !important",
								paddingLeft: 1.5,
							},
						}}
						{...params}
					/>
				)}
				size="medium"
				sx={{
					width: "100%",
				}}
				value={defaults}
				onChange={(event, value) => {
					handleValueChange(value);
				}}
				onInputChange={(event, newInputValue) => {
					setSearchValue(newInputValue);
				}}
			/>
		</div>
	) : (
		<></>
	);
};
