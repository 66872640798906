import { Stack, Link } from "@mui/material";

import { format } from "date-fns";

import { TableColumn } from "@/components/Table/Table";

import { ExternalLink } from "@/components/ExternalLink/ExternalLink";

import { SortParams } from "@/types/types";

import { ListPerson } from "../schema/list";
import { getContextMenuItem } from "../utils/contextMenu";
import {
	renderEstimatedNumEmployeesColumn,
	renderFoundedYearColumn,
	renderPersonCityColumn,
	renderPersonStateColumn,
	renderPersonCountryColumn,
	renderCompanyCityColumn,
	renderCompanyStateColumn,
	renderCompanyCountryColumn,
	renderLanguageColumn,
	renderOtherListsColumn,
	renderConnectionsColumn,
	renderGenderColumn,
	renderIndustryColumn,
	renderSeniorityColumn,
	renderTitleColumn,
	renderJobsCompanyColumn,
	renderCompanyColumn,
	renderAssignToColumn,
	renderMaybeColumn,
	renderSelectColumn,
	renderFollowers,
	renderNameColumn,
	renderJobsNameColumn,
	renderJobCountryColumn,
	renderJobCityColumn,
} from "../utils/allColumns";

export interface JobTableColumnsProps {
	selectAll: boolean;
	toggleSelectAll: () => void;
	selectedItems: number[];
	handleSelectItem: (id: number) => void;
	handleCopyClick: (value: string) => void;
	handleContextMenu: (value: string, row: ListPerson) => void;
	ts: (key: string) => string;
	sortingInbox: SortParams;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
	parts: string[];
}

export const JobTableColumns = ({
	selectAll,
	toggleSelectAll,
	selectedItems,
	handleSelectItem,
	handleCopyClick,
	handleContextMenu,
	ts,
	sortingInbox,
	setShowEdit,
	showEdit,
	parts,
}: JobTableColumnsProps): TableColumn[] => {
	const columns: TableColumn[] = [
		renderSelectColumn(toggleSelectAll, selectAll, selectedItems, handleSelectItem),
		renderMaybeColumn(),
		renderAssignToColumn(ts, handleCopyClick),
		{ ...getContextMenuItem(handleContextMenu, ts) },
	];

	if (parts.includes("managingDirector")) {
		columns.push(renderJobsNameColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit));
	} else if (parts.includes("prospect")) {
		columns.push(renderNameColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit));
		columns.push(renderCompanyColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit));
	}

	if (!parts.includes("prospect")) {
		columns.push(
			renderJobsCompanyColumn(
				ts,
				handleCopyClick,
				sortingInbox,
				setShowEdit,
				showEdit,
				parts.includes("managingDirector")
			)
		);
	}

	if (!parts.includes("managingDirector") && !parts.includes("prospect")) {
		columns.push(renderJobCountryColumn(ts, sortingInbox));
		columns.push(renderJobCityColumn(ts, sortingInbox));
	}

	columns.push(
		...[
			{
				title: ts("jobTitle"),
				width: "fit-content",
				orderTitle: "offer.jobTitle",
				uid: "jobTitle",
				hideable: true,
				moveable: true,
				show: true,
				render: (row: ListPerson) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="row" justifyContent="flex-start">
							<Link href={row.offer?.offerLink ?? "#"} target="_blank">
								<ExternalLink />
							</Link>
							<button
								className="text-ellipsis overflow-hidden max-w-[250px] cursor-pointer"
								onClick={() => handleCopyClick(row.offer?.jobTitle ?? "")}
							>
								{row.offer?.jobTitle}
							</button>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("postedAt"),
				width: "fit-content",
				orderTitle: "offer.postedAt",
				uid: "postedAt",
				hideable: true,
				moveable: true,
				show: true,
				render: (row: ListPerson) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="row" justifyContent="flex-start">
							<button
								className="text-ellipsis overflow-hidden max-w-[250px] cursor-pointer"
								onClick={() =>
									row.offer?.postedAt && handleCopyClick(row.offer?.postedAt.toISOString())
								}
							>
								{row.offer?.postedAt && format(new Date(row.offer?.postedAt), "dd.MM.yyyy")}
							</button>
						</Stack>
					</Stack>
				),
			},
			{
				title: ts("employmentType"),
				width: "fit-content",
				orderTitle: "offer.employmentType",
				uid: "employmentType",
				hideable: true,
				moveable: true,
				show: true,
				render: (row: ListPerson) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<button
								className="text-ellipsis overflow-hidden max-w-[250px] cursor-pointer"
								onClick={() => handleCopyClick(row.offer?.employmentType ?? "")}
							>
								{row.offer?.employmentType}
							</button>
						</Stack>
					</Stack>
				),
			},
			renderOtherListsColumn(ts, sortingInbox),
		]
	);

	if (parts.includes("prospect")) {
		columns.push(
			...[
				renderGenderColumn(ts, handleCopyClick, sortingInbox),
				renderTitleColumn(ts, handleCopyClick, sortingInbox),
				renderSeniorityColumn(ts, handleCopyClick, sortingInbox),
				renderConnectionsColumn(ts, sortingInbox),
				renderIndustryColumn(ts, handleCopyClick, sortingInbox),
				renderEstimatedNumEmployeesColumn(ts, handleCopyClick, sortingInbox),
				renderFoundedYearColumn(ts, handleCopyClick, sortingInbox),
				renderPersonCityColumn(ts, handleCopyClick, sortingInbox),
				renderPersonStateColumn(ts, handleCopyClick, sortingInbox),
				renderPersonCountryColumn(ts, handleCopyClick, sortingInbox),
				renderCompanyCityColumn(ts, handleCopyClick, sortingInbox),
				renderCompanyStateColumn(ts, handleCopyClick, sortingInbox),
				renderCompanyCountryColumn(ts, handleCopyClick, sortingInbox),
				renderLanguageColumn(ts, sortingInbox),
				renderFollowers(ts, sortingInbox),
			]
		);
	}

	return columns;
};
