import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { FileUploader } from "react-drag-drop-files";

import { faCheck } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "@/components";

import { useCreateCsvEnrichmentMutation } from "@/pages/Private/redux/csvEnrichment/csvEnrichment.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import { Button } from "@/components/Button/Button";
import { ButtonColor } from "@/components/Button/types";

import { InputField } from "@/components/InputField/InputField";

import { List } from "../../List/schema/list";
import {
	CreateCsvEnrichment,
	CreateCsvEnrichmentSchema,
	CsvEnrichment,
} from "../schema/csvEnrichment";

interface CreateCsvEnrichmentFormProps {
	csvEnrichment?: CsvEnrichment | null;
	list: List;
	handleSelect?: (csvEnrichment: CsvEnrichment | null) => void;
}

const usefulFields = [
	"name",
	"email",
	"companyName",
	"linkedinUrl",
	"website",
	"title",
	"companyLinkedinUrl",
];

const personalEnrichments = [
	["name", "companyName", "title"],
	["name", "companyName"],
	["linkedinUrl"],
	["email"],
];

const personByTitle = [["title", "companyName"]];

const companyList = [["website", "companyLinkedinUrl"]];

export const CreateCsvEnrichmentForm: FunctionComponent<CreateCsvEnrichmentFormProps> = ({
	csvEnrichment,
	list,
	handleSelect,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`csvEnrichment.${key}`);
	const [createCsvEnrichment, { isLoading, error }] = useCreateCsvEnrichmentMutation();
	const [fileContent, setFileContent] = useState<string>();
	const [foundFields, setFoundFields] = useState<string[]>();

	const [currentFormState, setCurrentFormState] = useState<CreateCsvEnrichment>({
		name: "",
		file: "",
		withEmail: false,
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof CreateCsvEnrichment) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const {
		handleSubmit,
		reset,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<CreateCsvEnrichment>({
		defaultValues: {
			name: "",
			file: "",
			withEmail: false,
		},
		resolver: zodResolver(CreateCsvEnrichmentSchema),
	});

	useEffect(() => {
		if (csvEnrichment) {
			reset({
				id: csvEnrichment.id,
				name: csvEnrichment.name,
			});
		} else {
			reset();
		}
	}, [list.scrapeLevel, csvEnrichment, reset]);

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: CreateCsvEnrichment) => {
		try {
			const { file: fileRaw } = values;

			console.log(fileRaw);

			const formData = new FormData();
			const res: Response = await fetch(fileRaw);
			const blob: Blob = await res.blob();
			const file = new File([blob], "file.csv");

			formData.append("file", file);
			formData.append("name", values.name || file.name);
			formData.append("withEmail", "false");
			formData.append("listId", list.id.toString());
			formData.append("type", "csv");

			await createCsvEnrichment(formData).unwrap();
			handleSelect?.(null);
		} catch (err) {
			console.error(err);
		}
	};

	// eslint-disable-next-line no-console
	// console.log(error, formErrors);

	useEffect(() => {
		const processFoundFields = async (fileContent?: string) => {
			if (!fileContent) {
				setFoundFields(undefined);

				return;
			}

			const res = await fetch(fileContent);
			const resText = await res.text();
			const firstLine = resText.split("\r\n")[0].split("\n")[0].split("\r")[0];
			const fields = firstLine.includes(";") ? firstLine.split(";") : firstLine.split(",");

			setFoundFields(fields);
		};

		processFoundFields(fileContent);
	}, [fileContent]);

	const renderPersonalEnrichment = () => {
		const options = personalEnrichments.filter((pE) => pE.every((i) => foundFields?.includes(i)));

		if (options.length === 0) {
			return renderPersonByTitle();
		}

		return (
			<div>
				<div className="text-text text-left text-ssm mt-4 font-bold mb-2">
					Enough data for enrichments of individuals. The following methods can be applied:
				</div>
				<div>
					<ul>
						{options.map((option, index) => {
							return (
								<li
									key={`renderPersonalEnrichment-${index}`}
									className="text-text text-left text-ssm"
								>
									{option.join(" & ")}
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		);
	};

	const renderPersonByTitle = () => {
		const options = personByTitle.filter((pE) => pE.every((i) => foundFields?.includes(i)));

		if (options.length === 0) {
			return renderCompanyList();
		}

		return (
			<div>
				<div className="text-text text-left text-ssm mt-4 font-bold mb-2">
					{ts("personByTitle")}
				</div>
				<div>
					<ul>
						{options.map((option, index) => {
							return (
								<li key={`renderPersonByTitle-${index}`} className="text-text text-left text-ssm">
									{option.join(" & ")}
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		);
	};

	const renderCompanyList = () => {
		const options = companyList.filter((pE) => pE.every((i) => foundFields?.includes(i)));

		if (options.length === 0) {
			return <></>;
		}

		return (
			<div className="flex flex-col w-full mb-4">
				<div className="text-text text-left text-ssm mt-4 font-bold mb-2">
					{ts("companyEnrichment")}
				</div>
				<div className="w-full">
					<ul>
						{options.map((option, index) => {
							return (
								<li key={`renderPersonByTitle-${index}`} className="text-text text-left text-ssm">
									{option.join(" or ")}
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		);
	};

	return (
		<div className="">
			<div className="text-md mb-5">Sources</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "name");
					}}
					label={ts("name")}
					name={"name"}
					placeholder={ts("namePlaceholder")}
					value={currentFormState?.name || ""}
				/>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<FileUploader
					handleChange={(file: File) => {
						console.log(file);
						if (!getValues("name")) {
							setValue("name", file.name, {
								shouldValidate: true,
								shouldDirty: true,
							});
						}

						setValue("file", URL.createObjectURL(file));
						setFileContent(URL.createObjectURL(file));
					}}
					name="file"
					types={["csv"]}
				/>
			</div>
			<div className="flex flex-col w-full mb-4">
				{foundFields ? (
					<div className="text-text text-left text-ssm font-semibold mt-4 mb-2">
						{ts("foundFields")}
					</div>
				) : (
					""
				)}
				<div className="w-full">
					<ul>
						{foundFields?.map((item, index) => {
							return (
								<li key={`list-index-${index}`} className="text-text text-left text-ssm">
									{item}{" "}
									{usefulFields.includes(item.trim()) ? <Icon color="green" icon={faCheck} /> : ""}{" "}
								</li>
							);
						})}
					</ul>
				</div>
			</div>
			<div>{renderPersonalEnrichment()}</div>

			{getAllErrors(error, formErrors).length
				? renderErrorMessages(getAllErrors(error, formErrors))
				: null}
			<div className="flex gap-4 w-full justify-end">
				{handleSelect && (
					<div className="flex max-w-[100px] w-full">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							title="cancel"
							onClick={() => {
								handleSelect(null);
								reset({
									name: "",
									file: "",
								});
							}}
						/>
					</div>
				)}

				<div className="flex max-w-[150px] w-full">
					<Button
						disabled={foundFields && foundFields?.length === 0}
						isLoading={isLoading}
						title={csvEnrichment?.id ? "Save" : "Upload"}
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</div>
		</div>
	);
};
