import { Dispatch, SetStateAction, FunctionComponent, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Modal } from "@/components";

import { ListSourceType } from "@/enum/list.enum";
import { useGetFiltersQuery, useGetListQuery } from "@/pages/Private/redux/list/list.api";
import { useSearch } from "@/utils";

import { JobScrapeType } from "@/enum/job.enum";

import { GeneralFilter, IFilter, IFilterType, LiEventFilter } from "../constans/general-filter";
import { MapsInboxAdditionalFilter } from "../constans/maps-filter";
import {
	AdditionalColumnsInfoAtFiltersConfig,
	AdditionalColumnsNonLinkedinJobFiltersConfig,
	JobsFilter,
} from "../constans/jobs-filter";
import { ListPerson } from "../schema/list";
import { LiPostFilter } from "../constans/li-post-filter";
import { LiProfileFilter } from "../constans/li-profile-filter";
import { LiCompanyActivityFilter } from "../constans/li-company-activity-filter";
import { useFilters } from "../utils/useFilters";

export interface IFilterWithValues {
	[key: string]: {
		value?:
			| string
			| number
			| {
					name: string;
					id: number;
			  }[]
			| {
					title: string;
					id: string;
			  }[]
			| {
					name: string;
					id: number;
			  };
		filterParam: string;
		type: IFilterType;
		customColumnId?: number;
	};
}

interface FilterModalProps {
	inboxData: ListPerson[];
	listId: number;
	showFilterModal: boolean;
	setShowFilterModal: Dispatch<SetStateAction<boolean>>;
	filter?: IFilterWithValues;
	setFilter: Dispatch<SetStateAction<IFilterWithValues | undefined>>;
	listType?: ListSourceType;
}

export const FilterModal: FunctionComponent<FilterModalProps> = ({
	inboxData,
	listId,
	showFilterModal,
	setShowFilterModal,
	filter,
	setFilter,
}) => {
	const { t } = useTranslation();

	const { renderFilters, filterValue, setFilterValue, setFilterType } = useFilters({ filter });

	const { query, searchValue, setSearchValue } = useSearch();

	const { data: list } = useGetListQuery(listId);

	const { data } = useGetFiltersQuery({
		id: listId,
		searchTerm: query,
	});

	const onSubmit = () => {
		filterValue && setFilter(filterValue);
	};

	const resetFilter = () => {
		setFilterType({});
		setFilterValue({});
	};

	const FilterDataType = useMemo(() => {
		if (!list) {
			return [];
		}

		let FilterDataType: IFilter[] = [];

		if (list.sourceType === ListSourceType.LI_EVENTS) {
			FilterDataType = LiEventFilter;
		}

		if (list.sourceType === ListSourceType.MAPS) {
			FilterDataType = MapsInboxAdditionalFilter;
		}

		if (list.sourceType === ListSourceType.JOBS) {
			FilterDataType = JobsFilter;

			if (
				[JobScrapeType.GET_MANAGING_DIRECTOR, JobScrapeType.GET_PERSON_FROM_OFFER].includes(
					list.scrapeLevel as JobScrapeType
				)
			) {
				FilterDataType = [...FilterDataType, ...AdditionalColumnsNonLinkedinJobFiltersConfig];
			} else if ([JobScrapeType.GET_INFO_AT].includes(list.scrapeLevel as JobScrapeType)) {
				FilterDataType = [...FilterDataType, ...AdditionalColumnsInfoAtFiltersConfig];
			}
		}

		if (list.sourceType === ListSourceType.LI_POST) {
			FilterDataType = LiPostFilter;
		}

		if (list.sourceType === ListSourceType.LI_PROFILE) {
			FilterDataType = LiProfileFilter;
		}

		if (list.sourceType === ListSourceType.LI_COMPANY_ACTIVITY) {
			FilterDataType = LiCompanyActivityFilter;
		}

		const foundPerson = inboxData.find((person) => person.prospect?.id);

		if (foundPerson) {
			FilterDataType = [...GeneralFilter, ...FilterDataType];
		}

		if (FilterDataType.length === 0) {
			FilterDataType = GeneralFilter;
		}

		return FilterDataType;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [list]);

	return (
		<Modal
			handleClear={() => resetFilter()}
			handleClose={() => setShowFilterModal(false)}
			handleSave={() => {
				onSubmit();
				setShowFilterModal(false);
			}}
			isOpened={showFilterModal}
			size="lg"
			submitButtonText={t("basics.confirm")}
			title={t("basics.filter")}
		>
			<div className="flex flex-col">{renderFilters(FilterDataType)}</div>
		</Modal>
	);
};
