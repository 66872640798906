import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/material";

import {
	useCreateLiProfileMutation,
	useUpdateLiProfileMutation,
} from "@/pages/Private/redux/liProfile/liProfile.api";

import { LiProfileEntryType } from "@/enum/liprofile.enum";

import { Radio } from "@/components/Radio/Radio";

import { InputField } from "@/components/InputField/InputField";

import { Button } from "@/components/Button/Button";

import { ButtonColor } from "@/components/Button/types";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { CreateLiProfile, CreateLiProfileSchema, LiProfile } from "../schema/liProfile";
import { List } from "../../List/schema/list";

interface CreateLiProfileFormProps {
	liProfile?: LiProfile | null;
	list: List;
	handleSelect?: (job: LiProfile | null) => void;
}

export const CreateLiProfileForm: FunctionComponent<CreateLiProfileFormProps> = ({
	liProfile,
	list,
	handleSelect,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liProfile.${key}`);
	const [createLiProfile, { isLoading, error }] = useCreateLiProfileMutation();
	const [updateLiProfile, { isLoading: updateIsLoading, error: updateError }] =
		useUpdateLiProfileMutation();
	const [filter, setFilter] = useState<LiProfileEntryType>(
		LiProfileEntryType.LINKEDIN_PROFILE_POSTS
	);

	const initialState = {
		name: "",
		url: "",
		type: LiProfileEntryType.LINKEDIN_PROFILE_POSTS,
	};

	const [currentFormState, setCurrentFormState] = useState<CreateLiProfile>(initialState);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof CreateLiProfile) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const {
		reset,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<CreateLiProfile>({
		defaultValues: initialState,
		resolver: zodResolver(CreateLiProfileSchema),
	});

	useEffect(() => {
		if (liProfile) {
			reset({
				id: liProfile.id,
				name: liProfile.name,
				url: liProfile.url,
				type: liProfile.type,
			});

			setCurrentFormState({
				id: liProfile.id,
				name: liProfile.name,
				url: liProfile.url,
				type: liProfile.type,
			});

			setFilter(liProfile.type);
		} else {
			reset();
		}
	}, [list.scrapeLevel, liProfile, reset]);

	const onSubmit = async (values: CreateLiProfile) => {
		try {
			if (liProfile?.id) {
				await updateLiProfile({
					id: liProfile.id,
					name: values.name,
					url: values.url,
					type: filter,
					listId: list.id,
				}).unwrap();
			} else {
				await createLiProfile({
					name: values.name,
					url: values.url,
					type: filter,
					listId: list.id,
				}).unwrap();
			}

			handleSelect?.(null);
			reset({
				name: "",
				url: "",
				type: LiProfileEntryType.LINKEDIN_PROFILE_POSTS,
			});
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<div>
			<div className="text-md mb-5">Sources</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.name?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "name");
					}}
					label={ts("name")}
					name={"name"}
					placeholder={ts("namePlaceholder")}
					value={currentFormState.name}
				/>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.url?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "url");
					}}
					label={ts("url")}
					name={"url"}
					placeholder={ts("urlPlaceholder")}
					value={currentFormState.url}
				/>
			</div>
			<div className="flex flex-col gap-4 w-full mb-4">
				<div className="text-smd">{ts(`observeAction`)}</div>
				<Radio
					isChecked={filter === LiProfileEntryType.LINKEDIN_PROFILE_POSTS}
					name="sourceType"
					onChange={() => setFilter(LiProfileEntryType.LINKEDIN_PROFILE_POSTS)}
				>
					{t(`liProfile.linkedinProfilePosts`)}
				</Radio>
				<Radio
					isChecked={filter === LiProfileEntryType.LINKEDIN_PROFILE_COMMENTS}
					name="sourceType"
					onChange={() => setFilter(LiProfileEntryType.LINKEDIN_PROFILE_COMMENTS)}
				>
					{t(`liProfile.linkedinProfileComments`)}
				</Radio>
			</div>
			<div className="flex flex-col gap-4 w-full mb-4">
				<Typography variant="subtitle1">{ts(`observeReaction`)}</Typography>
				<Typography className="text-gray-700 lg:max-w-[650px] text-xs" variant="subtitle2">
					{ts(`reactedWithExample`)}
				</Typography>
				<Radio
					isChecked={filter === LiProfileEntryType.LINKEDIN_PROFILE_LIKES_TO_COMMENT}
					name="sourceType"
					onChange={() => setFilter(LiProfileEntryType.LINKEDIN_PROFILE_LIKES_TO_COMMENT)}
				>
					{t(`liProfile.linkedinProfileLikesToComment`)}
				</Radio>
				<Radio
					isChecked={filter === LiProfileEntryType.LINKEDIN_PROFILE_LIKES_TO_POST}
					name="sourceType"
					onChange={() => setFilter(LiProfileEntryType.LINKEDIN_PROFILE_LIKES_TO_POST)}
				>
					{t(`liProfile.linkedinProfileLikesToPost`)}
				</Radio>
				<Radio
					isChecked={filter === LiProfileEntryType.LINKEDIN_PROFILE_COMMENTS_TO_POST}
					name="sourceType"
					onChange={() => setFilter(LiProfileEntryType.LINKEDIN_PROFILE_COMMENTS_TO_POST)}
				>
					{t(`liProfile.linkedinProfileCommentsToPost`)}
				</Radio>
			</div>
			<div className="flex flex-col gap-4 w-full mb-4">
				{getAllErrors(error || updateError, formErrors).length
					? renderErrorMessages(getAllErrors(error || updateError, formErrors))
					: null}
			</div>

			<div className="flex gap-4 w-full justify-end">
				{handleSelect && (
					<div className="flex max-w-[100px] w-full">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							title="cancel"
							onClick={() => {
								reset(initialState);
								setCurrentFormState(initialState);
								setFilter(LiProfileEntryType.LINKEDIN_PROFILE_POSTS);
								handleSelect?.(null);
							}}
						/>
					</div>
				)}

				<div className="flex max-w-[150px] w-full">
					<Button
						isLoading={isLoading || updateIsLoading}
						title={liProfile?.id ? "Save" : "Add criteria"}
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</div>
		</div>
	);
};
