import { z } from "zod";

import { ScoreTypes } from "@/enum/score.enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { ScoreTypeSchema } from "./score-type.schema";

const { REQUIRED } = ERROR_TYPE;

export const ScoreSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	description: z.string().trim().optional(),
	scoreTypes: z.array(ScoreTypeSchema),
	lists: z
		.array(
			z.object({
				id: z.number(),
			})
		)
		.optional(),
});

export const CreateScoreSchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	description: z.string().trim().optional(),
});

export const CreateScoreFromCreateListItemSchema = z.object({
	id: z.number(),
	name: z.string().optional(),
	filterParam: z.string().optional(),
	filterType: z.array(z.string()).optional(),
	selectedFilterType: z.string().nullable().optional(),
	value: z.array(z.string()).or(z.string()).or(z.number()).nullable().optional(),
	score: z.number().nullable().optional(),
});

export const ScoreFromCreateListSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	scores: z.array(CreateScoreFromCreateListItemSchema),
	listId: z.number(),
});

export const CreateScoreFromCreateListSchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	scores: z.array(CreateScoreFromCreateListItemSchema),
});

export const ScoreResponseSchema = z.object({
	data: z.array(ScoreSchema),
	meta: MetaDataSchema,
});

export const UpdateScoreSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	description: z.string().trim().optional(),
	filters: z
		.record(
			z.nativeEnum(ScoreTypes),
			z.array(
				z.object({
					value: z.array(z.string()).nullable(),
					valueFilter: z.array(z.object({ id: z.number(), name: z.string() })).nullable(),
					valueNumeric: z.number().nullable(),
					valueNumericTill: z.number().nullable(),
					scoreValue: z.number().nullable(),
				})
			)
		)
		.nullable(),
});

export type Score = z.infer<typeof ScoreSchema>;

export type CreateScore = z.infer<typeof CreateScoreSchema>;
export type UpdateScore = z.infer<typeof UpdateScoreSchema>;

export type ScoreResponse = z.infer<typeof ScoreResponseSchema>;

export type CreateScoreFromCreateList = z.infer<typeof CreateScoreFromCreateListSchema>;
export type ScoreFromCreateList = z.infer<typeof ScoreFromCreateListSchema>;
