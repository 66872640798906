import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { TextField } from "@mui/material";

import { Radio } from "@/components/Radio/Radio";

import { InputField } from "@/components/InputField/InputField";

import { Button } from "@/components/Button/Button";

import { ButtonColor } from "@/components/Button/types";

import { ERROR_TYPE, getAllErrors, renderErrorMessages, useSearch } from "@/utils";

import {
	useCreateLiPeopleMutation,
	useLazyGetSearchPersonSuggestionQuery,
	useUpdateLiPeopleMutation,
} from "@/pages/Private/redux/liPeople/liPeople.api";
import {
	LinkedinPersonAutoComplete,
	LinkedinPersonAutoCompleteItem,
} from "@/components/LinkedinPersonAutoComplete/LinkedinPersonAutoComplete";
import { LiEventType } from "@/enum/lievent.enum";

import { List } from "../../List/schema/list";

import { LinkedinParams, LinkedinTagAutocomplete } from "./LinkedinTagAutocomplete";
import { CreateLiPeople, CreateLiPeopleSchema, LiPeople, Suggestion } from "../schema/liPeople";

interface CreateLiPeopleFormProps {
	liPeople?: LiPeople | null;
	list: List;
	handleSelect?: (job: LiPeople | null) => void;
}

export const CreateLiPeopleForm: FunctionComponent<CreateLiPeopleFormProps> = ({
	liPeople,
	list,
	handleSelect,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liPeople.${key}`);
	const [createLiPeople, { isLoading, error }] = useCreateLiPeopleMutation();
	const [updateLiPeople, { isLoading: updateIsLoading, error: updateError }] =
		useUpdateLiPeopleMutation();

	const [tempUrls, setTempUrls] = useState<string>();
	const [filter, setFilter] = useState<LiEventType>(LiEventType.SEARCH);
	const [schools, setSchools] = useState<LinkedinParams[]>();
	const [locations, setLocations] = useState<LinkedinParams[]>();
	const [languages, setLanguages] = useState<LinkedinParams[]>();
	const [serviceCategories, setServiceCategories] = useState<LinkedinParams[]>();
	const [industries, setIndustries] = useState<LinkedinParams[]>();
	const [followers, setFollowers] = useState<LinkedinPersonAutoCompleteItem[]>();

	const [follower, setFollower] = useState<LinkedinPersonAutoCompleteItem>();

	const { query, setSearchValue } = useSearch();

	const [fetchTags] = useLazyGetSearchPersonSuggestionQuery();

	useEffect(() => {
		if (query) {
			fetchTags({ keyword: query }).then((results) => {
				const optionValues = results?.data?.people?.map((res) => {
					return {
						title: res.fullName,
						id: res.navigationUrl,
						icon: res.profilePicture,
					};
				});

				if (optionValues) {
					setFollowers([...optionValues]);
				}
			});
		}
	}, [fetchTags, query]);

	const initialState = {
		name: "",
		urls: [],
	};

	const [currentFormState, setCurrentFormState] = useState<Partial<CreateLiPeople>>(initialState);

	useEffect(() => {
		if (liPeople) {
			setLocations(
				liPeople.locationList?.map((l) => {
					return { name: l.title, id: l.urn };
				}) as unknown as LinkedinParams[]
			);
			setSchools(
				liPeople.schoolList?.map((l) => {
					return { name: l.title, id: l.urn };
				}) as unknown as LinkedinParams[]
			);
			setIndustries(
				liPeople.industryList?.map((l) => {
					return { name: l.title, id: l.urn };
				}) as unknown as LinkedinParams[]
			);
			setServiceCategories(
				liPeople.serviceCategoryList?.map((l) => {
					return { name: l.title, id: l.urn };
				}) as unknown as LinkedinParams[]
			);
			setLanguages(
				liPeople.languageList?.map((l) => {
					return { name: l.title, id: l.urn };
				}) as unknown as LinkedinParams[]
			);

			if (liPeople.follows) {
				const prep: LinkedinPersonAutoCompleteItem = {
					title: liPeople.follows.fullName,
					id: liPeople.follows.navigationUrl,
					icon: liPeople.follows.profilePicture,
				};

				setFollower(prep);
			}
		}
	}, [liPeople]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof CreateLiPeople) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const {
		reset,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<CreateLiPeople>({
		defaultValues: initialState,
		resolver: zodResolver(CreateLiPeopleSchema),
	});

	const handleUrlsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setTempUrls(event.target.value);
	};

	const handleUrlsOnBlur = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setTempUrls(event.target.value);
		setValue(
			"urls",
			event.target.value?.includes(",")
				? event.target.value?.split(",")
				: event.target.value?.includes("\n")
				? event.target.value?.split("\n")
				: [event.target.value]
		);
	};

	useEffect(() => {
		if (liPeople) {
			reset(liPeople);

			setCurrentFormState({ ...liPeople });
		} else {
			reset();
		}
	}, [liPeople, reset]);

	const onSubmit = async (values: CreateLiPeople) => {
		try {
			const newValue = {
				...values,
				schoolList: schools?.map((val) => {
					return { title: val.name, urn: val.id };
				}) as Suggestion[],
				locationList: locations?.map((val) => {
					return { title: val.name, urn: val.id };
				}) as Suggestion[],
				industryList: industries?.map((val) => {
					return { title: val.name, urn: val.id };
				}) as Suggestion[],
				serviceCategoryList: serviceCategories?.map((val) => {
					return { title: val.name, urn: val.id };
				}) as Suggestion[],
				languageList: languages?.map((val) => {
					return { title: val.name, urn: val.id };
				}) as Suggestion[],
				follows: follower
					? {
							fullName: follower.title,
							navigationUrl: follower.id,
							profilePicture: follower.icon,
					  }
					: undefined,
			};

			if (liPeople?.id) {
				await updateLiPeople({
					id: liPeople.id,
					...newValue,
					listId: list.id,
				}).unwrap();
			} else {
				await createLiPeople({ ...newValue, listId: list.id }).unwrap();
			}

			handleSelect?.(null);
			reset(initialState);
		} catch (err) {
			console.error(err);
		}
	};

	console.log(errors);

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<div>
			<div className="text-md mb-5">Sources</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.name?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "name");
					}}
					label={ts("name")}
					name={"name"}
					placeholder={ts("namePlaceholder")}
					value={currentFormState?.name || ""}
				/>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<div className="mb-4">
					<Radio
						isChecked={filter === LiEventType.SEARCH}
						onChange={() => setFilter(LiEventType.SEARCH)}
					>
						{ts("defaultSearch")}
					</Radio>
				</div>
				<div className="mb-4">
					<Radio
						isChecked={filter === LiEventType.SPECIFIC}
						onChange={() => setFilter(LiEventType.SPECIFIC)}
					>
						{ts("specific")}
					</Radio>
				</div>
			</div>

			{filter === LiEventType.SPECIFIC && (
				<div className="flex flex-row gap-4 w-full mb-4">
					<TextField
						multiline
						label={ts("urls")}
						placeholder={ts("urls")}
						required={false}
						rows={4}
						sx={{ width: "100%" }}
						value={tempUrls}
						onBlur={handleUrlsOnBlur}
						onChange={handleUrlsChange}
					/>
				</div>
			)}

			{filter === LiEventType.SEARCH && (
				<>
					<div className="text-ssm mb-5">{ts("personalDetails")}</div>
					<div className="flex flex-row gap-4 w-full mb-4">
						<InputField
							error={!!errors.keyword?.message}
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								handleSaveFormState(event.target.value, "keyword");
							}}
							label={ts("keyword")}
							name={"keyword"}
							placeholder={ts("keywordPlaceholder")}
							value={currentFormState?.keyword || ""}
						/>
					</div>
					<div className="flex flex-row gap-4 w-full mb-4">
						<InputField
							error={!!errors.titleFreeText?.message}
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								handleSaveFormState(event.target.value, "titleFreeText");
							}}
							label={ts("titleFreeText")}
							name={"titleFreeText"}
							placeholder={ts("titleFreeTextPlaceholder")}
							value={currentFormState?.titleFreeText || ""}
						/>
					</div>
					<div className="flex flex-row gap-4 w-full mb-4">
						<InputField
							error={!!errors.firstName?.message}
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								handleSaveFormState(event.target.value, "firstName");
							}}
							label={ts("firstName")}
							name={"firstName"}
							placeholder={ts("firstNamePlaceholder")}
							value={currentFormState?.firstName || ""}
						/>

						<InputField
							error={!!errors.lastName?.message}
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								handleSaveFormState(event.target.value, "lastName");
							}}
							label={ts("lastName")}
							name={"lastName"}
							placeholder={ts("lastNamePlaceholder")}
							value={currentFormState?.lastName || ""}
						/>
					</div>
					<div className="flex flex-row gap-4 w-full mb-4">
						<InputField
							error={!!errors.companyFreeText?.message}
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								handleSaveFormState(event.target.value, "companyFreeText");
							}}
							label={ts("companyFreeText")}
							name={"companyFreeText"}
							placeholder={ts("companyFreeTextPlaceholder")}
							value={currentFormState?.companyFreeText || ""}
						/>
					</div>
					<div className="flex flex-row gap-4 w-full mb-4">
						<InputField
							error={!!errors.schoolFreeText?.message}
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								handleSaveFormState(event.target.value, "schoolFreeText");
							}}
							label={ts("schoolFreeText")}
							name={"schoolFreeText"}
							placeholder={ts("schoolFreeTextPlaceholder")}
							value={currentFormState?.schoolFreeText || ""}
						/>
					</div>

					<div className="text-ssm mb-5">{ts("specificTags")}</div>
					<div className="flex flex-row gap-4 w-full mb-4">
						<LinkedinTagAutocomplete
							handleValueChange={(value: LinkedinParams[]) => {
								setSchools(value);
							}}
							type={"school"}
							value={schools}
						/>
					</div>
					<div className="flex flex-row gap-4 w-full mb-4">
						<LinkedinTagAutocomplete
							handleValueChange={(value: LinkedinParams[]) => {
								setLocations(value);
							}}
							type={"location"}
							value={locations}
						/>
					</div>
					<div className="flex flex-row gap-4 w-full mb-4">
						<LinkedinTagAutocomplete
							handleValueChange={(value: LinkedinParams[]) => {
								setIndustries(value);
							}}
							type={"industry"}
							value={industries}
						/>
					</div>
					<div className="flex flex-row gap-4 w-full mb-4">
						<LinkedinTagAutocomplete
							handleValueChange={(value: LinkedinParams[]) => {
								setServiceCategories(value);
							}}
							type={"serviceCategory"}
							value={serviceCategories}
						/>
					</div>
					<div className="flex flex-row gap-4 w-full mb-4">
						<LinkedinTagAutocomplete
							handleValueChange={(value: LinkedinParams[]) => {
								setLanguages(value);
							}}
							type={"language"}
							value={languages}
						/>
					</div>
					<div className="text-ssm mb-5">{ts("followerOf")}</div>
					<div className="flex flex-row gap-4 w-full mb-4">
						<LinkedinPersonAutoComplete
							data={followers || []}
							handleSearch={(val) => setSearchValue(val)}
							handleSelect={function (value?: LinkedinPersonAutoCompleteItem | undefined): void {
								setFollower(value);
							}}
							selectedItem={follower}
						/>
					</div>
				</>
			)}

			<div className="flex flex-col gap-4 w-full mb-4">
				{getAllErrors(error || updateError, formErrors).length
					? renderErrorMessages(getAllErrors(error || updateError, formErrors))
					: null}
			</div>

			<div className="flex gap-4 w-full justify-end">
				{handleSelect && (
					<div className="flex max-w-[100px] w-full">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							title="cancel"
							onClick={() => {
								reset(initialState);
								setCurrentFormState(initialState);
								handleSelect?.(null);
							}}
						/>
					</div>
				)}

				<div className="flex max-w-[150px] w-full">
					<Button
						isLoading={isLoading || updateIsLoading}
						title={liPeople?.id ? "Save" : "Add criteria"}
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</div>
		</div>
	);
};
