import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import { ListSourceRequestPaginationParams } from "@/types/list-source-request-params";

import { ListFilterRequestParams } from "@/types/list-filter-request-params";

import {
	Organization,
	People,
	PeopleResponse,
	PeopleResponseSchema,
} from "../../pages/Apollo/schema/get-people";
import { AiColumn } from "../../pages/List/schema/ai-column";
import {
	ColumnConfig,
	ColumnConfigResponse,
	ColumnConfigResponseSchema,
	ColumnConfigSchema,
} from "../../pages/List/schema/columnConfig";
import { ColumnValue } from "../../pages/List/schema/columnValue";
import {
	BulkAssign,
	BulkChangeStatus,
	CustomColumn,
	DownloadFile,
	Filter,
	FilterByValue,
	FilterByValueSchema,
	FilterSchema,
	List,
	ListAiEnrichmentResponse,
	ListAiEnrichmentResponseSchema,
	ListLogResponse,
	ListLogResponseSchema,
	ListPersonResponse,
	ListPersonResponseSchema,
	ListReport,
	ListReportSchema,
	ListRequestPaginationParams,
	ListResponse,
	ListResponseSchema,
	ListSchema,
	ListSourceResponse,
	ListSourceSchema,
	ListViewReqiestPaginationParams,
	UpdateList,
	UpdateListDetails,
} from "../../pages/List/schema/list";
import { MapBusinessUpdate } from "../../pages/List/schema/mapBusiness-update";
import { ProspectUpdate } from "../../pages/List/schema/prospect-update";
import { MapBusiness } from "../../pages/Map/schema/mapBusiness";

export const listApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getLists: builder.query<ListResponse, RequestPaginationParams>({
			query: ({ sorting, ...params }) => ({
				url: "list",
				params: {
					...(params && { ...params }),
					...(sorting && { ...sorting }),
				},
				responseSchema: ListResponseSchema,
			}),
			providesTags: ["List"],
		}),
		getLeanLists: builder.query<ListResponse, RequestPaginationParams>({
			query: ({ sorting, ...params }) => ({
				url: "list/lean",
				params: {
					...(params && { ...params }),
					...(sorting && { ...sorting }),
				},
				responseSchema: ListResponseSchema,
			}),
			providesTags: ["List"],
		}),
		getListForScrape: builder.query<List, RequestPaginationParams & { id: number }>({
			query: (params) => ({
				url: `list/${params.id}`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: ListSchema,
			}),
			providesTags: ["List"],
		}),
		getListAttendees: builder.query<PeopleResponse, RequestPaginationParams & { id: number }>({
			query: (params) => ({
				url: `list/${params.id}/attendees`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: PeopleResponseSchema,
			}),
			providesTags: ["People"],
		}),
		getList: builder.query<List, number>({
			query: (id: number) => ({
				url: `list/${id}`,
				params: {
					id,
				},
				responseSchema: ListSchema,
			}),
			providesTags: (result, error, arg) => [{ type: "List", id: result?.id || arg }],
		}),
		getLeanList: builder.query<List, number>({
			query: (id: number) => ({
				url: `list/${id}/lean`,
				params: {
					id,
				},
				responseSchema: ListSchema,
			}),
			providesTags: (result, error, arg) => [{ type: "List", id: result?.id || arg }],
		}),
		getColumnTemplates: builder.query<ColumnConfigResponse, RequestPaginationParams>({
			query: (params) => ({
				url: `list/column/templates`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: ColumnConfigResponseSchema,
			}),
			providesTags: ["ListTemplates"],
		}),
		getLog: builder.query<
			ListLogResponse,
			RequestPaginationParams & { id: number; iteration?: number }
		>({
			query: ({ id, iteration, ...params }) => ({
				url: `list/log/${id}${iteration ? `/${iteration}` : ""}`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: ListLogResponseSchema,
			}),
			providesTags: ["ListLog"],
		}),
		downloadList: builder.query<string, { id: number; excluded?: boolean }>({
			query: ({ id, excluded }) => ({
				url: `list/download/${id}${excluded ? "?excluded=true" : ""}`,
				params: {
					id,
				},
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				responseHandler: (response: any) => {
					if (!response.ok) {
						return { error: response.statusText, status: response.status };
					}

					return response.blob();
				},
			}),
		}),
		downloadSelectedList: builder.query<Blob, { files: DownloadFile[] }>({
			query: (body) => ({
				url: `list/downloadSelected`,
				method: "POST",
				body,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				responseHandler: async (response: Response) => {
					if (!response.ok) {
						return { error: response.statusText, status: response.status };
					}

					return response.blob();
				},
			}),
		}),
		getListSources: builder.query<ListSourceResponse, ListSourceRequestPaginationParams>({
			query: ({ id, ...params }) => ({
				url: `list/sources/${id}`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: ListSourceSchema,
			}),
			providesTags: ["List"],
		}),
		getAddedListSources: builder.query<ListSourceResponse, ListSourceRequestPaginationParams>({
			query: ({ id, ...params }) => ({
				url: `list/addedSources/${id}`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: ListSourceSchema,
			}),
			providesTags: ["List"],
		}),
		getFilters: builder.query<Filter, ListFilterRequestParams>({
			query: ({ id, ...params }) => ({
				url: `list/filter/${id}`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: FilterSchema,
			}),
			providesTags: ["List"],
		}),
		getFilterByProperty: builder.query<
			FilterByValue,
			{ propertyName: string; searchTerm?: string }
		>({
			query: ({ propertyName, searchTerm }) => ({
				url: `list/filterByProperty/${propertyName}`,
				params: {
					searchTerm,
				},
				responseSchema: FilterByValueSchema,
			}),
			providesTags: ["List"],
		}),
		getUnassigned: builder.query<ListPersonResponse, ListRequestPaginationParams>({
			query: ({ sorting, filter, ...params }) => ({
				url: `list/unassigned`,
				params: {
					...(params && { ...params }),
					...(sorting && { ...sorting }),
					...(filter && { filter: JSON.stringify(filter) }),
				},
				responseSchema: ListPersonResponseSchema,
			}),
			providesTags: ["ListPerson"],
		}),
		getCampaign: builder.query<ListPersonResponse, ListViewReqiestPaginationParams>({
			query: ({ sorting, filter, type, ...params }) => ({
				url: `list/campaign/${type}`,
				params: {
					...(params && { ...params }),
					...(sorting && { ...sorting }),
					...(filter && { filter: JSON.stringify(filter) }),
				},
				responseSchema: ListPersonResponseSchema,
			}),
			providesTags: ["Campaign"],
		}),
		getListAccountFilter: builder.query<ListSourceResponse, RequestPaginationParams>({
			query: (params) => ({
				url: `list/accountFilter`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: ListSourceSchema,
			}),
			providesTags: ["List"],
		}),
		getListLeadFilter: builder.query<ListSourceResponse, RequestPaginationParams>({
			query: (params) => ({
				url: `list/leadFilter`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: ListSourceSchema,
			}),
			providesTags: ["List"],
		}),
		getListAiEnrichment: builder.query<ListAiEnrichmentResponse, RequestPaginationParams>({
			query: (params) => ({
				url: `list/aiEnrichment`,
				params: {
					...(params && { ...params }),
				},
				responseSchema: ListAiEnrichmentResponseSchema,
			}),
			providesTags: ["List"],
		}),
		getColumnById: builder.query<ColumnConfig, number>({
			query: (id) => ({
				url: `list/column/${id}`,
				responseSchema: ColumnConfigSchema,
			}),
			providesTags: [{ type: "Column", id: "DETAIL" }],
		}),
		triggerList: builder.mutation<void, number>({
			query: (id) => ({
				url: `list/trigger/${id}`,
				method: "POST",
			}),
			invalidatesTags: ["List"],
		}),
		createList: builder.mutation<Partial<List>, Partial<List>>({
			query: (body) => ({
				url: `list`,
				method: "POST",
				body,
				responseSchema: ListSchema,
			}),
			invalidatesTags: ["List"],
		}),
		addScoresToList: builder.mutation<Partial<List>, { listId: number; scoreIds: number[] }>({
			query: ({ listId, ...body }) => ({
				url: `list/addScoresToList/${listId}`,
				method: "PATCH",
				body,
				responseSchema: ListSchema,
			}),
			invalidatesTags: ["Score"],
		}),
		updateList: builder.mutation<Partial<List>, Partial<UpdateList>>({
			query: ({ id, ...body }) => ({
				url: `list/${id}`,
				method: "PATCH",
				body,
				responseSchema: ListSchema,
			}),
			invalidatesTags: (result, error, arg) => [{ type: "List", id: arg.id }],
		}),
		removeSourceFromList: builder.mutation<Partial<List>, { id: number; sourceId: number }>({
			query: ({ id, ...body }) => ({
				url: `list/removeSourceFromList/${id}`,
				method: "PATCH",
				body,
				responseSchema: ListSchema,
			}),
			invalidatesTags: (result, error, arg) => [{ type: "List", id: arg.id }],
		}),
		updateListDetails: builder.mutation<Partial<List>, UpdateListDetails>({
			query: ({ id, ...body }) => ({
				url: `list/${id}`,
				method: "PATCH",
				body,
				responseSchema: ListSchema,
			}),
			invalidatesTags: (result, error, arg) => [{ type: "List", id: arg.id }],
		}),
		updateListColumns: builder.mutation<Partial<List>, Partial<UpdateList>>({
			query: ({ id, ...body }) => ({
				url: `list/${id}`,
				method: "PATCH",
				body,
				responseSchema: ListSchema,
			}),
			invalidatesTags: (result, error, arg) => [
				"ListPerson",
				"Column",
				{ type: "List", id: arg.id },
			],
		}),
		deleteListColumn: builder.mutation<number, Partial<UpdateList>>({
			query: ({ id }) => ({
				url: `list/column/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, arg) => {
				console.log(result, error, arg);

				return ["ListPerson", "Column", { type: "List", id: result }];
			},
		}),
		updateCostumColumn: builder.mutation<{ id: number; columnValue: ColumnValue }, CustomColumn>({
			query: ({ id, ...body }) => ({
				url: `list/person/${id}`,
				method: "PATCH",
				body,
			}),
		}),
		updateProspect: builder.mutation<ProspectUpdate, { id: number & Partial<People> }>({
			query: ({ id, ...body }) => ({
				url: `list/prospect/${id}`,
				method: "PATCH",
				body,
			}),
		}),
		updateMapBusiness: builder.mutation<MapBusinessUpdate, { id: number & Partial<MapBusiness> }>({
			query: ({ id, ...body }) => ({
				url: `list/mapBusiness/${id}`,
				method: "PATCH",
				body,
			}),
		}),
		updateOrganization: builder.mutation<ProspectUpdate, { id: number & Partial<Organization> }>({
			query: ({ id, ...body }) => ({
				url: `list/organization/${id}`,
				method: "PATCH",
				body,
			}),
		}),
		runAiEnrichment: builder.mutation<{ data: string }, AiColumn>({
			query: ({ id, ...body }) => ({
				url: `list/run/${id}`,
				method: "POST",
				body,
			}),
		}),
		bulkAssign: builder.mutation<Partial<List>, BulkAssign>({
			query: (body) => ({
				url: `list/bulkAssign`,
				method: "PATCH",
				body,
				responseSchema: ListSchema,
			}),
			invalidatesTags: (result, error, arg) => [{ type: "List", id: arg.listId }, "ListPerson"],
		}),
		bulkChangeStatus: builder.mutation<Partial<List>, BulkChangeStatus>({
			query: (body) => ({
				url: `list/bulkChangeStatus`,
				method: "PATCH",
				body,
				responseSchema: ListSchema,
			}),
			invalidatesTags: (result, error, arg) => [{ type: "List", id: arg.listId }, "ListPerson"],
		}),
		createSlackMessage: builder.mutation<Partial<ListReport>, ListReport>({
			query: (body) => ({
				url: `list/createSlackMessage`,
				method: "POST",
				body,
				responseSchema: ListReportSchema,
			}),
		}),
		deleteListPrompt: builder.mutation<Partial<List>, { id: number; promptId: number }>({
			query: ({ id, promptId }) => ({
				url: `list/${id}/${promptId}`,
				method: "DELETE",
				responseSchema: ListSchema,
			}),
			invalidatesTags: ["List"],
		}),
		resetList: builder.mutation<Partial<List>, number>({
			query: (id) => ({
				url: `list/reset/${id}`,
				method: "PATCH",
				responseSchema: ListSchema,
			}),
			invalidatesTags: ["List"],
		}),
		deleteList: builder.mutation<Partial<List>, number>({
			query: (id) => ({
				url: `list/${id}`,
				method: "DELETE",
				responseSchema: ListSchema,
			}),
			invalidatesTags: ["List"],
		}),
	}),
});

export const {
	useGetListsQuery,
	useGetLeanListsQuery,
	useLazyGetListsQuery,
	useGetLeanListQuery,
	useGetLogQuery,
	useLazyGetLogQuery,
	useGetListQuery,
	useLazyGetListQuery,
	useGetListForScrapeQuery,
	useLazyGetListForScrapeQuery,
	useGetListAttendeesQuery,
	useGetListSourcesQuery,
	useGetListAccountFilterQuery,
	useGetListLeadFilterQuery,
	useLazyGetListLeadFilterQuery,
	useGetListAiEnrichmentQuery,
	useGetUnassignedQuery,
	useLazyGetUnassignedQuery,
	useLazyDownloadListQuery,
	useLazyDownloadSelectedListQuery,
	useUpdateListMutation,
	useDeleteListMutation,
	useDeleteListPromptMutation,
	useTriggerListMutation,
	useResetListMutation,
	useBulkAssignMutation,
	useBulkChangeStatusMutation,
	useCreateSlackMessageMutation,
	useCreateListMutation,
	useUpdateCostumColumnMutation,
	useUpdateListColumnsMutation,
	useGetFiltersQuery,
	useGetColumnByIdQuery,
	useDeleteListColumnMutation,
	useRunAiEnrichmentMutation,
	useUpdateProspectMutation,
	useUpdateOrganizationMutation,
	useUpdateListDetailsMutation,
	useGetAddedListSourcesQuery,
	useGetColumnTemplatesQuery,
	useLazyGetFilterByPropertyQuery,
	useUpdateMapBusinessMutation,
	useAddScoresToListMutation,
	useRemoveSourceFromListMutation,
	useLazyGetCampaignQuery,
	useGetCampaignQuery,
} = listApi;
export const accountApiReducer = listApi.reducer;
export const accountApiMiddleware = listApi.middleware;
