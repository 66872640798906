import { z } from "zod";

import { ApolloType } from "@/enum/apollo.enum";
import { AssignStatus, InclusionStatus } from "@/enum/list.enum";
import { ApolloMetaDataSchema, MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

const { REQUIRED } = ERROR_TYPE;

export const ApolloSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	organization: z.record(z.string(), z.string()),
	type: z.nativeEnum(ApolloType),
	status: z.string().optional().nullable(),
	config: z.record(z.string(), z.string()),
	ListApollo: z
		.object({
			listItemType: z.nativeEnum(ApolloType),
		})
		.optional()
		.nullable(),
	image: z.string().optional().nullable(),
});

export const ApolloCreatedSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	type: z.nativeEnum(ApolloType),
	status: z.string().optional().nullable(),
	config: z.any(),
	ListApollo: z
		.object({
			listItemType: z.nativeEnum(ApolloType),
		})
		.optional()
		.nullable(),
});

export const ApolloTagSchema = z.object({
	category: z.string().nullish(),
	cleaned_name: z.string().nullish(),
	has_children: z.boolean().nullish(),
	id: z.string().nullish(),
	kind: z.string().nullish(),
	num_organizations: z.number().nullish(),
	parent_tag_id: z.string().nullish(),
	tag_category_downcase: z.string().nullish(),
	tag_name_unanalyzed_downcase: z.string().nullish(),
	uid: z.string().nullish(),
});

export const ApolloSearchResultPersonSchema = z.object({
	id: z.string(),
	name: z.string(),
	linkedin_url: z.string(),
	title: z.string(),
	photo_url: z.string(),
	email: z.string(),
	blacklist: z.boolean(),
	ListPerson: z
		.object({
			assignStatus: z.nativeEnum(AssignStatus),
			inclusionStatus: z.nativeEnum(InclusionStatus),
		})
		.optional(),
	organization: z
		.object({
			id: z.string(),
			name: z.string(),
			website_url: z.string(),
			logo_url: z.string().optional().nullable(),
			primary_domain: z.string().optional().nullable(),
			founded_year: z.number().optional().nullable(),
			locations: z.number().optional().nullable(),
			companyLocations: z
				.array(
					z.object({
						id: z.number(),
						city: z.string(),
						country: z.string(),
						state: z.string(),
					})
				)
				.optional()
				.nullable(),
		})
		.nullable(),
});

export const SavedApolloPeopleSchema = z.record(z.string(), z.string());

export const SavedApolloSchema = z.object({
	name: z.string(),
	type: z.nativeEnum(ApolloType),
	config: z.record(z.string(), z.string()),
});

export const SavedApollosSchema = z.object({
	data: z.array(SavedApolloSchema),
	meta: ApolloMetaDataSchema,
});

export const ApolloTagResponseSchema = z.object({
	data: z.array(ApolloTagSchema),
});

export const ApolloDownloadSchema = z.string();

export const ApolloResponseSchema = z.object({
	data: z.array(ApolloSchema),
	meta: MetaDataSchema,
});

export const ApolloPeopleResponseSchema = z.object({
	data: z.array(ApolloSearchResultPersonSchema),
	meta: ApolloMetaDataSchema,
});

export type Apollo = z.infer<typeof ApolloSchema>;

export type ApollosResponse = z.infer<typeof ApolloResponseSchema>;
export type ApolloTagResponse = z.infer<typeof ApolloTagResponseSchema>;
export type ApolloPeopleResponse = z.infer<typeof ApolloPeopleResponseSchema>;
export type SavedApolloPeopleResponse = z.infer<typeof ApolloSearchResultPersonSchema>;
export type SavedApollosResponse = z.infer<typeof SavedApollosSchema>;
export type SavedApolloResponse = z.infer<typeof SavedApolloSchema>;
