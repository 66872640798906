import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Stack, TextField, Typography } from "@mui/material";

import { faPen, faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import {
	useDeleteJobMutation,
	useGetJobsQuery,
	useToggleJobMutation,
} from "@/pages/Private/redux/job/job.api";
import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { InputField } from "@/components/InputField/InputField";

import { Toggle } from "@/components/Toggle/Toggle";

import { ListStatus } from "@/enum/list.enum";

import { jobSelector } from "@/pages/Private/redux/job/job.slice";

import { useAppSelector } from "@/redux/hooks";

import { Job, ToggleJob } from "../schema/job";
import { EditJobModal } from "./EditJobModal";
import { JobStatus } from "./JobStatus";

export const JobTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`job.${key}`), [t]);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedJob, setSelectedJob] = useState<Job | null>(null);
	const { page, setPage, limit, setLimit, searchValue, setSearchValue, query } = useSearch();
	const [deleteJob, { isLoading: isDeleteLoading }] = useDeleteJobMutation();
	const [toggleJob] = useToggleJobMutation();

	const { jobs: data } = useAppSelector(jobSelector);

	console.log(data);

	const { isLoading } = useGetJobsQuery({
		page,
		limit,
		searchValue: query,
	});

	const columns: TableColumn<Job>[] = useMemo(() => {
		const handleToggle = async (row: Job) => {
			const updater: ToggleJob = {
				id: row.id,
				activeStatus:
					row.activeStatus === ListStatus.ACTIVE ? ListStatus.PAUSED : ListStatus.ACTIVE,
			};

			await toggleJob({ ...updater }).unwrap();
		};

		return [
			{
				label: "",
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								<Toggle
									isChecked={row?.activeStatus === ListStatus.ACTIVE}
									onChange={() => {
										if (row.id && row.activeStatus !== ListStatus.ERROR) {
											handleToggle(row);
										}
									}}
								/>
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("name"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Link href={`/app/jobs/${row?.id}`}>
								<Typography color="inherit" mb={0} variant="body2">
									{row?.name}
								</Typography>
							</Link>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("status"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<JobStatus status={row.status} />
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("query"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row?.query}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("employmentType"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{ts(row.employmentType)}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				label: ts("countOfResults"),
				format: (row) => (
					<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
						<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
							<Typography color="inherit" mb={0} variant="body2">
								{row.offers.length}
							</Typography>
						</Stack>
					</Stack>
				),
			},
			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<PermissionCheck requiredPermissions={[PermissionRoles.JOB]}>
							<>
								<Box
									sx={{
										color: "neutral.500",
										"&:hover": {
											color: "primary.main",
											cursor: "pointer",
										},
									}}
									onClick={() => handleEditJob(row)}
								>
									<Icon icon={faPen} size="xl" />
								</Box>
								<Box
									sx={{
										color: "neutral.500",
										"&:hover": {
											color: "primary.main",
											cursor: "pointer",
										},
									}}
									onClick={() => handleDeleteModal(row)}
								>
									<Icon icon={faTrashCan} size="xl" />
								</Box>
							</>
						</PermissionCheck>
					</Stack>
				),
			},
		];
	}, [toggleJob, ts]);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(1);
	};

	const handleEditJob = (row: Job) => {
		setShowEditModal(true);
		setSelectedJob(row);
	};

	const handleDeleteModal = (row: Job) => {
		setShowDeleteModal(true);
		setSelectedJob(row);
	};

	const handleDeleteJob = async () => {
		try {
			await deleteJob({ id: selectedJob?.id ?? 0 }).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full">
			<div className="flex items-end align-baseline justify-between w-full mb-8">
				<div className="flex-grow mr-4 ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						label={ts("search")}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
			</div>
			<div className="w-full bg-white">
				<TableComponent
					columns={columns}
					data={data?.data || []}
					handleChangeLimit={handleChangeRowsPerPage}
					handleChangePage={(_: unknown, page: number) => setPage(page + 1)}
					itemCount={data?.meta.itemCount ?? 0}
					limit={limit}
					page={page}
				/>
			</div>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteJob}
					isLoading={isDeleteLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedJob?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}

			{showEditModal && selectedJob && (
				<EditJobModal isOpen={showEditModal} job={selectedJob} setIsOpen={setShowEditModal} />
			)}
		</div>
	);
};
