import { ListSourceType } from "@/enum/list.enum";

import { List } from "../schema/list";

export type Step = "details" | "source" | "filter" | "score" | "settings";

export const detailSteps = ["details", "source", "filter", "score", "settings"];

export const normalSteps = ["source", "filter", "score", "settings"];

export const reducedSteps = ["filter", "score", "settings"];

export const isDetailsNeeded = (data: List) =>
	data?.sourceType === ListSourceType.MAPS || data?.sourceType === ListSourceType.JOBS;

export const getAvailableSteps = (isDetailsNeeded: boolean, list?: List) => {
	if (list?.sourceType === ListSourceType.PROSPECT_SEARCH) {
		return reducedSteps;
	}

	if (isDetailsNeeded) {
		return detailSteps;
	}

	return normalSteps;
};

export const getDoneSteps = (data: List, forceSteps?: Step[]) => {
	const doneSteps: Step[] = [];

	if (data.scrapeLevel) {
		doneSteps.push("details");
	}

	const amountOfSources =
		data.maps.length +
		data.jobs.length +
		data.liCompanyActivity.length +
		data.csvEnrichments.length +
		data.liEvents.length +
		data.liPeople.length +
		data.liPosts.length +
		data.liProfile.length +
		data.liProfileVisits.length;

	if (data?.apollos?.length) {
		doneSteps.push("filter");
	}

	if (data?.lemlists?.length) {
		doneSteps.push("settings");
	}

	if (amountOfSources > 0) {
		doneSteps.push("source");
	}

	if (forceSteps && forceSteps?.length > 0) {
		forceSteps.forEach((step) => {
			if (!doneSteps.includes(step)) {
				doneSteps.push(step);
			}
		});
	}

	return doneSteps;
};
