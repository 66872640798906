import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";

import { faChevronDown, faChevronUp, faMegaphone } from "@fortawesome/pro-regular-svg-icons";

import { Breadcrumb } from "@/components/Breadcrump/Breadcrump";

import { Button } from "@/components/Button/Button";
import { useGetListQuery, useUpdateListMutation } from "@/pages/Private/redux/list/list.api";

import { Icon, LoadingOverlay } from "@/components";

import { ButtonColor } from "@/components/Button/types";

import { ListSourceType, ListType, SearchBase, UpdateType } from "@/enum/list.enum";
import { ERROR_TYPE, getAllErrors, renderErrorMessages, useSearch } from "@/utils";

import { Checkbox } from "@/components/Checkbox/Checkbox";
import { InputField } from "@/components/InputField/InputField";

import {
	AutoCompleteItem as DropdownAutoCompleteItem,
	Dropdown,
} from "@/components/Dropdown/Dropdown";

import { useGetLemlistsQuery } from "@/pages/Private/redux/lemlist/lemlist.api";

import { AutoComplete, AutoCompleteItem } from "@/components/AutoComplete/AutoComplete";

import { SaveListSettings, SaveListSettingsValidationSchema, UpdateList } from "../../schema/list";
import { getAvailableSteps, isDetailsNeeded, getDoneSteps } from "../../utils/create-list-steps";
import { Stepper } from "../ListEditDetails/components/Stepper";
import { AdvancedCampaignSettings } from "../../components/AdvancedCampaignSettings";
import { CreateCampaignRule } from "../../schema/campaign-rule";

export const ListEditSetting: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();
	const navigate = useNavigate();

	const ts = (key: string) => t(`list.${key}`);

	const tsSettings = (key: string) => t(`list.settings.${key}`);

	const { query: lemlistQuery, setSearchValue: setSearchLemlist } = useSearch();

	const { data, isFetching } = useGetListQuery(+id);

	const { data: lemlists } = useGetLemlistsQuery({
		page: 1,
		limit: 10,
		searchValue: lemlistQuery,
	});

	const [selectedCampaign, setSelectedCampaign] = useState<AutoCompleteItem | null>();

	const [showTypeSelection, setShowTypeSelection] = useState<boolean>(false);
	const [showCampaignRules, setShowCampaignRules] = useState<boolean>(true);

	const [withEmail, setWithEmail] = useState<boolean>(data?.withEmail || false);
	const [tryToFindEmail, setTryToFindEmail] = useState<boolean>(data?.tryToFindEmail || false);
	const [onlyNewLeads, setOnlyNewLeads] = useState<boolean>(data?.onlyNewLeads || false);

	const [listType, setListType] = useState<ListType | null>(data?.type || null);
	const [searchBase, setSearchBase] = useState<SearchBase | null>(data?.searchBase || null);

	const [campaignRules, setCampaignRules] = useState<CreateCampaignRule[]>(
		data?.campaignRules || []
	);

	const [currentFormState, setCurrentFormState] = useState<Partial<SaveListSettings>>();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof SaveListSettings) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const [update, { isLoading }] = useUpdateListMutation();

	const {
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<SaveListSettings>({
		defaultValues: {
			name: data?.name,
			searchBase: data?.searchBase,
			maxProspects: data?.maxProspects?.toString() || "100",
			maxProspectsPerDay: data?.maxProspectsPerDay?.toString() || "100",
			type: data?.type,
			withEmail: data?.withEmail || false,
			tryToFindEmail: data?.tryToFindEmail || false,
			campaigns: [],
			onlyNewLeads: data?.onlyNewLeads || false,
		},
		resolver: zodResolver(SaveListSettingsValidationSchema),
	});

	useEffect(() => {
		if (listType) {
			setValue("type", listType, {
				shouldDirty: true,
				shouldValidate: true,
			});
		}
	}, [listType, setValue]);

	useEffect(() => {
		if (searchBase) {
			setValue("searchBase", searchBase, {
				shouldDirty: true,
				shouldValidate: true,
			});
		}
	}, [searchBase, setValue]);

	useEffect(() => {
		if (data) {
			let showTypeSelection = true;

			setCampaignRules(data.campaignRules || []);

			if ([ListSourceType.PROSPECT_SEARCH].includes(data.sourceType)) {
				showTypeSelection = false;
			}

			setValue("name", data.name, {
				shouldDirty: true,
				shouldValidate: true,
			});

			setCurrentFormState({
				name: data?.name,
				searchBase: data?.searchBase,
				maxProspects: data?.maxProspects?.toString() || "100",
				maxProspectsPerDay: data?.maxProspectsPerDay?.toString() || "100",
				type: data?.type,
				withEmail: data?.withEmail || false,
				tryToFindEmail: data?.tryToFindEmail || false,
				campaigns: [],
				onlyNewLeads: data?.onlyNewLeads || false,
			});

			if (
				["GET_MANAGING_DIRECTOR", "GET_INFO_AT", "GET_PERSON_FROM_OFFER"].includes(data.scrapeLevel)
			) {
				showTypeSelection = false;

				setValue("searchBase", SearchBase.SOURCE, {
					shouldDirty: true,
					shouldValidate: true,
				});

				setSearchBase(SearchBase.SOURCE);
			} else if (["GET_ENRICHMENT"].includes(data.scrapeLevel)) {
				showTypeSelection = false;

				setValue("searchBase", SearchBase.COMPANY, {
					shouldDirty: true,
					shouldValidate: true,
				});

				setSearchBase(SearchBase.COMPANY);
			} else {
				setValue("searchBase", data.searchBase, {
					shouldDirty: true,
					shouldValidate: true,
				});

				setSearchBase(data.searchBase);
			}

			setValue("maxProspects", data.maxProspects?.toString() || "", {
				shouldDirty: true,
				shouldValidate: true,
			});

			setValue("maxProspectsPerDay", data.maxProspectsPerDay?.toString() || "", {
				shouldDirty: true,
				shouldValidate: true,
			});

			setValue("type", data.type, {
				shouldDirty: true,
				shouldValidate: true,
			});

			setListType(data.type);

			setValue("withEmail", data.withEmail, {
				shouldDirty: true,
				shouldValidate: true,
			});

			setWithEmail(data.withEmail);

			setTryToFindEmail(data.tryToFindEmail);

			setValue("tryToFindEmail", data.tryToFindEmail, {
				shouldDirty: true,
				shouldValidate: true,
			});

			if (data.lemlists?.length) {
				setSelectedCampaign({
					id: data.lemlists[0].id,
					title: data.lemlists[0].name,
				});
			}

			setOnlyNewLeads(data.onlyNewLeads);

			setShowTypeSelection(showTypeSelection);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const onSubmit = async (values: SaveListSettings) => {
		try {
			if (data) {
				let updater: Partial<UpdateList> = {
					id: data.id,
					updateType: UpdateType.SETTINGS,
				};

				if (data) {
					updater = {
						...updater,
						...{
							id: data.id,
							name: values.name,
							maxProspects: values.maxProspects ? +values.maxProspects : null,
							maxProspectsPerDay: values.maxProspectsPerDay ? +values.maxProspectsPerDay : null,
							withEmail: withEmail,
							tryToFindEmail: tryToFindEmail,
							type: listType,
							searchBase: values.searchBase,
							campaigns: selectedCampaign ? [selectedCampaign.id as number] : [],
							onlyNewLeads: onlyNewLeads,
							campaignRules: campaignRules,
						},
					};
				}

				await update({ ...updater }).unwrap();
				navigate("/app/lists");
			}
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return isFetching || !data || isFetching ? (
		<LoadingOverlay />
	) : (
		<div className="sm:block flex flex-col items-start justify-between mb-32 pb-32">
			<Breadcrumb
				path={[
					{ name: ts("title"), link: "/" },
					{ name: ts("create.title"), link: "/create" },
					{ name: data?.name || "", link: `/create/${id}/details` },
				]}
			/>
			<div className="text-lg leading-9 mb-5">Create {ts(data.sourceType)} List</div>
			<Stepper
				availableSteps={getAvailableSteps(isDetailsNeeded(data), data)}
				currentStep="settings"
				doneSteps={getDoneSteps(data, ["score"])}
				listId={data.id}
			/>
			<div className="w-full">
				<div className="max-w-[612px]">
					<div className="text-md mb-5">Settings</div>
					<div className="flex flex-row gap-4 w-full mb-1">
						<InputField
							error={errors.name?.message}
							handleChange={(event: ChangeEvent<HTMLInputElement>) => {
								handleSaveFormState(event.target.value, "name");
							}}
							label={ts("name")}
							name="name"
							placeholder={ts("name")}
							showError={!!errors.name?.message}
							value={currentFormState?.name || ""}
						/>
					</div>
					<div className="flex flex-row gap-4 w-full mb-4">
						<Dropdown
							data={[
								{
									title: tsSettings("once"),
									id: ListType.ONCE,
								},
								{
									title: tsSettings("ongoing"),
									id: ListType.ON_GOING,
								},
							]}
							floating={true}
							handleSelect={(value: DropdownAutoCompleteItem) => {
								setListType(value.id as ListType);
							}}
							label={"List frequency"}
							value={
								listType
									? {
											id: listType,
											title: tsSettings(listType === ListType.ONCE ? "once" : "ongoing"),
									  }
									: undefined
							}
						/>
					</div>

					<div className="flex flex-col gap-4 w-full mb-1">
						{listType === ListType.ONCE && (
							<InputField
								error={!!errors.maxProspects?.message}
								handleChange={(event: ChangeEvent<HTMLInputElement>) => {
									handleSaveFormState(event.target.value, "maxProspects");
								}}
								label={tsSettings("maxProspects")}
								name="maxProspects"
								placeholder={tsSettings("maxProspects")}
								showError={!!errors.maxProspects?.message}
								value={currentFormState?.maxProspects || ""}
							/>
						)}

						{listType === ListType.ON_GOING && (
							<InputField
								error={!!errors.maxProspectsPerDay?.message}
								handleChange={(event: ChangeEvent<HTMLInputElement>) => {
									handleSaveFormState(event.target.value, "maxProspectsPerDay");
								}}
								label={tsSettings("maxProspectsPerDay")}
								name="maxProspectsPerDay"
								placeholder={tsSettings("maxProspectsPerDay")}
								showError={!!errors.maxProspectsPerDay?.message}
								value={currentFormState?.maxProspectsPerDay || ""}
							/>
						)}
					</div>
					{showTypeSelection &&
						data?.sourceType !== ListSourceType.PROSPECT_SEARCH &&
						!["GET_MANAGING_DIRECTOR", "GET_INFO_AT", "GET_PERSON_FROM_OFFER"].includes(
							data.scrapeLevel
						) && (
							<div className="flex flex-row gap-4 w-full mb-4">
								<Dropdown
									data={[
										{
											title: t(`enum.searchBase.PERSON`),
											id: SearchBase.PERSON,
										},
										{
											title: t(`enum.searchBase.COMPANY`),
											id: SearchBase.COMPANY,
										},
									]}
									floating={true}
									handleSelect={(value: DropdownAutoCompleteItem) => {
										setSearchBase(value.id as SearchBase);
									}}
									label={ts("searchBase")}
									value={
										searchBase
											? {
													id: searchBase as SearchBase,
													title:
														searchBase === SearchBase.PERSON
															? t(`enum.searchBase.PERSON`)
															: t(`enum.searchBase.COMPANY`),
											  }
											: undefined
									}
								/>
							</div>
						)}

					{!["GET_MANAGING_DIRECTOR", "GET_INFO_AT", "GET_PERSON_FROM_OFFER"].includes(
						data.scrapeLevel
					) && (
						<div className="flex flex-col w-full mb-4">
							<div className="block mb-2 text-sm font-medium text-gray-900 appearance-none">
								Connection via
							</div>
							<div className="flex flex-col gap-2 w-full">
								<Checkbox
									isChecked={tryToFindEmail}
									name="withEmailOrOnlyTry"
									onChange={() => {
										setWithEmail(false);
										setTryToFindEmail(!tryToFindEmail);
									}}
								>
									{tsSettings("tryToFindEmail")}
								</Checkbox>
								<Checkbox
									isChecked={withEmail}
									name="withEmailOrOnlyTry"
									onChange={() => {
										setWithEmail(!withEmail);
										setTryToFindEmail(false);
									}}
								>
									{tsSettings("withEmail")}
								</Checkbox>
								<Checkbox
									isChecked={true}
									isDisabled={true}
									name="withLinkedin"
									onChange={() => {
										console.log("withLinkedin");
									}}
								>
									{tsSettings("withLinkedin")}
								</Checkbox>
							</div>
						</div>
					)}
					<div className="flex flex-col w-full mb-4">
						<Checkbox
							isChecked={onlyNewLeads}
							label={tsSettings("onlyNewLeads")}
							name="onlyNewLeads"
							onChange={() => {
								setOnlyNewLeads(!onlyNewLeads);
							}}
						/>
					</div>

					<div className="flex flex-col gap-4 w-full mb-4">
						<AutoComplete
							data={
								lemlists?.data?.map((c) => {
									return { id: c.id, title: c.name } as AutoCompleteItem;
								}) || []
							}
							handleSearch={(value: string) => setSearchLemlist(value)}
							handleSelect={(value?: AutoCompleteItem) => setSelectedCampaign(value)}
							icon={faMegaphone}
							label={ts("campaign.search")}
							selectedItem={selectedCampaign?.title ?? ""}
						/>
					</div>
				</div>
				<button
					className="text-ssm mb-4"
					onClick={() => {
						setShowCampaignRules(!showCampaignRules);
					}}
				>
					Advanced campaign settings <Icon icon={showCampaignRules ? faChevronUp : faChevronDown} />
				</button>

				<AdvancedCampaignSettings
					campaignRules={campaignRules}
					lemlists={lemlists?.data || []}
					list={data}
					setCampaignRules={setCampaignRules}
					setSearchLemlist={setSearchLemlist}
					setShowCampaignRules={setShowCampaignRules}
					showCampaignRules={showCampaignRules}
				/>

				<div className="flex flex-col gap-4 w-full mb-4">
					{getAllErrors(undefined, formErrors).length
						? renderErrorMessages(getAllErrors(undefined, formErrors))
						: null}
				</div>
			</div>

			<div className="absolute mt-14 h-[72px] flex flex-row justify-between items-center w-auto border-t border-t-border px-8 py-[14px] rounded-t-16 bottom-0 right-0 z-20 bg-white w-full">
				<div></div>
				<div className="flex flex-row self-left items-center gap-x-4">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						title={t("basics.previous")}
						onClick={() => navigate(`/app/lists/create/score/${id}`)}
					/>
					<Button isLoading={isLoading} title={t("basics.next")} onClick={handleSubmit(onSubmit)} />
				</div>
			</div>
		</div>
	);
};
