import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { Campaigns } from "./pages/Campaigns/Campaigns";
import { Replies } from "./pages/Replies/Replies";
import { NoReply } from "./pages/NoReply/NoReply";

export const ListView: FunctionComponent = () => {
	return (
		<Routes>
			<Route element={<Campaigns />} path="/campaigns" />
			<Route element={<Replies />} path="/replies" />
			<Route element={<NoReply />} path="/no-reply" />
		</Routes>
	);
};
