import { faPhone } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "@/components";

import { TableColumn } from "@/components/Table/Table";

import { SortParams } from "@/types/types";

import { ListPerson } from "../schema/list";
import { getContextMenuItem } from "../utils/contextMenu";
import {
	renderAssignToColumn,
	renderCompanyCityColumn,
	renderCompanyColumn,
	renderCompanyCountryColumn,
	renderCompanyStateColumn,
	renderConnectionsColumn,
	renderEstimatedNumEmployeesColumn,
	renderFollowers,
	renderFoundedYearColumn,
	renderGenderColumn,
	renderIndustryColumn,
	renderLanguageColumn,
	renderLiCompanyActivityContentColumn,
	renderLiCompanyActivityContentResponseColumn,
	renderLiCompanyActivityPostedAtColumn,
	renderLiCompanyActivityTypeColumn,
	renderMaybeColumn,
	renderNameColumn,
	renderOtherListsColumn,
	renderPersonCityColumn,
	renderPersonCountryColumn,
	renderPersonStateColumn,
	renderSelectColumn,
	renderSeniorityColumn,
	renderTitleColumn,
} from "../utils/allColumns";

export interface LiCompanyActivityTableColumnsProps {
	selectAll: boolean;
	toggleSelectAll: () => void;
	selectedItems: number[];
	handleSelectItem: (id: number) => void;
	handleCopyClick: (value: string) => void;
	handleContextMenu: (value: string, row: ListPerson) => void;
	ts: (key: string) => string;
	sortingInbox: SortParams;
	setShowEdit: ({ id, name }: { id: number; name: string }) => void;
	showEdit: { id: number; name: string } | undefined;
}

export const LiCompanyActivityTableColumns = ({
	selectAll,
	toggleSelectAll,
	selectedItems,
	handleSelectItem,
	handleCopyClick,
	handleContextMenu,
	ts,
	sortingInbox,
	setShowEdit,
	showEdit,
}: LiCompanyActivityTableColumnsProps): TableColumn[] => {
	return [
		renderSelectColumn(toggleSelectAll, selectAll, selectedItems, handleSelectItem),
		renderMaybeColumn(),
		renderAssignToColumn(ts, handleCopyClick),
		{ ...getContextMenuItem(handleContextMenu, ts) },
		renderNameColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit),
		renderCompanyColumn(ts, handleCopyClick, sortingInbox, setShowEdit, showEdit),
		renderLiCompanyActivityTypeColumn(ts, sortingInbox),
		renderLiCompanyActivityContentColumn(ts, handleCopyClick, sortingInbox),
		renderLiCompanyActivityContentResponseColumn(ts, handleCopyClick, sortingInbox),
		renderLiCompanyActivityPostedAtColumn(ts, sortingInbox),
		renderGenderColumn(ts, handleCopyClick, sortingInbox),
		renderTitleColumn(ts, handleCopyClick, sortingInbox),
		renderSeniorityColumn(ts, handleCopyClick, sortingInbox),
		renderConnectionsColumn(ts, sortingInbox),
		renderIndustryColumn(ts, handleCopyClick, sortingInbox),
		{
			title: ts("companyPhone"),
			width: "fit-content",
			orderTitle: "organization.phone",
			orderStatus: 0,
			uid: "companyPhone",
			hideable: true,
			moveable: true,
			show: true,
			render: (row: ListPerson) => (
				<div>
					<a href={`tel: ${row.prospect?.organization?.phone}`}>
						<Icon className="mr-1" icon={faPhone} />
					</a>
					<button
						className="cursor-pointer"
						onClick={() => handleCopyClick(row.prospect?.organization?.phone ?? "")}
					>
						{row.prospect?.organization?.phone ?? ""}
					</button>
				</div>
			),
		},
		renderEstimatedNumEmployeesColumn(ts, handleCopyClick, sortingInbox),
		renderFoundedYearColumn(ts, handleCopyClick, sortingInbox),
		renderPersonCityColumn(ts, handleCopyClick, sortingInbox),
		renderPersonStateColumn(ts, handleCopyClick, sortingInbox),
		renderPersonCountryColumn(ts, handleCopyClick, sortingInbox),
		renderCompanyCityColumn(ts, handleCopyClick, sortingInbox),
		renderCompanyStateColumn(ts, handleCopyClick, sortingInbox),
		renderCompanyCountryColumn(ts, handleCopyClick, sortingInbox),
		renderLanguageColumn(ts, sortingInbox),
		renderFollowers(ts, sortingInbox),
		renderOtherListsColumn(ts, sortingInbox),
	];
};
