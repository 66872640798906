import { FunctionComponent } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { ReactComponent as Dot } from "@assets/icons/sidebar/dot.svg";

import { classNames } from "@/utils/classNames";
import { SidenavItem } from "@/constants/sidenav.config";

import { SvgIcon } from "../Icon/SvgIcon";

interface SidenavItemProps extends SidenavItem {
	// isAdmin: boolean;
	children?: JSX.Element | JSX.Element[];
	testId?: string;
}

export const SidebarItem: FunctionComponent<SidenavItemProps> = ({
	title,
	path,
	strictPath,
	icon,
	iconActive,
	onClick,
	// isAdmin,
	children,
	testId,
	type,
}) => {
	const location = useLocation();

	let match = location.pathname.includes(path);

	if (strictPath) {
		match = location.pathname === path;
	}

	const isActive = !!match;

	const getClassName = (isActive: boolean) => {
		const itemClassNames = isActive
			? classNames("rounded-[12px] bg-custom-blue text-blue-action")
			: classNames("text-gray-700 ");

		return classNames("mb-1 w-[208px] h-10 flex items-center px-2", itemClassNames);
	};

	const getIcon = (isActive: boolean) => {
		const currentIcon = isActive && iconActive ? iconActive : icon;

		return typeof currentIcon === "string" ? (
			<img
				alt="icon"
				className={classNames(
					`w-[24px] rounded`,
					type === "logo" ? "bg-gray-700" : "",
					type === "icon" ? "invert" : ""
				)}
				src={currentIcon}
			/>
		) : (
			currentIcon
		);
	};

	// To do: include counters and right items somehow (from config, they should be rendered as children)
	return (
		<NavLink className={getClassName(isActive)} data-test={testId} to={path} onClick={onClick}>
			<div className="flex items-center flex-grow flex-shrink">
				{icon && <div className="w-[33px]">{getIcon(isActive)}</div>}
				{type === "bullet" && isActive && (
					<div className="w-[16px]">
						<SvgIcon
							className={classNames(
								"w-[8px] h-[8px] ",
								isActive ? "text-blue-action" : "text-gray-700"
							)}
							svgIcon={Dot}
						/>
					</div>
				)}
				<span className="text-sm font-medium">{title}</span>
			</div>
			{children && <div>{children}</div>}
		</NavLink>
	);
};
