import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, TextField } from "@mui/material";
import useLocalStorageState from "use-local-storage-state";

import {
	faAdd,
	faCheck,
	faColumns3,
	faMegaphone,
	faSearch,
	faSliders,
	faUsers,
} from "@fortawesome/pro-regular-svg-icons";

import { useParams } from "react-router-dom";

import { SvgIcon } from "@/components/Icon/SvgIcon";
import { ReactComponent as Reassign } from "@assets/icons/inbox/reassign.svg";
import { ReactComponent as Clock } from "@assets/icons/inbox/clock.svg";
import { ReactComponent as Exclude } from "@assets/icons/inbox/exclude.svg";
import { ReactComponent as CrossedCircle } from "@assets/icons/inbox/crossed-circle.svg";

import { Icon, LoadingOverlay, Modal } from "@/components";
import { useSearch } from "@/utils";

import {
	useBulkAssignMutation,
	useBulkChangeStatusMutation,
	useCreateSlackMessageMutation,
	useDeleteListColumnMutation,
	useLazyGetUnassignedQuery,
} from "@/pages/Private/redux/list/list.api";

import { Table, TableColumn } from "@/components/Table/Table";
import { useBulkFunctions } from "@/utils/userBulkFunctions";
import { SortParams } from "@/types/types";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { clearInbox, listSelector, setInboxSorting } from "@/pages/Private/redux/list/list.slice";

import { useGetLemlistsQuery } from "@/pages/Private/redux/lemlist/lemlist.api";

import { AutoComplete, AutoCompleteItem } from "@/components/AutoComplete/AutoComplete";

import { Button } from "@/components/Button/Button";

import { ButtonColor, ButtonSize } from "@/components/Button/types";

import {
	AssignStatus,
	CustomColumnTypes,
	InclusionFilterStatus,
	InclusionStatus,
	ListSourceType,
} from "@/enum/list.enum";

import { useCopyToClipboard } from "@/utils/useCopyToClipboard";

import { InputField } from "@/components/InputField/InputField";

import { classNames } from "@/utils/classNames";

import { TItem } from "@/components/SortableList/SortableList";

import { useCreateBlacklistMutation } from "@/pages/Private/redux/blacklist/blacklist.api";

import { TextArea } from "@/components/TextArea/TextArea";
import { BlacklistType } from "@/enum/blacklist.enum";
import { Toggle } from "@/components/Toggle/Toggle";

import { useSnackBar } from "@/utils/useSnackbar";

import { MapScrapeType } from "@/enum/map.enum";
import { useGetUsersQuery } from "@/pages/Private/redux/admin/admin.api";

import { Radio } from "@/components/Radio/Radio";

import { JobScrapeType } from "@/enum/job.enum";

import { ListPerson } from "../../List/schema/list";
import { FilterModal, IFilterWithValues } from "./FilterModal";
import { CreateCustomColumnModal } from "./CreateCustomColumnModal";
import { CustomTextColumn } from "./CustomTextColumn";
import { CustomNumberColumn } from "./CustomNumberColumn";
import { CustomBooleanColumn } from "./CustomBooleanColumn";
import { CustomLinkColumn } from "./CustomLinkColumn";
import { GeneralTableColumns } from "./GeneralTableColumns";
import { LiEventTableColumns } from "./LiEventTableColumns";
import { GoogleMapsTableColumns } from "./GoogleMapsTableColumns";
import { JobTableColumns } from "./JobTableColumns";
import { EditCustomColumnModalWrapper } from "./EditCustomColumnModalWrapper";
import { ColumnModal } from "./ColumnModal";
import { LiContentTableColumns } from "./LiContentTableColumns";
import { getOrderStatus } from "../utils/colum-utils";
import { LiPostTableColumns } from "./LiPostTableColumns";
import { LiCompanyActivityTableColumns } from "./LiCompanyActivityTableColumns";

interface UnassignedTableProps {
	assignStatus?: AssignStatus;
	listType?: ListSourceType;
}

export interface FilterUnassignedTable {
	name?: string;
	email?: string;
	linkedinUrl?: string;
	country?: string;
	city?: string;
	state?: string;
	company?: string;
	companyCountry?: string;
	companyState?: string;
	companyCity?: string;
	companyLinkedinUrl?: string;
	title?: string;
	seniority?: string;
	website?: string;
	inclusionStatus?: InclusionFilterStatus;
	event?: {
		name: string;
		id: number;
	}[];
}

export const UnassignedTable: FunctionComponent<UnassignedTableProps> = ({
	assignStatus,
	listType,
}) => {
	const { t } = useTranslation();
	const { id } = useParams();
	const ts = useCallback((key: string) => t(`inbox.${key}`), [t]);
	const { sortingInbox, inbox: data, meta } = useAppSelector(listSelector);

	const { renderSnackbar, handleCopyClick } = useCopyToClipboard();
	const { handleOpenSnackbar, renderMessageSnackbar } = useSnackBar();
	const { page, setPage, limit, query, setLimit, setSearchValue, searchValue } = useSearch();

	const { query: lemlistQuery, setSearchValue: setSearchLemlist } = useSearch();
	const { query: userQuery, setSearchValue: setSearchUser } = useSearch();
	const [customColumnTextValue, setCustomColumnTextValue] = useState<string>();
	const [customColumnNumberValue, setCustomColumnNumberValue] = useState<number | null>();
	const [showSelectColumns, setShowSelectColumns] = useState(false);
	const [reportReason, setReportReason] = useState<string>("");

	const dispatch = useAppDispatch();
	const { selectedItems, selectAll, handleSelectAll, handleSelectItem, toggleSelectAll } =
		useBulkFunctions();

	const [bulkAssign] = useBulkAssignMutation();
	const [bulkChangeStatus] = useBulkChangeStatusMutation();
	const [createBlacklist] = useCreateBlacklistMutation();
	const [createSlackMessage] = useCreateSlackMessageMutation();

	const [showReassignmentModal, setShowReassignmentModal] = useState<boolean>(false);
	const [showNewColumn, setShowNewColumn] = useState<boolean>(false);
	const [editableColumn, setEditableColumn] = useState<TItem>();
	const [showEditColumn, setShowEditColumn] = useState<boolean>(false);
	const [showDeleteColumn, setShowDeleteColumn] = useState<boolean>(false);
	const [showBlacklistModal, setShowBlacklistModal] = useState<boolean>(false);
	const [showExcludeFromListModal, setShowExcludeFromListModal] = useState<boolean>(false);
	const [showAddToListModal, setShowAddToListModal] = useState<boolean>(false);
	const [showCheckLasterModal, setShowCheckLasterModal] = useState<boolean>(false);
	const [showRevertModal, setShowRevertModal] = useState<boolean>(false);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [showReportRecordModal, setShowReportRecordModal] = useState<boolean>(false);
	const [addEntireCompanyToBlacklist, setAddEntireCompanyToBlacklist] = useState<boolean>(false);
	const [selectedItemForConfirmation, setSelectedItemForConfirmation] = useState<ListPerson | null>(
		null
	);
	const [selectedCampaign, setSelectedCampaign] = useState<AutoCompleteItem | null>();
	const [selectedUserToAssign, setSelectedUserToAssign] = useState<AutoCompleteItem | null>();
	const [filter, setFilter] = useState<IFilterWithValues>();
	const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
	const [showEdit, setShowEdit] = useState<{ id: number; name: string }>();
	const [tableConfig, setTableConfig] = useLocalStorageState<TItem[]>(`column-settings-${id}`, {
		defaultValue: [],
	});
	const [personAssignment, setPersonAssignment] = useState<boolean>(false);

	const [deleteColumn, { isLoading: deleteLoading }] = useDeleteListColumnMutation();
	const [getUnassigned, { isLoading, isFetching }] = useLazyGetUnassignedQuery();

	const handleSorting = (sortParams: SortParams) => {
		setPage(1);
		dispatch(setInboxSorting(sortParams));
	};

	const { data: lemlists } = useGetLemlistsQuery({
		page: 1,
		limit: 10,
		searchValue: lemlistQuery,
	});

	const { data: users } = useGetUsersQuery({
		page: 1,
		limit,
		searchValue: userQuery,
	});

	useEffect(() => {
		dispatch(clearInbox());
		getUnassigned({
			page,
			limit,
			searchValue: query,
			sorting: sortingInbox,
			assignStatus,
			listId: id ? +id : undefined,
			filter,
		});
	}, [assignStatus, dispatch, filter, getUnassigned, id, limit, page, query, sortingInbox]);

	const handleChangeRowsPerPage = (newLimit: number) => {
		setLimit(newLimit);
		setPage(1);
	};

	const handleBulkReassign = async () => {
		if (!data) {
			return;
		}

		let param: Array<{
			listPersonId: number;
			prospectId: number;
			lemlistId?: number;
			assignedToUserId?: number;
		}> = [];

		if (personAssignment) {
			const userAssigned = users?.data.find((c) => c.id === selectedUserToAssign?.id);

			if (!userAssigned) {
				return;
			}

			if (selectedItemForConfirmation) {
				const cObj = data.find((item) => item.id === selectedItemForConfirmation.id);

				if (!cObj) {
					return;
				}

				const assignedToUserId = userAssigned.id;

				if (!assignedToUserId) {
					return;
				}

				if (cObj) {
					param = [
						{
							listPersonId: selectedItemForConfirmation.id,
							prospectId: cObj.prospect?.id,
							assignedToUserId: assignedToUserId,
						},
					];
				}
			} else {
				param = selectedItems
					.map((id) => {
						const cObj = data.find((item) => item.id === id);

						if (!cObj) {
							return;
						}

						const assignedToUserId = userAssigned.id;

						if (!assignedToUserId) {
							return;
						}

						return {
							listPersonId: id,
							prospectId: cObj.prospect?.id,
							assignedToUserId: assignedToUserId,
						};
					})
					.filter(Boolean) as Array<{
					listPersonId: number;
					prospectId: number;
					lemlistId?: number;
					assignedToUserId?: number;
				}>;
			}
		} else {
			const lemlist = lemlists?.data.find((c) => c.id === selectedCampaign?.id);

			if (!lemlist) {
				return;
			}

			if (selectedItemForConfirmation) {
				const cObj = data.find((item) => item.id === selectedItemForConfirmation.id);

				if (!cObj) {
					return;
				}

				const lemlistId = lemlist.id;

				if (!lemlistId) {
					return;
				}

				if (cObj) {
					param = [
						{
							listPersonId: selectedItemForConfirmation.id,
							prospectId: cObj.prospect?.id,
							lemlistId: lemlistId,
						},
					];
				}
			} else {
				param = selectedItems
					.map((id) => {
						const cObj = data.find((item) => item.id === id);

						if (!cObj) {
							return;
						}

						const lemlistId = lemlist.id;

						if (!lemlistId) {
							return;
						}

						return {
							listPersonId: id,
							prospectId: cObj.prospect?.id,
							lemlistId: lemlistId,
						};
					})
					.filter(Boolean) as Array<{
					listPersonId: number;
					prospectId: number;
					lemlistId?: number;
					assignedToUserId?: number;
				}>;
			}
		}

		await bulkAssign({
			listId: id ? +id : 0,
			assign: param.filter(Boolean) as {
				listPersonId: number;
				prospectId: number;
				lemlistId?: number;
				assignedToUserId?: number;
			}[],
			assignStatus: AssignStatus.ASSIGNING,
		}).unwrap();
	};

	const handleBulkStatusChange = async ({
		assignStatus,
		inclusionStatus,
	}: {
		assignStatus?: AssignStatus;
		inclusionStatus?: InclusionStatus;
	}) => {
		if (!data) {
			return;
		}

		let param: Array<{ listPersonId: number; prospectId: number }> = [];

		if (selectedItemForConfirmation) {
			const cObj = data.find((item) => item.id === selectedItemForConfirmation.id);

			if (cObj) {
				param = [
					{
						listPersonId: selectedItemForConfirmation.id,
						prospectId: cObj.prospect?.id,
					},
				];
			}
		} else {
			param = selectedItems
				.map((id) => {
					const cObj = data.find((item) => item.id === id);

					if (!cObj) {
						return undefined;
					}

					return {
						listPersonId: id,
						prospectId: cObj.prospect?.id,
					};
				})
				.filter(Boolean) as Array<{ listPersonId: number; prospectId: number }>;
		}

		if (param.length > 0) {
			await bulkChangeStatus({
				listId: id ? +id : 0,
				assign: param,
				assignStatus: assignStatus ?? undefined,
				inclusionStatus: inclusionStatus ?? undefined,
			}).unwrap();
		}

		handleOpenSnackbar(ts("bulkStatusChangeSuccess"));
		setSelectedItemForConfirmation(null);
	};

	const handleBulkAddToBlacklist = async () => {
		if (!data) {
			return;
		}

		let param: Array<{ listPersonId: number; prospectId: number }> = [];

		if (selectedItemForConfirmation) {
			const cObj = data.find((item) => item.id === selectedItemForConfirmation.id);

			if (cObj) {
				param = [
					{
						listPersonId: selectedItemForConfirmation.id,
						prospectId: cObj.prospect?.id,
					},
				];

				await createBlacklist({
					domain: addEntireCompanyToBlacklist
						? cObj.prospect?.organization?.primaryDomain ?? undefined
						: undefined,
					email: cObj.prospect?.email ?? undefined,
					linkedinUrl: cObj.prospect?.linkedinUrl ?? undefined,
					type: BlacklistType.BLOCKED,
				}).unwrap();
			}
		} else {
			const promises = selectedItems.map(async (id) => {
				const cObj = data.find((item) => item.id === id);

				if (!cObj) {
					return undefined;
				}

				await createBlacklist({
					domain: addEntireCompanyToBlacklist
						? cObj.prospect?.organization?.primaryDomain ?? undefined
						: undefined,
					email: cObj.prospect?.email ?? undefined,
					linkedinUrl: cObj.prospect?.linkedinUrl ?? undefined,
					type: BlacklistType.BLOCKED,
				}).unwrap();

				return {
					listPersonId: id,
					prospectId: cObj.prospect?.id,
				};
			});

			const results = await Promise.all(promises);

			param = results.filter(
				(result): result is { listPersonId: number; prospectId: number } => result !== undefined
			);
		}

		if (param.length > 0) {
			await bulkChangeStatus({
				listId: id ? +id : 0,
				assign: param,
				assignStatus: AssignStatus.BLACKLIST,
			}).unwrap();
		}

		handleOpenSnackbar(ts("addToBlackListSuccess"));

		setSelectedItemForConfirmation(null);
		setAddEntireCompanyToBlacklist(false);
	};

	const handleDeleteColumn = async () => {
		await deleteColumn({ id: editableColumn?.columnId }).unwrap();
		setEditableColumn(undefined);
	};

	const handleReportReason = async () => {
		try {
			await createSlackMessage({
				listId: selectedItemForConfirmation?.list?.id ?? 0,
				leadId: selectedItemForConfirmation?.id ?? 0,
				companyName: selectedItemForConfirmation?.prospect?.organization?.name ?? "",
				listName: selectedItemForConfirmation?.list?.name ?? "",
				recordId: selectedItemForConfirmation?.prospect?.id ?? 0,
				leadName: selectedItemForConfirmation?.prospect?.name ?? "",
				reportReason: reportReason,
			}).unwrap();
			handleOpenSnackbar(ts("reportReasonSent"));
		} catch (e) {
			console.error(e);
		}
	};

	const handleBulkAssign = async () => {
		if (!data) {
			return;
		}

		let param: Array<{ listPersonId: number; prospectId: number; lemlistId: number }> = [];

		if (selectedItemForConfirmation) {
			const cObj = data.find((item) => item.id === selectedItemForConfirmation.id);

			if (cObj?.list?.lemlists?.at(0)) {
				const lemlistId = cObj.list.lemlists.at(0)?.id;

				if (lemlistId) {
					param = [
						{
							listPersonId: selectedItemForConfirmation.id,
							prospectId: cObj.prospect?.id,
							lemlistId: lemlistId,
						},
					];
				}
			}
		} else {
			param = selectedItems
				.map((id) => {
					const cObj = data.find((item) => item.id === id);

					if (!cObj?.list?.lemlists?.at(0)) {
						return undefined;
					}

					const lemlistId = cObj.list.lemlists.at(0)?.id;

					if (!lemlistId) {
						return undefined;
					}

					return {
						listPersonId: id,
						prospectId: cObj.prospect?.id,
						lemlistId: lemlistId,
					};
				})
				.filter(Boolean) as Array<{ listPersonId: number; prospectId: number; lemlistId: number }>;
		}

		if (param.length > 0) {
			await bulkAssign({
				listId: id ? +id : 0,
				assign: param,
				assignStatus: AssignStatus.ASSIGNING,
			}).unwrap();
		}

		handleOpenSnackbar(ts("bulkAssignSuccess"));
		setSelectedItemForConfirmation(null);
	};

	const handleBulkRevertAssign = async (assignStatus: AssignStatus) => {
		if (!data) {
			return;
		}

		let param: Array<{ listPersonId: number; prospectId?: number; lemlistId?: number }> = [];

		if (selectedItemForConfirmation) {
			const cObj = data.find((item) => item.id === selectedItemForConfirmation.id);

			const lemlistId = cObj?.list?.lemlists?.at(0)?.id;

			param = [
				{
					listPersonId: selectedItemForConfirmation.id,
					prospectId: cObj?.prospect?.id,
					lemlistId: lemlistId,
				},
			];
		} else {
			param = selectedItems
				.map((id) => {
					const cObj = data.find((item) => item.id === id);

					const lemlistId = cObj?.list.lemlists.at(0)?.id;

					return {
						listPersonId: id,
						prospectId: cObj?.prospect?.id,
						lemlistId: lemlistId,
					};
				})
				.filter(Boolean) as Array<{ listPersonId: number; prospectId: number; lemlistId: number }>;
		}

		if (param.length > 0) {
			await bulkAssign({
				listId: id ? +id : 0,
				assign: param,
				assignStatus,
			}).unwrap();
		}

		handleOpenSnackbar(ts("bulkAssignRevertSuccess"));

		setSelectedItemForConfirmation(null);
	};

	const handleContextMenu = (status: string, row: ListPerson) => {
		setSelectedItemForConfirmation(row);
		switch (status) {
			case "confirmAssignment": {
				setShowConfirmModal(true);
				break;
			}

			case "reassign": {
				setShowReassignmentModal(true);

				break;
			}

			case "saveForLater": {
				setShowCheckLasterModal(true);

				break;
			}

			case "revert": {
				setShowRevertModal(true);

				break;
			}

			case "excludeFromList": {
				setShowExcludeFromListModal(true);

				break;
			}

			case "addToList": {
				setShowAddToListModal(true);

				break;
			}

			case "toBlackList": {
				setShowBlacklistModal(true);

				break;
			}

			case "reportRecord": {
				setShowReportRecordModal(true);
				break;
			}
		}
	};

	useEffect(() => {
		if (selectAll) {
			handleSelectAll(data?.map((item) => item.id) || []);
		} else {
			handleSelectAll([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAll, data]);

	const [previousColumnCount, setPreviousColumnCount] = useState(0);

	useEffect(() => {
		if (tableConfig?.length === 0) {
			updateTableConfig(tableColumns);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableConfig]);

	const updateTableConfig = useCallback(
		(columns: TableColumn[]) => {
			const newConfig = columns.map((c, index) => {
				return {
					uid: c.uid ?? "",
					id: +index + 1,
					show: typeof c.show !== "undefined" ? c.show : true,
					content: c.title,
					locked: !!c.locked || false,
					allowExclude: typeof c.allowExclude !== "undefined" ? c.allowExclude : true,
					custom: c.uid?.includes("custom-") || false,
					columnId: c.uid?.includes("custom-") ? +c.uid.split("-")[1] : undefined,
					moveable: c.moveable,
					hideable: c.hideable,
				};
			});

			if (JSON.stringify(newConfig) !== JSON.stringify(tableConfig)) {
				setTableConfig(newConfig);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setTableConfig, tableConfig]
	);

	const tableColumns: TableColumn[] = useMemo(() => {
		const getColumnsBasedOnListType = (listType?: ListSourceType) => {
			let columns: TableColumn[] = [];

			if (listType === ListSourceType.LI_EVENTS) {
				columns = LiEventTableColumns({
					selectAll,
					toggleSelectAll,
					selectedItems,
					handleSelectItem,
					handleCopyClick,
					handleContextMenu,
					ts,
					sortingInbox,
					setShowEdit,
					showEdit,
				});
			} else if (listType === ListSourceType.MAPS && data?.at(0)?.mapBusiness) {
				const parts = ["mapBusiness"];

				const checkManagingDirector =
					data?.at(0)?.mapBusiness?.map?.mapScrapeType === MapScrapeType.GET_MANAGING_DIRECTOR;

				if (checkManagingDirector) {
					parts.push("managingDirector");
				}

				const foundPersonInformation = data.find((d) => d.prospect);

				if (foundPersonInformation?.prospect?.id) {
					parts.push("prospect");
				}

				columns = GoogleMapsTableColumns({
					selectAll,
					toggleSelectAll,
					selectedItems,
					handleSelectItem,
					handleCopyClick,
					handleContextMenu,
					ts,
					parts,
					sortingInbox,
					setShowEdit,
					showEdit,
				});
			} else if (listType === ListSourceType.JOBS && data?.at(0)?.offer) {
				const parts = ["offer"];

				const checkManagingDirector =
					data?.at(0)?.offer?.job?.jobScrapeType === JobScrapeType.GET_MANAGING_DIRECTOR;

				if (checkManagingDirector) {
					parts.push("managingDirector");
				}

				const foundPersonInformation = data.find((d) => d.prospect);

				if (foundPersonInformation?.prospect?.id) {
					parts.push("prospect");
				}

				columns = JobTableColumns({
					parts,
					selectAll,
					toggleSelectAll,
					selectedItems,
					handleSelectItem,
					handleCopyClick,
					handleContextMenu,
					ts,
					sortingInbox,
					setShowEdit,
					showEdit,
				});
			} else if (listType && [ListSourceType.LI_PROFILE].includes(listType)) {
				columns = LiContentTableColumns({
					selectAll,
					toggleSelectAll,
					selectedItems,
					handleSelectItem,
					handleCopyClick,
					handleContextMenu,
					ts,
					sortingInbox,
					setShowEdit,
					showEdit,
				});
			} else if (listType && listType === ListSourceType.LI_POST) {
				columns = LiPostTableColumns({
					selectAll,
					toggleSelectAll,
					selectedItems,
					handleSelectItem,
					handleCopyClick,
					handleContextMenu,
					ts,
					sortingInbox,
					setShowEdit,
					showEdit,
				});
			} else if (listType === ListSourceType.LI_COMPANY_ACTIVITY) {
				columns = LiCompanyActivityTableColumns({
					selectAll,
					toggleSelectAll,
					selectedItems,
					handleSelectItem,
					handleCopyClick,
					handleContextMenu,
					ts,
					sortingInbox,
					setShowEdit,
					showEdit,
				});
			} else {
				columns = GeneralTableColumns({
					selectAll,
					toggleSelectAll,
					selectedItems,
					handleSelectItem,
					handleCopyClick,
					handleContextMenu,
					ts,
					sortingInbox,
					setShowEdit,
					showEdit,
				});
			}

			return columns;
		};

		let columns: TableColumn[] = getColumnsBasedOnListType(listType);

		if (data && data.length > 0) {
			for (const rec of data) {
				if (rec.score) {
					for (const score of rec.score) {
						const hasColumn = columns.find((c) => c.uid === `${score.id}-${score.name}`);

						if (!hasColumn) {
							const title = score.name;
							const width = "w-[150px]";
							const uid = `${score.id}-${score.name}`;
							const show = true;
							const moveable = true;
							const hideable = true;
							const orderTitle = `${score.id}-score`;

							const orderStatus = getOrderStatus(sortingInbox, orderTitle);

							const render = (row: ListPerson) => {
								const foundScore = row.score?.find((s) => s.id === score.id);

								return (
									<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
										<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
											<div>{foundScore?.scoreValue}</div>
										</Stack>
									</Stack>
								);
							};

							columns = [
								...columns.slice(0, 6),
								{ title, uid, orderTitle, width, render, moveable, hideable, show, orderStatus },
								...columns.slice(6),
							];
						}
					}
				}

				if (rec.list.columns) {
					for (const customColumn of rec.list.columns) {
						const hasColumn = columns.find((c) => c.uid === `custom-${customColumn.id}`);

						if (!hasColumn) {
							const type = customColumn.type as CustomColumnTypes;
							const title = customColumn.name;
							const width = "fit-content";
							const minWidth = "100px";
							const uid = `custom-${customColumn.id}`;
							const show = true;
							const moveable = true;
							const hideable = true;
							const orderTitle = `custom-${customColumn.id}`;

							const orderStatus = getOrderStatus(sortingInbox, orderTitle);

							const render = (row: ListPerson) => {
								const currentCustomColumn = row.list.columns?.find((c) => c.id === customColumn.id);

								const columnValue = row.columns?.find(
									(c) => c.columnConfigId === currentCustomColumn?.id
								);

								return (
									<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
										<Stack
											alignItems="flex-start"
											direction="column"
											justifyContent="flex-start"
											width="100%"
										>
											{(type === CustomColumnTypes.TEXT ||
												type === CustomColumnTypes.AI_PROMPT ||
												type === CustomColumnTypes.RANDOM_VALUE) &&
												currentCustomColumn && (
													<CustomTextColumn
														columnConfig={currentCustomColumn}
														columnValue={columnValue}
														customColumnTextValue={customColumnTextValue}
														row={row}
														setCustomColumnTextValue={setCustomColumnTextValue}
														setShowEdit={setShowEdit}
														showEdit={showEdit}
														type={type}
													/>
												)}
											{type === CustomColumnTypes.LINK && currentCustomColumn && (
												<CustomLinkColumn
													columnConfig={currentCustomColumn}
													columnValue={columnValue}
													customColumnTextValue={customColumnTextValue}
													row={row}
													setCustomColumnTextValue={setCustomColumnTextValue}
													setShowEdit={setShowEdit}
													showEdit={showEdit}
													type={type}
												/>
											)}
											{type === CustomColumnTypes.NUMBER && currentCustomColumn && (
												<CustomNumberColumn
													columnConfig={currentCustomColumn}
													columnValue={columnValue}
													customColumnNumberValue={customColumnNumberValue}
													row={row}
													setCustomColumnNumberValue={setCustomColumnNumberValue}
													setShowEdit={setShowEdit}
													showEdit={showEdit}
													type={type}
												/>
											)}

											{type === CustomColumnTypes.BOOLEAN && currentCustomColumn && (
												<CustomBooleanColumn
													columnConfig={currentCustomColumn}
													columnValue={columnValue}
													row={row}
													type={type}
												/>
											)}
										</Stack>
									</Stack>
								);
							};

							columns = [
								...columns,
								{
									title,
									uid,
									minWidth,
									width,
									render,
									orderTitle,
									show,
									moveable,
									hideable,
									orderStatus,
								},
							];
						}
					}
				}

				if (rec.aiEnrichmentResultOrg) {
					for (const aiEnrichmentColumn of rec.aiEnrichmentResultOrg) {
						if (!aiEnrichmentColumn.aiEnrichment?.name) {
							continue;
						}

						const hasColumn = columns.find(
							(c) => c.uid === `list-ai-${aiEnrichmentColumn.aiEnrichmentId}`
						);

						if (!hasColumn) {
							const title = aiEnrichmentColumn.aiEnrichment.name;
							const width = "fit-content";
							const minWidth = "100px";
							const uid = `list-ai-${aiEnrichmentColumn.aiEnrichmentId}`;
							const show = true;
							const moveable = true;
							const hideable = true;
							const orderTitle = `list-ai-${aiEnrichmentColumn.aiEnrichmentId}`;
							const orderStatus = getOrderStatus(sortingInbox, orderTitle);

							const render = (row: ListPerson) => {
								const correctAiEnrichmentColumn = row.aiEnrichmentResultOrg?.find(
									(a) => a.aiEnrichmentId === aiEnrichmentColumn.aiEnrichmentId
								);

								return (
									<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
										<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
											{correctAiEnrichmentColumn?.results}
										</Stack>
									</Stack>
								);
							};

							columns = [
								...columns,
								{
									title,
									uid,
									minWidth,
									width,
									render,
									orderTitle,
									orderStatus,
									show,
									moveable,
									hideable,
								},
							];
						}
					}
				}

				if (rec.aiEnrichmentResultPerson) {
					for (const aiEnrichmentColumn of rec.aiEnrichmentResultPerson) {
						if (!aiEnrichmentColumn.aiEnrichment?.name) {
							continue;
						}

						const hasColumn = columns.find(
							(c) => c.uid === `list-ai-${aiEnrichmentColumn.aiEnrichmentId}`
						);

						if (!hasColumn) {
							const title = aiEnrichmentColumn.aiEnrichment.name;
							const width = "fit-content";
							const minWidth = "100px";
							const uid = `list-ai-${aiEnrichmentColumn.aiEnrichmentId}`;
							const show = true;
							const moveable = true;
							const hideable = true;
							const orderTitle = `list-ai-${aiEnrichmentColumn.aiEnrichmentId}`;

							const render = (row: ListPerson) => {
								const correctAiEnrichmentColumn = row.aiEnrichmentResultPerson?.find(
									(a) => a.id === aiEnrichmentColumn.id
								);

								return (
									<Stack alignItems={"flex-start"} direction="row" justifyContent="flex-start">
										<Stack alignItems="flex-start" direction="column" justifyContent="flex-start">
											{correctAiEnrichmentColumn?.results}
										</Stack>
									</Stack>
								);
							};

							columns = [
								...columns,
								{ title, uid, minWidth, width, render, orderTitle, show, moveable, hideable },
							];
						}
					}
				}
			}
		}

		if (tableConfig?.length) {
			for (const c of columns) {
				const configIndex = tableConfig.findIndex((tc) => tc?.uid === c.uid);
				const currentIndex = columns.findIndex((tc) => tc?.uid === c.uid);

				if (configIndex > -1 && currentIndex > -1 && configIndex !== currentIndex) {
					const newConfigColumn = columns[currentIndex];

					columns.splice(currentIndex, 1);

					columns = [
						...columns.slice(0, configIndex),
						newConfigColumn,
						...columns.slice(configIndex),
					];
				}
			}

			columns = columns.map((c) => {
				const config = tableConfig.find((tc) => {
					return tc?.uid === c?.uid;
				});

				if (config) {
					c.show = config.show;
				}

				return c;
			});
		}

		return columns;
	}, [
		listType,
		data,
		tableConfig,
		selectAll,
		toggleSelectAll,
		selectedItems,
		handleSelectItem,
		handleCopyClick,
		ts,
		sortingInbox,
		customColumnTextValue,
		showEdit,
		customColumnNumberValue,
	]);

	useEffect(() => {
		if (data.length !== 0) {
			const currentColumnCount = data[0]?.list?.columns.length || 0;

			if (currentColumnCount !== previousColumnCount) {
				updateTableConfig(tableColumns);
				setPreviousColumnCount(currentColumnCount);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, previousColumnCount, updateTableConfig]);

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<div className="w-full overflow-auto">
			{isFetching && <LoadingOverlay />}
			<div className="flex items-start align-baseline justify-between w-full mb-2">
				<div className="flex-grow mr-4 max-w-[50%] w-[390px] md-w-[240px] ">
					<InputField
						handleChange={(e: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(e.target.value);
						}}
						icon={faSearch}
						name={"search"}
						placeholder="Search..."
						value={searchValue}
					/>
				</div>
				<div className="w-[fit-content] flex">
					<div className="w-[fit-content] mr-2">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							image={<Icon className="mr-2" icon={faColumns3} />}
							size={ButtonSize.L}
							title={ts("columns")}
							onClick={() => setShowSelectColumns(true)}
						/>
					</div>
					<div className="w-[fit-content] mr-2">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							image={<Icon className="mr-2" icon={faSliders} />}
							size={ButtonSize.L}
							title={`${ts("filter")}${
								filter && Object.keys(filter).length > 0 ? ` (${Object.keys(filter).length})` : ""
							}`}
							onClick={() => setShowFilterModal(true)}
						/>
					</div>

					<div className="w-[fit-content] mr-2">
						<Button
							color={ButtonColor.BRAND}
							disabled={assignStatus !== AssignStatus.DRAFT}
							image={<Icon className="mr-2" icon={faAdd} />}
							size={ButtonSize.L}
							title={ts("addColumn")}
							onClick={() => setShowNewColumn(true)}
						/>
					</div>
				</div>
			</div>
			<div
				className={classNames(
					"items-end align-baseline justify-start fixed bottom-12 z-50 bg-white w-full py-4",
					selectedItems.length > 0 ? "flex" : "hidden"
				)}
			>
				{assignStatus === AssignStatus.DRAFT && (
					<>
						<div className="w-[fit-content] mr-2">
							<Button
								className="w-[auto]"
								disabled={!selectedCampaign && selectedItems.length === 0}
								image={<Icon className="mr-2" icon={faCheck} />}
								size={ButtonSize.ML}
								title={ts("confirmAssignment")}
								onClick={() => setShowConfirmModal(true)}
							/>
						</div>
						<div className="w-[fit-content] mr-2">
							<Button
								color={ButtonColor.ACTION_SECONDARY}
								disabled={!selectedCampaign && selectedItems.length === 0}
								image={
									<SvgIcon className="w-[20px] h-[20px] text-brand-black mr-1" svgIcon={Reassign} />
								}
								size={ButtonSize.ML}
								title={ts("reassign")}
								onClick={() => setShowReassignmentModal(true)}
							/>
						</div>
						<div className="w-[fit-content] mr-2">
							<Button
								color={ButtonColor.ACTION_SECONDARY}
								disabled={selectedItems.length === 0}
								image={
									<SvgIcon
										className="w-[20px] h-[20px] mr-2 text-brand-black mr-1"
										svgIcon={Exclude}
									/>
								}
								size={ButtonSize.ML}
								title={ts("excludeFromList")}
								onClick={() => setShowExcludeFromListModal(true)}
							/>
						</div>
					</>
				)}

				{assignStatus !== AssignStatus.NOT_NOW && (
					<div className="w-[fit-content] mr-2">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							disabled={!selectedCampaign && selectedItems.length === 0}
							image={
								<SvgIcon className="w-[20px] h-[20px] text-brand-black mr-1" svgIcon={Clock} />
							}
							size={ButtonSize.ML}
							title={ts("checkLater")}
							onClick={() => setShowCheckLasterModal(true)}
						/>
					</div>
				)}

				{assignStatus && [AssignStatus.BLACKLIST, AssignStatus.NOT_NOW].includes(assignStatus) && (
					<>
						<div className="w-[fit-content] mr-2">
							<Button
								color={ButtonColor.ACTION_SECONDARY}
								image={
									<SvgIcon className="w-[20px] h-[20px] text-brand-black mr-1" svgIcon={Clock} />
								}
								size={ButtonSize.ML}
								title={ts("revert")}
								onClick={() => setShowRevertModal(true)}
							/>
						</div>
						<div className="w-[fit-content] mr-2">
							<Button
								color={ButtonColor.ACTION_SECONDARY}
								disabled={!selectedCampaign && selectedItems.length === 0}
								image={
									<SvgIcon className="w-[20px] h-[20px] text-brand-black mr-1" svgIcon={Reassign} />
								}
								size={ButtonSize.ML}
								title={ts("reassign")}
								onClick={() => setShowReassignmentModal(true)}
							/>
						</div>
						<div className="w-[fit-content] mr-2">
							<Button
								color={ButtonColor.ACTION_SECONDARY}
								disabled={selectedItems.length === 0}
								image={
									<SvgIcon
										className="w-[20px] h-[20px] mr-2 text-brand-black mr-1"
										svgIcon={Exclude}
									/>
								}
								size={ButtonSize.ML}
								title={ts("excludeFromList")}
								onClick={() => setShowExcludeFromListModal(true)}
							/>
						</div>
					</>
				)}

				{assignStatus !== AssignStatus.BLACKLIST && (
					<div className="w-[fit-content] mr-2">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							disabled={!selectedCampaign && selectedItems.length === 0}
							image={
								<SvgIcon
									className="w-[20px] h-[20px] text-brand-black mr-1"
									svgIcon={CrossedCircle}
								/>
							}
							size={ButtonSize.ML}
							title={ts("toBlackList")}
							onClick={() => setShowBlacklistModal(true)}
						/>
					</div>
				)}
			</div>
			{showReassignmentModal && lemlists && (
				<Modal
					handleClose={() => setShowReassignmentModal(false)}
					handleSave={async () => {
						await handleBulkReassign();
						selectAll && toggleSelectAll();
						setShowReassignmentModal(false);
					}}
					isOpened={showReassignmentModal}
					size="md"
					submitButtonText={t("basics.confirm")}
					title={ts("reassign")}
				>
					<Radio
						className="mb-4"
						isChecked={!personAssignment}
						onChange={() => {
							setPersonAssignment(false);
						}}
					>
						{ts("campaignAssignment")}
					</Radio>

					<div className="mb-4 flex flex-row items-center">
						{lemlists && !personAssignment ? (
							<AutoComplete
								data={
									lemlists?.data?.map((c) => {
										return { id: c.id, title: c.name } as AutoCompleteItem;
									}) || []
								}
								handleSearch={(value: string) => setSearchLemlist(value)}
								handleSelect={(value?: AutoCompleteItem) => setSelectedCampaign(value)}
								icon={faMegaphone}
								label={ts("campaign")}
								selectedItem={selectedCampaign?.title ?? ""}
							/>
						) : (
							""
						)}
					</div>

					<Radio
						isChecked={personAssignment}
						onChange={() => {
							setPersonAssignment(true);
						}}
					>
						{ts("personAssignment")}
					</Radio>
					<div className="mt-4 flex flex-row items-center">
						{users && personAssignment ? (
							<AutoComplete
								data={
									users?.data?.map((c) => {
										return {
											id: c.id,
											title: `${c.firstName}  ${c.lastName}`,
											icon: (
												<span className="block w-[26px] h-[26px] text-xs text-center text-white rounded-full bg-brand-light p-1">
													{c.firstName[0]}
													{c.lastName[0]}
												</span>
											),
										} as AutoCompleteItem;
									}) || []
								}
								handleSearch={(value: string) => setSearchUser(value)}
								handleSelect={(value?: AutoCompleteItem) => setSelectedUserToAssign(value)}
								icon={faUsers}
								label={ts("person")}
								selectedItem={selectedUserToAssign?.title ?? ""}
							/>
						) : (
							""
						)}
					</div>
				</Modal>
			)}
			{showSelectColumns && tableConfig && setShowSelectColumns && (
				<ColumnModal
					setEditableColumn={setEditableColumn}
					setShowDeleteColumn={setShowDeleteColumn}
					setShowEditColumn={setShowEditColumn}
					setShowSelectColumns={setShowSelectColumns}
					setTableConfig={setTableConfig}
					showSelectColumns={showSelectColumns}
					tableConfig={tableConfig}
				/>
			)}
			{showConfirmModal && (
				<Modal
					handleClose={() => setShowConfirmModal(false)}
					handleSave={async () => {
						await handleBulkAssign();
						selectAll && toggleSelectAll();
						setShowConfirmModal(false);
					}}
					isOpened={showConfirmModal}
					size="xs"
					submitButtonText={t("basics.confirm")}
					title={ts("confirmAssignment")}
				>
					{selectedItemForConfirmation ? (
						<>
							{ts("confirmAssignment")} for {selectedItemForConfirmation?.prospect?.name}?
						</>
					) : (
						<>
							{ts("confirmAssignment")} for all {selectedItems.length} selected items?
						</>
					)}
				</Modal>
			)}
			{showBlacklistModal && (
				<Modal
					handleClose={() => setShowBlacklistModal(false)}
					handleSave={async () => {
						await handleBulkAddToBlacklist();
						selectAll && toggleSelectAll();
						setShowBlacklistModal(false);
					}}
					isOpened={showBlacklistModal}
					size="xs"
					submitButtonColor={ButtonColor.DANGER}
					submitButtonText={ts("toBlackList")}
					title={ts("addingToBlacklist")}
				>
					{selectedItemForConfirmation ? (
						<>{ts("addingToBlacklistConfirmationMessage")}</>
					) : (
						<>
							Are you sure you want to add {selectedItems.length} selected items to the Blacklist?
							This action is permanent and cannot be undone. Please specify if you want to add only
							people to the blacklist or their entire companies.
						</>
					)}

					<div className="mt-4 flex flex-row items-center">
						<Toggle
							isChecked={addEntireCompanyToBlacklist}
							onChange={() => {
								setAddEntireCompanyToBlacklist(!addEntireCompanyToBlacklist);
							}}
						/>
						<span className="ml-2 text-ssm text-gray-700 ">
							{ts("addEntireCompanyToBlacklist")}
						</span>
					</div>
				</Modal>
			)}
			{showCheckLasterModal && (
				<Modal
					handleClose={() => setShowCheckLasterModal(false)}
					handleSave={async () => {
						await handleBulkStatusChange({ assignStatus: AssignStatus.NOT_NOW });
						selectAll && toggleSelectAll();
						setShowCheckLasterModal(false);
					}}
					isOpened={showCheckLasterModal}
					size="xs"
					submitButtonText={ts("saveForLater")}
					title={ts("saveForLater")}
				>
					{selectedItemForConfirmation ? (
						<>
							{ts("saveForLaterConfirmationMessage")} {selectedItemForConfirmation?.prospect?.name}
						</>
					) : (
						<>Add {selectedItems.length} selected items to check later?</>
					)}
				</Modal>
			)}
			{showExcludeFromListModal && (
				<Modal
					handleClose={() => setShowExcludeFromListModal(false)}
					handleSave={async () => {
						await handleBulkStatusChange({ inclusionStatus: InclusionStatus.MANUALLY_EXCLUDED });
						selectAll && toggleSelectAll();
						setShowExcludeFromListModal(false);
					}}
					isOpened={showExcludeFromListModal}
					size="xs"
					submitButtonColor={ButtonColor.DANGER}
					submitButtonText={ts("excludeFromList")}
					title={ts("excludeFromList")}
				>
					{selectedItemForConfirmation ? (
						<>{ts("excludeFromListConfirmationMessage")}</>
					) : (
						<>Exclude {selectedItems.length} selected items from the list?</>
					)}
				</Modal>
			)}
			{showAddToListModal && (
				<Modal
					handleClose={() => setShowAddToListModal(false)}
					handleSave={async () => {
						await handleBulkStatusChange({ inclusionStatus: InclusionStatus.ADDED });
						selectAll && toggleSelectAll();
						setShowAddToListModal(false);
					}}
					isOpened={showAddToListModal}
					size="xs"
					submitButtonText={ts("addToList")}
					title={ts("addToList")}
				>
					{selectedItemForConfirmation ? (
						<>{ts("addToListConfirmationMessage")}</>
					) : (
						<>Add {selectedItems.length} selected items to the list?</>
					)}
				</Modal>
			)}
			{showRevertModal && (
				<Modal
					handleClose={() => setShowRevertModal(false)}
					handleSave={async () => {
						await handleBulkRevertAssign(AssignStatus.DRAFT);
						selectAll && toggleSelectAll();
						setShowRevertModal(false);
					}}
					isOpened={showRevertModal}
					size="xs"
					submitButtonText={t("basics.confirm")}
					title={ts("confirmRevertAssignment")}
				>
					{selectedItemForConfirmation ? (
						<>
							{ts("revertAssignment")} for {selectedItemForConfirmation?.prospect?.name}?
						</>
					) : (
						<>
							{ts("revertAssignment")} for all {selectedItems.length} selected items?
						</>
					)}
				</Modal>
			)}
			{showNewColumn && id && (
				<CreateCustomColumnModal listId={+id} open={showNewColumn} setOpen={setShowNewColumn} />
			)}
			{showDeleteColumn && id && editableColumn?.columnId && (
				<Modal
					handleClose={() => setShowDeleteColumn(false)}
					handleSave={handleDeleteColumn}
					isLoading={deleteLoading}
					isOpened={showDeleteColumn}
					submitButtonColor={ButtonColor.DANGER}
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<div className="text-sm text-gray-700 mb-4">{ts("delete.confirmation")}</div>
					<TextField
						fullWidth
						defaultValue={editableColumn.content}
						inputProps={{ readOnly: true }}
						label={ts("name")}
					/>
				</Modal>
			)}
			{showReportRecordModal && (
				<Modal
					handleClose={() => setShowReportRecordModal(false)}
					handleSave={() => {
						handleReportReason();
						setShowReportRecordModal(false);
					}}
					isOpened={showReportRecordModal}
					size="xs"
					submitButtonText={ts("reportRecord")}
					title={ts("reportRecord")}
				>
					<div className="flex flex-col">
						<span className="mb-4 text-ssm text-gray-700 ">{ts("specifyReason")}</span>
						<TextArea
							handleChange={(e) => setReportReason(e.target.value)}
							name="reportReason"
							placeholder={ts("specifyProblem")}
							showError={false}
							value={reportReason}
						/>
					</div>
				</Modal>
			)}

			{showEditColumn && id && editableColumn?.columnId && (
				<EditCustomColumnModalWrapper
					columnId={editableColumn?.columnId}
					listId={+id}
					open={showEditColumn}
					setOpen={setShowEditColumn}
				/>
			)}
			{showFilterModal && id && (
				<FilterModal
					filter={filter}
					inboxData={data}
					listId={+id}
					listType={listType}
					setFilter={(e) => {
						setPage(1);
						setFilter(e);
					}}
					setShowFilterModal={setShowFilterModal}
					showFilterModal={showFilterModal}
				/>
			)}
			<div className="relative w-full pr-0">
				<div className="pr-4 pb-[20px]">
					<Table
						columns={tableColumns}
						data={data || []}
						filter={filter}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						handleFetchPage={(page) => {
							if (selectAll) {
								toggleSelectAll();
							}

							setPage(page);
						}}
						handleSorting={(sortParams: SortParams) => {
							setPage(1);
							handleSorting(sortParams);
						}}
						hidePagination={false}
						isLoading={isFetching}
						limit={limit}
						page={page}
						totalPages={meta?.pageCount}
					/>
				</div>
			</div>
			{renderSnackbar()}
			{renderMessageSnackbar()}
		</div>
	);
};
