import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "@/components";

import {
	useCreateLiPostMutation,
	useUpdateLiPostMutation,
} from "@/pages/Private/redux/liPost/liPost.api";

import { ERROR_TYPE, getAllErrors, renderErrorMessages, useSearch } from "@/utils";

import { LiPostSort, TimeType } from "@/enum/li-post.enum";

import {
	LinkedinPersonSuggestion,
	LinkedinPersonSuggestionItem,
} from "@/components/LinkedinPersonSuggestion/LinkedinPersonSuggestion";

import {
	useLazyGetCompanySuggestionQuery,
	useLazyGetPersonSuggestionQuery,
} from "@/pages/Private/redux/liPeople/liPeople.api";

import {
	LinkedinOrganizationSuggestion,
	LinkedinOrganizationSuggestionItem,
} from "@/components/LinkedinOrganizationSuggestion/LinkedinOrganizationSuggestion";

import { Button } from "@/components/Button/Button";

import { ButtonColor } from "@/components/Button/types";

import { InputField } from "@/components/InputField/InputField";

import { CreateLiPost, CreateLiPostSchema, LiPost } from "../schema/liPost";

import { LinkedinTagAutocompleteSingle, LinkedinParams } from "./LinkedinTagAutocomplete";
import { List } from "../../List/schema/list";

interface CreateLiPostFormProps {
	liPost?: LiPost | null;
	list: List;
	handleSelect?: (company: LiPost | null) => void;
}

const initialState = {
	name: "",
	query: "",
	repeatDaily: false,
	sortBy: LiPostSort.DATE_POSTED,
};

export const CreateLiPostForm: FunctionComponent<CreateLiPostFormProps> = ({
	liPost,
	list,
	handleSelect,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`liPost.${key}`);
	const [createLiPost, { error, isLoading }] = useCreateLiPostMutation();
	const [updateLiPost, { error: updateError, isLoading: updateIsLoading }] =
		useUpdateLiPostMutation();
	const { query, setSearchValue } = useSearch();
	const [personSuggestions, setPersonSuggestions] = useState<LinkedinPersonSuggestionItem[]>();
	const [organizationSuggestions, setOrganizationSuggestions] =
		useState<LinkedinPersonSuggestionItem[]>();
	const [additionalColumns, setAdditionalColumns] = useState<boolean>(false);

	const [fetchPeople] = useLazyGetPersonSuggestionQuery();

	const [fetchCompanies] = useLazyGetCompanySuggestionQuery();

	useEffect(() => {
		if (query) {
			fetchCompanies({ keyword: query }).then((results) => {
				const optionValues = results?.data?.suggestions;

				if (optionValues) {
					setOrganizationSuggestions([...optionValues]);
				}
			});
		}
	}, [fetchCompanies, query]);

	useEffect(() => {
		if (query) {
			fetchPeople({ keyword: query }).then((results) => {
				const optionValues = results?.data?.suggestions;

				if (optionValues) {
					setPersonSuggestions([...optionValues]);
				}
			});
		}
	}, [fetchPeople, query]);

	const [currentFormState, setCurrentFormState] = useState<CreateLiPost>(initialState);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof CreateLiPost) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const {
		handleSubmit,
		setValue,
		getValues,
		reset,
		formState: { errors },
	} = useForm<CreateLiPost>({
		defaultValues: initialState,
		resolver: zodResolver(CreateLiPostSchema),
	});

	console.error(errors);

	useEffect(() => {
		if (liPost) {
			if (
				liPost.fromMember ||
				liPost.fromOrganization ||
				liPost.authorCompany ||
				liPost.authorIndustry ||
				liPost.mentionsMember ||
				liPost.mentionsOrganization
			) {
				setAdditionalColumns(true);
			}

			const newInitialState = {
				id: liPost.id,
				name: liPost.name,
				query: liPost.query,
				repeatDaily: liPost.repeatDaily || false,
				sortBy: liPost.sortBy || LiPostSort.DATE_POSTED,
				authorJobTitle: liPost.authorJobTitle ?? "",
				contentType: liPost.contentType ?? null,
				fromMember: liPost.fromMember ?? undefined,
				fromOrganization: liPost.fromOrganization ?? undefined,
				authorCompany: liPost.authorCompany ?? undefined,
				authorIndustry: liPost.authorIndustry ?? undefined,
				mentionsMember: liPost.mentionsMember ?? undefined,
				mentionsOrganization: liPost.mentionsOrganization ?? undefined,
				timeType: TimeType.PERIOD,
				timeFrame: liPost.timeFrame || 30,
				timeStart: liPost.timeStart ? new Date(liPost.timeStart) : undefined,
				timeEnd: liPost.timeEnd ? new Date(liPost.timeEnd) : undefined,
			};

			reset(newInitialState);

			setCurrentFormState(newInitialState);
		} else {
			reset();
		}
	}, [list.scrapeLevel, liPost, reset]);

	const onSubmit = async (values: CreateLiPost) => {
		try {
			if (liPost?.id) {
				await updateLiPost({
					...values,
					id: liPost.id,
					listId: list.id,
				}).unwrap();
			} else {
				await createLiPost({
					...values,
					listId: list.id,
				}).unwrap();
			}

			reset({
				name: "",
				query: "",
				repeatDaily: false,
				sortBy: LiPostSort.DATE_POSTED,
			});

			handleSelect?.(null);

			setAdditionalColumns(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<div>
			<div className="text-md mb-5">Sources</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.name?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "name");
					}}
					label={ts("name")}
					name={"name"}
					placeholder={ts("namePlaceholder")}
					value={currentFormState?.name || ""}
				/>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.query?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "query");
					}}
					label={ts("query")}
					name={"query"}
					placeholder={ts("queryPlaceholder")}
					value={currentFormState?.query || ""}
				/>
			</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					error={!!errors.authorJobTitle?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "authorJobTitle");
					}}
					label={ts("authorJobTitle")}
					name={"authorJobTitle"}
					placeholder={ts("authorJobTitlePlaceholder")}
					value={currentFormState?.authorJobTitle || ""}
				/>
			</div>

			{/* <div className="flex flex-col gap-0 mb-1 py-0">
				<div className="relative flex items-center justify-between mb-2">
					<label
						className={classNames("block text-sm font-medium text-gray-900 appearance-none")}
						htmlFor="timeFrame"
					>
						{ts("timeFrame")}
					</label>
				</div>
				<div className="flex flex-row gap-4 mb-1 pb-4">
					<Radio
						isChecked={getValues("timeType") === TimeType.PERIOD}
						onChange={() => {
							setValue("timeType", TimeType.PERIOD);
						}}
					>
						{ts("timeFrame")}
					</Radio>

					<Radio
						isChecked={getValues("timeType") === TimeType.FROM_TO}
						onChange={() => {
							setValue("timeType", TimeType.FROM_TO);
						}}
					>
						{ts("fromTo")}
					</Radio>
				</div>
			</div> */}

			<div className="flex flex-row gap-4 mb-4 py-4">
				<InputField
					error={!!errors.timeFrame?.message}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(+event.target.value, "timeFrame");
					}}
					label={ts("timeFrame")}
					name={"timeFrame"}
					placeholder={ts("timeFrame")}
					value={currentFormState?.timeFrame || ""}
				/>
			</div>

			<button
				className="text-ssm mb-4"
				onClick={() => {
					setAdditionalColumns(!additionalColumns);
				}}
			>
				Additional Filters <Icon icon={additionalColumns ? faChevronUp : faChevronDown} />
			</button>
			{/* <div className="flex flex-row gap-4 w-full mb-4">
				<Dropdown
					data={[
						{
							title: "None",
							id: null,
						},
						{
							title: ts(LiPostContentType.PHOTOS),
							id: LiPostContentType.PHOTOS,
						},
						{
							title: ts(LiPostContentType.VIDEOS),
							id: LiPostContentType.VIDEOS,
						},
						{
							title: ts(LiPostContentType.LIVE_VIDEOS),
							id: LiPostContentType.LIVE_VIDEOS,
						},
						{
							title: ts(LiPostContentType.COLLABORATIVE_ARTICLES),
							id: LiPostContentType.COLLABORATIVE_ARTICLES,
						},
						{
							title: ts(LiPostContentType.DOCUMENTS),
							id: LiPostContentType.DOCUMENTS,
						},
					]}
					floating={false}
					handleSelect={function (value?: AutoCompleteItem) {
						if (value?.id) {
							setValue("contentType", value.id as LiPostContentType);
						}
					}}
					label={ts("ContentType")}
				/>
				
			</div> */}

			{additionalColumns && (
				<>
					<div className="flex flex-col w-full mb-4">
						<label className="text-sm mb-2">{ts("fromMember")}</label>
						<LinkedinPersonSuggestion
							data={personSuggestions || []}
							floating={true}
							handleSearch={(val) => setSearchValue(val)}
							handleSelect={function (value?: LinkedinPersonSuggestionItem | undefined): void {
								if (value) {
									setValue("fromMember", value, {
										shouldDirty: true,
										shouldValidate: true,
									});

									setPersonSuggestions([]);
									setSearchValue("");
								} else {
									setValue("fromMember", null, {
										shouldDirty: true,
										shouldValidate: true,
									});
								}
							}}
							selectedItem={(getValues("fromMember") as LinkedinPersonSuggestionItem) || undefined}
						/>
					</div>
					<div className="flex flex-col w-full mb-4">
						<label className="text-sm mb-2">{ts("fromOrganization")}</label>
						<LinkedinOrganizationSuggestion
							data={organizationSuggestions || []}
							floating={true}
							handleSearch={(val) => setSearchValue(val)}
							handleSelect={function (
								value?: LinkedinOrganizationSuggestionItem | undefined
							): void {
								if (value) {
									setValue("fromOrganization", value, {
										shouldDirty: true,
										shouldValidate: true,
									});

									setOrganizationSuggestions([]);
									setSearchValue("");
								} else {
									setValue("fromOrganization", null, {
										shouldDirty: true,
										shouldValidate: true,
									});
								}
							}}
							selectedItem={
								(getValues("fromOrganization") as LinkedinOrganizationSuggestionItem) || undefined
							}
						/>
					</div>
					<div className="flex flex-col w-full mb-4">
						<label className="text-sm mb-2">{ts("authorCompany")}</label>
						<LinkedinOrganizationSuggestion
							data={organizationSuggestions || []}
							floating={true}
							handleSearch={(val) => setSearchValue(val)}
							handleSelect={function (
								value?: LinkedinOrganizationSuggestionItem | undefined
							): void {
								if (value) {
									setValue("authorCompany", value, {
										shouldDirty: true,
										shouldValidate: true,
									});

									setOrganizationSuggestions([]);
									setSearchValue("");
								} else {
									setValue("authorCompany", null, {
										shouldDirty: true,
										shouldValidate: true,
									});
								}
							}}
							selectedItem={
								(getValues("authorCompany") as LinkedinOrganizationSuggestionItem) || undefined
							}
						/>
					</div>
					<div className="flex flex-col w-full mb-1">
						<label className="text-sm mb-2">{ts("authorIndustry")}</label>
						<LinkedinTagAutocompleteSingle
							handleValueChange={(value: LinkedinParams | null) => {
								if (value) {
									setValue("authorIndustry", value, {
										shouldDirty: true,
										shouldValidate: true,
									});
								} else {
									setValue("authorIndustry", null);
								}
							}}
							type={"industry"}
							value={getValues("authorIndustry") || null}
						/>
					</div>

					<div className="flex flex-col w-full mb-4">
						<label className="text-sm mb-2">{ts("mentionsMember")}</label>
						<LinkedinPersonSuggestion
							data={personSuggestions || []}
							floating={true}
							handleSearch={(val) => setSearchValue(val)}
							handleSelect={function (value?: LinkedinPersonSuggestionItem | undefined): void {
								if (value) {
									setValue("mentionsMember", value, {
										shouldDirty: true,
										shouldValidate: true,
									});

									setPersonSuggestions([]);
									setSearchValue("");
								} else {
									setValue("mentionsMember", null, {
										shouldDirty: true,
										shouldValidate: true,
									});
								}
							}}
							selectedItem={
								(getValues("mentionsMember") as LinkedinPersonSuggestionItem) || undefined
							}
						/>
					</div>
					<div className="flex flex-col w-full mb-4">
						<label className="text-sm mb-2">{ts("mentionsOrganization")}</label>
						<LinkedinOrganizationSuggestion
							data={organizationSuggestions || []}
							floating={true}
							handleSearch={(val) => setSearchValue(val)}
							handleSelect={function (
								value?: LinkedinOrganizationSuggestionItem | undefined
							): void {
								if (value) {
									setValue("mentionsOrganization", value, {
										shouldDirty: true,
										shouldValidate: true,
									});

									setOrganizationSuggestions([]);
									setSearchValue("");
								} else {
									setValue("mentionsOrganization", null, {
										shouldDirty: true,
										shouldValidate: true,
									});
								}
							}}
							selectedItem={
								(getValues("mentionsOrganization") as LinkedinOrganizationSuggestionItem) ||
								undefined
							}
						/>
					</div>
				</>
			)}

			<div className="flex flex-row gap-4 w-full mb-4">
				{getAllErrors(error || updateError, formErrors).length
					? renderErrorMessages(getAllErrors(error || updateError, formErrors))
					: null}
			</div>
			<div className="flex gap-4 w-full justify-end">
				{handleSelect && (
					<div className="flex max-w-[100px] w-full">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							title="cancel"
							onClick={() => {
								reset(initialState);

								setCurrentFormState(initialState);

								handleSelect?.(null);
								setAdditionalColumns(false);
							}}
						/>
					</div>
				)}

				<div className="flex max-w-[150px] w-full">
					<Button
						isLoading={isLoading || updateIsLoading}
						title={liPost?.id ? "Save" : "Add criteria"}
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</div>
		</div>
	);
};
