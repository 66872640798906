import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { Button } from "@/components/Button/Button";

import { InputField } from "@/components/InputField/InputField";

import { ButtonColor } from "@/components/Button/types";

import { EmploymentType, JobScrapeType } from "@/enum/job.enum";
import { useCreateJobMutation, useUpdateJobMutation } from "@/pages/Private/redux/job/job.api";
import { Dropdown, AutoCompleteItem } from "@/components/Dropdown/Dropdown";

import { List } from "../../List/schema/list";
import { CreateJob, CreateJobSchema, Job } from "../schema/job";

interface CreateJobFormProps {
	job?: Job | null;
	list: List;
	handleSelect?: (job: Job | null) => void;
}

export const CreateJobForm: FunctionComponent<CreateJobFormProps> = ({
	job,
	list,
	handleSelect,
}) => {
	const initialState = {
		name: "",
		query: "",
		employmentType: EmploymentType.FULLTIME,
		jobScrapeType: JobScrapeType.GET_INFO_AT,
		repeatDaily: false,
	};
	const { t } = useTranslation();
	const ts = (key: string) => t(`job.${key}`);

	const [employmnetType, setEmploymentType] = useState<EmploymentType | null>(
		job?.employmentType || null
	);

	const [update] = useUpdateJobMutation();
	const [createJob, { error, isLoading }] = useCreateJobMutation();

	const [currentFormState, setCurrentFormState] = useState<CreateJob>(initialState);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSaveFormState = (value: any, name: keyof CreateJob) => {
		setCurrentFormState({
			...currentFormState,
			[name]: value,
		});

		setValue(name, value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const {
		reset,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<CreateJob>({
		defaultValues: initialState,
		resolver: zodResolver(CreateJobSchema),
	});

	useEffect(() => {
		if (job) {
			reset({
				id: job.id,
				name: job.name,
				query: job.query,
				employmentType: job.employmentType,
				jobScrapeType: job.jobScrapeType,
				repeatDaily: false,
			});

			setEmploymentType(job.employmentType);

			setCurrentFormState({
				id: job.id,
				name: job.name,
				query: job.query,
				employmentType: job.employmentType,
				jobScrapeType: job.jobScrapeType,
				repeatDaily: false,
			});
		} else {
			reset();
			setEmploymentType(null);
		}
	}, [list.scrapeLevel, job, reset]);

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	useEffect(() => {
		if (list.scrapeLevel) {
			setValue("jobScrapeType", list.scrapeLevel as JobScrapeType);
			setValue("repeatDaily", false);
		}
	}, [list.scrapeLevel, setValue]);

	const onSubmit = async (values: CreateJob) => {
		try {
			if (values.id) {
				await update({ id: values.id, ...values, listId: list.id }).unwrap();
			} else {
				await createJob({ ...values, listId: list.id }).unwrap();
			}

			handleSelect?.(null);

			reset();
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<div className="">
			<div className="text-md mb-5">Sources</div>
			<div className="flex flex-row gap-4 w-full mb-4">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						handleSaveFormState(event.target.value, "query");
					}}
					label={ts("query")}
					name={"query"}
					placeholder={ts("queryPlaceholder")}
					value={currentFormState.query}
				/>
			</div>

			<div className="flex flex-row gap-4 w-full mb-4">
				<Dropdown
					data={[
						{
							title: ts(EmploymentType.FULLTIME),
							id: EmploymentType.FULLTIME,
						},
						{
							title: ts(EmploymentType.PARTTIME),
							id: EmploymentType.PARTTIME,
						},
						{
							title: ts(EmploymentType.INTERN),
							id: EmploymentType.INTERN,
						},
						{
							title: ts(EmploymentType.CONTRACTOR),
							id: EmploymentType.CONTRACTOR,
						},
					]}
					floating={true}
					handleSelect={(value: AutoCompleteItem) => {
						setEmploymentType(value.id as EmploymentType);
					}}
					label={ts("employmentType")}
					value={
						employmnetType
							? {
									id: employmnetType,
									title: ts(employmnetType),
							  }
							: undefined
					}
				/>
			</div>

			{getAllErrors(error, formErrors).length
				? renderErrorMessages(getAllErrors(error, formErrors))
				: null}

			<div className="flex gap-4 w-full justify-end">
				{handleSelect && (
					<div className="flex max-w-[100px] w-full">
						<Button
							color={ButtonColor.ACTION_SECONDARY}
							title="cancel"
							onClick={() => {
								handleSelect(null);
								reset(initialState);
								setCurrentFormState(initialState);
								setEmploymentType(EmploymentType.FULLTIME);
							}}
						/>
					</div>
				)}

				<div className="flex max-w-[150px] w-full">
					<Button
						isLoading={isLoading}
						title={job?.id ? "Save" : "Add criteria"}
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</div>
		</div>
	);
};
