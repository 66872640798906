import { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";

import { faMagnifyingGlass, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { Breadcrumb } from "@/components/Breadcrump/Breadcrump";

import { Button } from "@/components/Button/Button";
import { useGetListQuery } from "@/pages/Private/redux/list/list.api";

import { Icon, LoadingOverlay } from "@/components";

import { ButtonColor } from "@/components/Button/types";

import { ListSourceType } from "@/enum/list.enum";

import { List } from "../../schema/list";
import { getAvailableSteps, isDetailsNeeded, getDoneSteps } from "../../utils/create-list-steps";
import { CreateMapForm } from "../../../Map/components/CreateMapForm";
import { AddedSourceList } from "../../components/AddedSourceList";
import { Map } from "../../../Map/schema/map";
import { Job } from "../../../Job/schema/job";
import { CreateJobForm } from "../../../Job/components/CreateJobForm";
import { CreateCsvEnrichmentForm } from "../../../CsvEnrichment/components/CreateCsvEnrichmentForm";
import { CsvEnrichment } from "../../../CsvEnrichment/schema/csvEnrichment";
import { CreateLiEventForm } from "../../../LiEvent/components/CreateLiEventForm";
import { LiEvent } from "../../../LiEvent/schema/liEvent";
import { CreateLiCompanyActivityForm } from "../../../LiCompanyActivity/components/CreateLiCompanyActivityForm";
import { LiCompanyActivity } from "../../../LiCompanyActivity/schema/liCompanyActivity";
import { CreateLiPostForm } from "../../../LiPost/components/CreateLiPostForm";
import { LiPost } from "../../../LiPost/schema/liPost";
import { Stepper } from "../ListEditDetails/components/Stepper";
import { SelectableSourceList } from "../../components/SelectableSourceList";
import { LiProfileVisits } from "../../../LiProfileVisits/schema/liProfileVisits";
import { LiProfile } from "../../../LiProfile/schema/liProfile";
import { CreateLiProfileForm } from "../../../LiProfile/components/CreateLiProfileForm";
import { LiPeople } from "../../../LiPeople/schema/liPeople";
import { CreateLiPeopleForm } from "../../../LiPeople/components/CreateLiPeopleForm";

export const ListEditSource: FunctionComponent = () => {
	const { t } = useTranslation();
	const { id = 0 } = useParams();

	const navigate = useNavigate();
	const ts = (key: string) => t(`list.${key}`);

	const { data, isFetching } = useGetListQuery(+id);

	const [selected, setSelected] = useState<
		| Map
		| Job
		| CsvEnrichment
		| LiEvent
		| LiCompanyActivity
		| LiPost
		| LiProfileVisits
		| LiPeople
		| null
	>(null);

	const [switchSelectOrCreate, setSwitchSelectOrCreate] = useState<boolean>(false);

	const amountOfSources = useMemo(() => {
		if (!data) {
			return 0;
		}

		return (
			data.maps.length +
			data.jobs.length +
			data.liCompanyActivity.length +
			data.csvEnrichments.length +
			data.liEvents.length +
			data.liPeople.length +
			data.liPosts.length +
			data.liProfile.length +
			data.liProfileVisits.length
		);
	}, [data]);

	const submit = async () => {
		navigate(`/app/lists/create/filter/${id}`);
	};

	const CreateOrAddButton = (
		<button
			className="absolute right-0"
			onClick={() => setSwitchSelectOrCreate(!switchSelectOrCreate)}
		>
			{switchSelectOrCreate ? (
				<>
					<Icon icon={faPlus} /> Create
				</>
			) : (
				<>
					<Icon icon={faMagnifyingGlass} /> Add
				</>
			)}
		</button>
	);

	return isFetching || !data ? (
		<LoadingOverlay />
	) : (
		<div className="sm:block flex flex-col items-start justify-between mb-6 pb-8">
			<Breadcrumb
				path={[
					{ name: ts("title"), link: "/" },
					{ name: ts("create.title"), link: "/create" },
					{ name: data?.name || "", link: `/create/${id}/details` },
				]}
			/>
			<div className="text-lg leading-9 mb-5">Create {ts(data.sourceType)} List</div>
			<Stepper
				availableSteps={getAvailableSteps(isDetailsNeeded(data), data)}
				currentStep="source"
				doneSteps={getDoneSteps(data)}
				listId={data.id}
			/>
			<div className="w-full">
				{data.sourceType === ListSourceType.MAPS && (
					<div className="flex flex-row gap-12">
						<div className="grow relative">
							{CreateOrAddButton}
							{switchSelectOrCreate ? (
								<SelectableSourceList list={data as List} />
							) : selected ? (
								<CreateMapForm
									handleSelect={setSelected}
									list={data as List}
									map={selected as Map}
								/>
							) : (
								<CreateMapForm list={data as List} />
							)}
						</div>
						<div className="w-1/2">
							<AddedSourceList handleSelect={setSelected} list={data as List} />
						</div>
					</div>
				)}
				{data.sourceType === ListSourceType.JOBS && (
					<div className="flex flex-row gap-12">
						<div className="grow relative">
							{CreateOrAddButton}
							{switchSelectOrCreate ? (
								<SelectableSourceList list={data as List} />
							) : selected ? (
								<CreateJobForm
									handleSelect={setSelected}
									job={selected as Job}
									list={data as List}
								/>
							) : (
								<CreateJobForm list={data as List} />
							)}
						</div>
						<div className="w-1/2">
							<AddedSourceList handleSelect={setSelected} list={data as List} />
						</div>
					</div>
				)}
				{data.sourceType === ListSourceType.CSV_ENRICHMENTS && (
					<div className="flex flex-row gap-12">
						<div className="grow relative">
							{CreateOrAddButton}
							{switchSelectOrCreate ? (
								<SelectableSourceList list={data as List} />
							) : selected ? (
								<CreateCsvEnrichmentForm
									csvEnrichment={selected as CsvEnrichment}
									handleSelect={setSelected}
									list={data as List}
								/>
							) : (
								<CreateCsvEnrichmentForm list={data as List} />
							)}
						</div>
						<div className="w-1/2">
							<AddedSourceList handleSelect={setSelected} list={data as List} />
						</div>
					</div>
				)}
				{data.sourceType === ListSourceType.LI_EVENTS && (
					<div className="flex flex-row gap-12">
						<div className="grow relative">
							{CreateOrAddButton}
							{switchSelectOrCreate ? (
								<SelectableSourceList list={data as List} />
							) : selected ? (
								<CreateLiEventForm
									handleSelect={setSelected}
									liEvent={selected as LiEvent}
									list={data as List}
								/>
							) : (
								<CreateLiEventForm list={data as List} />
							)}
						</div>
						<div className="w-1/2">
							<AddedSourceList handleSelect={setSelected} list={data as List} />
						</div>
					</div>
				)}
				{data.sourceType === ListSourceType.LI_COMPANY_ACTIVITY && (
					<div className="flex flex-row gap-12">
						<div className="grow relative">
							{CreateOrAddButton}
							{switchSelectOrCreate ? (
								<SelectableSourceList list={data as List} />
							) : selected ? (
								<CreateLiCompanyActivityForm
									company={selected as LiCompanyActivity}
									handleSelect={setSelected}
									list={data as List}
								/>
							) : (
								<CreateLiCompanyActivityForm list={data as List} />
							)}
						</div>
						<div className="w-1/2">
							<AddedSourceList handleSelect={setSelected} list={data as List} />
						</div>
					</div>
				)}
				{data.sourceType === ListSourceType.LI_POST && (
					<div className="flex flex-row gap-12">
						<div className="grow relative">
							{CreateOrAddButton}
							{switchSelectOrCreate ? (
								<SelectableSourceList list={data as List} />
							) : selected ? (
								<CreateLiPostForm
									handleSelect={setSelected}
									liPost={selected as LiPost}
									list={data as List}
								/>
							) : (
								<CreateLiPostForm list={data as List} />
							)}
						</div>
						<div className="w-1/2">
							<AddedSourceList handleSelect={setSelected} list={data as List} />
						</div>
					</div>
				)}
				{data.sourceType === ListSourceType.LI_PROFILE_VISITS && (
					<div className="flex flex-row gap-12">
						<div className="grow">
							<SelectableSourceList list={data as List} />
						</div>
						<div className="w-1/2">
							<AddedSourceList handleSelect={setSelected} list={data as List} />
						</div>
					</div>
				)}
				{data.sourceType === ListSourceType.LI_PROFILE && (
					<div className="flex flex-row gap-12">
						<div className="grow relative">
							{CreateOrAddButton}
							{switchSelectOrCreate ? (
								<SelectableSourceList list={data as List} />
							) : (
								<CreateLiProfileForm
									handleSelect={setSelected}
									liProfile={selected as LiProfile}
									list={data as List}
								/>
							)}
						</div>
						<div className="w-1/2">
							<AddedSourceList handleSelect={setSelected} list={data as List} />
						</div>
					</div>
				)}
				{data.sourceType === ListSourceType.LI_PEOPLE && (
					<div className="flex flex-row gap-12">
						<div className="grow relative">
							{CreateOrAddButton}
							{switchSelectOrCreate ? (
								<SelectableSourceList list={data as List} />
							) : (
								<CreateLiPeopleForm
									handleSelect={selected ? setSelected : undefined}
									liPeople={selected as LiPeople}
									list={data as List}
								/>
							)}
						</div>
						<div className="w-1/2">
							<AddedSourceList handleSelect={setSelected} list={data as List} />
						</div>
					</div>
				)}
			</div>
			<div className="absolute mt-14 h-[72px] flex flex-row justify-between items-center w-auto border-t border-t-border px-8 py-[14px] rounded-t-16 bottom-0 right-0 z-20 bg-white w-full">
				<div></div>
				<div className="flex flex-row self-left items-center gap-x-4">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						title={t("basics.previous")}
						onClick={() => navigate(`/app/lists/create/details/${id}`)}
					/>
					<Button disabled={amountOfSources === 0} title={t("basics.next")} onClick={submit} />
				</div>
			</div>
		</div>
	);
};
