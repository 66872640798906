import { FunctionComponent } from "react";

import { NoReplyTable } from "../../components/NoReplyTable";

export const NoReply: FunctionComponent = () => {
	// const { t } = useTranslation();
	// const ts = (key: string) => t(`inbox.${key}`);
	// const [searchParams, setSearchParams] = useSearchParams();
	// const { setSearchValue, query } = useSearch();
	// const [sortState, setSortState] = useState<SortParams | undefined>();
	// const [page, setPage] = useState(1);
	// const [listData, setListData] = useState<List[]>([]);
	// const [listLoading, setListLoading] = useState(false);

	// const handleSortList = (value: AutoCompleteItem) => {
	// 	setListLoading(true);
	// 	setListData([]);
	// 	setPage(1);
	// 	switch (value.id) {
	// 		case "nameASC":
	// 			setSortState({ sortBy: "name", sortOrder: Order.ASC });
	// 			break;
	// 		case "nameDESC":
	// 			setSortState({ sortBy: "name", sortOrder: Order.DESC });
	// 			break;
	// 		case "leadsASC":
	// 			setSortState({ sortBy: "newLeads", sortOrder: Order.ASC });
	// 			break;
	// 		case "leadsDESC":
	// 			setSortState({ sortBy: "newLeads", sortOrder: Order.DESC });
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// };

	return (
		<div className="sm:block flex flex-col items-start justify-between mb-6">
			<div className="flex items-end justify-between w-full mb-7">
				<div className="flex-grow inline-flex items-center justify-between">
					<div className="flex-grow inline-flex items-center">
						{/* <CustomListAutocomplete
							currentPage={page}
							data={listData || []}
							floating={true}
							handleFetchMore={() => {
								handleLoadMore();
							}}
							handleSearch={(value: string) => {
								setListLoading(true);
								setListData([]);
								setPage(1);
								setSearchValue(value);
							}}
							handleSelect={(value?: Partial<List>) => {
								if (value && value.id !== undefined) {
									navigate(`/app/lists/detail/${value.id}`);
									setSortState(undefined);
									setSearchValue("");
									setPage(1);
									console.log("selected");
								} else {
									console.log("No value selected");
								}
							}}
							handleSortList={(value: AutoCompleteItem) => {
								handleSortList(value);
							}}
							isFetching={isFetching}
							isListLoading={listLoading}
							isLoading={isLoading}
							label={data?.name}
							selectedItem={data?.name || ""}
							sortState={sortState}
							totalPages={lists?.meta.pageCount || 0}
						/> */}
						List View
					</div>
				</div>
			</div>
			<NoReplyTable />
		</div>
	);
};
