import { FunctionComponent } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { classNames } from "@/utils/classNames";

import { ROUTE_CONFIG } from "@/routes/config";

import { authSelector, setAuth } from "@/pages/Public/redux/auth.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";

import { ReactComponent as ArrowDown } from "@assets/icons/topbar/arrow-down.svg";
import { ReactComponent as ArrowRightStartOnRectangle } from "@assets/icons/topbar/arrow-right-start-on-rectangle.svg";
import { ReactComponent as UserIcon } from "@assets/icons/topbar/user.svg";

import { clearLists } from "@/pages/Private/redux/list/list.slice";

import { User } from "@/pages/Public/pages/Login/schema/login";

import { ContextMenu } from "../ContextMenu/ContextMenu";
import { SvgIcon } from "../Icon/SvgIcon";

const { ACCOUNT } = ROUTE_CONFIG;

export const Topbar: FunctionComponent = () => {
	const { t } = useTranslation();

	const ts = (key: string) => t(`sidebar.${key}`);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { user } = useAppSelector(authSelector);

	const handleNavigateAccount = () => {
		navigate(ACCOUNT);
	};

	const initialUserAsString = localStorage.getItem("initialUser");

	let initialUser: { user: User } | null = null;

	if (initialUserAsString) {
		initialUser = JSON.parse(initialUserAsString) as { user: User };
	}

	const resetInitialUser = () => {
		dispatch(setAuth(initialUser));
		dispatch(clearLists());
		localStorage.removeItem("initialUser");
		navigate(ROUTE_CONFIG.LISTS);
	};

	console.log(initialUser);

	return (
		<div className={classNames("flex-shrink-0 w-full flex flex-row justify-end items-center px-4")}>
			{initialUser && (
				<div className="absolute text-sm right-[80px] items-center">
					{initialUser?.user?.firstName} {initialUser?.user?.lastName} logged in as{" "}
					{user?.firstName} {user?.lastName} of <b>{user?.company?.name}</b>
				</div>
			)}
			<div className="absolute right-[10px] top-[10px] cursor-pointer">
				<ContextMenu
					data={[
						[
							{
								title: "My Profile",
								icon: <SvgIcon className="w-[20px] h-[20px] text-gray-700" svgIcon={UserIcon} />,
								onClick: () => {
									handleNavigateAccount();
								},
							},
							{
								title: initialUser ? ts("back") : ts("logout"),
								icon: (
									<SvgIcon
										className="w-[20px] h-[20px] text-gray-700"
										svgIcon={ArrowRightStartOnRectangle}
									/>
								),
								onClick: () => (initialUser ? resetInitialUser() : navigate("/logout")),
							},
						],
					]}
					position="bottom-right"
					width="150"
				>
					<div className="flex flex-row w-fit ali">
						<span className="w-[26px] h-[26px] text-xs text-center text-white rounded-full bg-brand-light p-1">
							{user?.firstName[0]}
							{user?.lastName[0]}
						</span>
						<SvgIcon className="w-[24px] h-[24px] text-gray-700" svgIcon={ArrowDown} />
					</div>
				</ContextMenu>
			</div>
		</div>
	);
};
